import React, {useEffect, useState} from "react";
import "./MobileFooter.css";
import {ReactComponent as CryptoIcon} from "../../images/icons/sidebar/crypto.svg";
import {ReactComponent as CardIcon} from "../../images/icons/sidebar/card.svg";
import {ReactComponent as IconTransactions} from "../../images/icons/sidebar/transactions.svg";
import {ReactComponent as DashboardIcon} from "../../images/icons/sidebar/dashboard.svg";
import {ROUTE} from "../../constants/routes";
import {useHistory, useLocation} from "react-router-dom";
import {LS} from "../../constants/ls";
import {notification} from "../Notification";
import classNames from "classnames";



export const MobileFooter = () => {
  const history = useHistory();
  const [active, setActive] = useState(1);
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === ROUTE.DASHBOARD_WALLET) {
      setActive(0);
    } else if (location.pathname === ROUTE.DASHBOARD_CARDS_ORDER) {
      setActive(1);
    } else if (location.pathname === ROUTE.DASHBOARD_CRYPTO) {
      setActive(2);
    }
  }, [location])

  return(
    <div className="mobile-footer">
      <DashboardIcon className={classNames(
        {"mobile-footer-item-active" : active === 0}
      )} onClick={() => {
        localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET);
        history.push(ROUTE.DASHBOARD_WALLET);
      }}/>
      <CardIcon className={classNames(
        {"mobile-footer-item-active" : active === 1}
      )} onClick={() => {
        history.push(ROUTE.DASHBOARD_CARDS_ORDER);
        localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_CARDS_ORDER);
      }}/>
      <CryptoIcon className={classNames(
        {"mobile-footer-item-active" : active === 2}
      )} onClick={() => {
        localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_CRYPTO);
        history.push(ROUTE.DASHBOARD_CRYPTO);
      }}/>
      <IconTransactions className={classNames(
        {"mobile-footer-item-active" : active === 3}
      )} onClick={() => notification.warning("Not implemented")}/>
    </div>
  )
}