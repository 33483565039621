import React, {useEffect, useState} from "react";
import "./Login.css";
import "../Auth.css";
import {client} from "../../../services/client";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setAuth, setUser} from "../../../store/actions/actions";
import {jwt} from "../../../services/jwt";
import {notification} from "../../../components/Notification";
import {ROUTE} from "../../../constants/routes";
import {Input, INPUT_ICON, INPUT_TYPES} from "../../../components/Input/Input";
import {Button, TYPE} from "../../../components/Button/Button";
import {isMobile} from "../../../utils/checkDevice";
import {MobileHeader} from "../../../components/MobileHeader/MobileHeader";
import {validateEmail, validatePassword} from "../../../utils/validators";
import {VALIDATION_ERROR} from "../../../utils/errorCodes";
import {ModalSecurityCode} from "../../../components/Modal/ModalSecutityCode/ModalSecurityCode";
import {USER_STATUS} from "../../../constants/userStatuses";

export const Login = () => {
  const [formData, setFormData] = useState();
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState();
  const [buttonActive, setButtonActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(true);
  const [isModalSecurityCodeOpen, setIsModalSecurityCodeOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const p = new URLSearchParams(history.location.search).get('redirect');
    if (p !== null) {
      setRedirectUrl(p.replace("/", ""));
    }
  }, []);

  const validateForm = () => {
    if (!validateEmail(formData.email)) {
      setEmailError(VALIDATION_ERROR.EMAIL);
      return false;
    }
    if (!validatePassword(formData.password)) {
      setPasswordError(VALIDATION_ERROR.PASS);
      return false;
    }
    return true;
  }

  const handleResend = async () => {
    await client.resendCode()
      .then(() => notification.success("OTP has been sent"))
      .catch((error) => notification.warning(error.message));
  }

  const handleLogin = async (e) => {
    e && e.preventDefault();
    if (loading || !buttonActive) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    let token;
    await client.auth2fa({
      email: formData.email,
      password: formData.password
    })
      .then((r) => {
        token = r;
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));

    if (token && token.access_token !== '') {
      jwt.setJwtData(token.access_token, token.refresh_token);
      const user = await client.getCurrentUser();
      if (user) {
        dispatch(setUser(user));
        dispatch(setAuth(true));
        if (user.status === USER_STATUS.MEMBER) {
          if (redirectUrl !== null) {
            history.push(ROUTE.ROOT  + redirectUrl);
          } else {
            history.push(ROUTE.DASHBOARD_WALLET);
          }
        }
        if (user.status === USER_STATUS.NEW) {
          await handleResend();
          if (isMobile()) {
            history.push(ROUTE.OTP);
          } else {
            setIsModalSecurityCodeOpen(true);
          }
        }
      }
      setLoading(false);
    }
  }

  const onChange = (e) => {
    switch (e.target.name) {
      case "email": {
        setEmailError(null);
        setFormData({...formData, email: e.target.value});
        if (e.target.value.length > 0) {
          if (!buttonActive && formData && formData.password !== undefined && formData.password.length > 0) {
            setButtonActive(true);
          }
        } else {
          setButtonActive(false);
        }
        break;
      }
      case "password": {
        setPasswordError(null);
        if (e.target.value.length > 0) {
          setPasswordIcon(true);
          if (!buttonActive && formData.email.length > 0) {
            setButtonActive(true);
          }
        } else {
          setPasswordIcon(false);
          if (buttonActive) {
            setButtonActive(false)
          }
        }
        setFormData({...formData, password: e.target.value});
        break;
      }
    }
  }


  return (
    <div className="auth-container">
      {isMobile() ?
        <>
          <MobileHeader url={ROUTE.ROOT} text="Log in"/>
        </> : <>
          <h3>Log in</h3>
          <div className="auth-description">
            <b>Limited-Time Offer:</b> We offer this special promotion for a limited time! Earn <b>a ten euro cash
            bonus</b> per customer
            to your own Visa card. Find out more in your account!
          </div>
        </>}
      <form onSubmit={(e) => handleLogin(e)}>
        <Input
          name="email"
          label="Email"
          error={emailError}
          onChange={onChange}
          onSubmit={(e) => handleLogin(e)}
          autoFocus
          required
        />
        <Input
          icon={passwordIcon && (showPassword ? INPUT_ICON.EYE_CLOSED : INPUT_ICON.EYE_OPEN)}
          onIconClick={() => setShowPassword(!showPassword)}
          name="password" type={showPassword ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
          label="Password"
          error={passwordError}
          onChange={onChange}
          onSubmit={(e) => handleLogin(e)}
          required
        />
        <div className="reset-password caption-small">
          Forgot your password? <span className="link" onClick={() => history.push(ROUTE.PASSWORD_RESET_REQUEST)}>Reset it here.</span>
        </div>
        <div className="auth-button-container">
          <Button
            type={buttonActive ? TYPE.PRIMARY : TYPE.INACTIVE}
            text="Log in"
          />
        </div>
        {!isMobile() && <div className="signup-link subtitle">
          Don’t have an account? <span onClick={() => history.push(ROUTE.SIGNUP)} className="link">Sign up here.</span>
        </div>}
      </form>
      {isModalSecurityCodeOpen &&
        <ModalSecurityCode isOpen={setIsModalSecurityCodeOpen}/>}
    </div>
  )
}