import React from "react";
import { isMobile } from "../../../utils/checkDevice";
import { MobileHeader } from "../../../components/MobileHeader/MobileHeader";
import { ROUTE } from "../../../constants/routes";
import { ReactComponent as LmgtIcon } from "../../../images/icons/wallet/lmgt.svg";
import { ReactComponent as BtcIcon } from "../../../images/icons/wallet/btc.svg";
import { ReactComponent as UsdtIcon } from "../../../images/icons/wallet/usdt.svg";
import { ReactComponent as EthIcon } from "../../../images/icons/wallet/eth.svg";
import { ReactComponent as BnbIcon } from "../../../images/icons/wallet/bnb.svg";
import { toEur } from "../../../utils/tools";
import { useSelector } from "react-redux";

export const CryptoMobile = () => {
    const wallet = useSelector(store => store.base.wallet);

    return (
        <div className="mobile-content">
            {isMobile() && <MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Crypto" />}

            <div className="wallet-mobile-card-item subtitle1">
                <div className="wallet-info-inner">
                    <LmgtIcon />
                    LMGT
                </div>
                <div className="wallet-info-inner wallet-crypto-balance-container">
                    <div>{wallet && wallet.lmgtBalance} LMGT</div>
                    <div>{wallet && toEur(wallet.lmgtValue)}</div>
                </div>
            </div>

            <div className="wallet-mobile-card-item subtitle1">
                <div className="wallet-info-inner">
                    <BtcIcon />
                    Bitcoin
                </div>
                <div className="wallet-info-inner wallet-crypto-balance-container">
                    <div>0 BTC</div>
                    <div>{toEur(0)}</div>
                </div>
            </div>

            <div className="wallet-mobile-card-item subtitle1">
                <div className="wallet-info-inner">
                    <UsdtIcon />
                    Tether
                </div>
                <div className="wallet-info-inner wallet-crypto-balance-container">
                    <div>0 USDT</div>
                    <div>{toEur(0)}</div>
                </div>
            </div>

            <div className="wallet-mobile-card-item subtitle1">
                <div className="wallet-info-inner">
                    <EthIcon />
                    Ethereum
                </div>
                <div className="wallet-info-inner wallet-crypto-balance-container">
                    <div>0 ETH</div>
                    <div>{toEur(0)}</div>
                </div>
            </div>

            <div className="wallet-mobile-card-item subtitle1">
                <div className="wallet-info-inner">
                    <BnbIcon />
                    BNB
                </div>
                <div className="wallet-info-inner wallet-crypto-balance-container">
                    <div>0 BNB</div>
                    <div>{toEur(0)}</div>
                </div>
            </div>
        </div>
    )
}