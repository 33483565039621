import React from "react";
import { UpdatePassword } from "../../../pages/dashboard/account/UpdatePassword/UpdatePassword";

export const ModalUpdatePassword = ({isOpen}) => {

  const renderContent = () => {
    return <UpdatePassword isOpen={isOpen}/>
  }

  return (
    <>
    <div className="modal-bg" onClick={() => isOpen(false)}/>
    <div className="modal modal-send">
      <div className="modal-header">
        <div className="modal-title">Update password</div>
        <div className="modal-close-button" onClick={() => isOpen(false)}/>
      </div>
      {renderContent()}
    </div>
  </>
  )
}