import React from "react";
import {useSelector} from "react-redux";
import {PersonalInfoForm} from "./components/PersonalInfoForm/PersonalInfoForm";
import {ResidentialAddressForm} from "./components/ResedentialAddressForm/ResidentialAddressForm";
import {ShippingAddressForm} from "./components/ShippingAddressForm/ShippingAddressForm";
import "./KycFullModal.css";
import {VerifyHint} from "./components/VerifyHint/VerifyHint";
import {VerifyIdentity} from "./components/VerifyIdentity/VerifyIdentity";
import {VerifyAddress} from "./components/VerifyAddress/VerifyAddress";
import {KycCompleted} from "./components/KycCompleted/KycCompleted";
import {FullModalSteps} from "../components/FullModalSteps/FullModalSteps";

const ITEMS = [
  {id: 0, title: "Basic information"},
  {id: 1, title: "Residential Address"},
  {id: 2, title: "Shipping Address"},
  {id: 3, title: "Verify your identity"},
  {id: 4, title: "Verify your address"},
];

export const KycFullModal = ({isOpen}) => {
  const user = useSelector(store => store.base.user);


  const getLeftTitle = () => {
    switch (user.kycStep) {
      case 0:
        return "Personal information";
      case 1:
        return "Personal information";
      case 2:
        return "Shipping address";
      case 3:
        return "Identity verification";
      case 4:
        return "Address verification";
    }
  }

  const getLeftContent = () => {
    switch (user.kycStep) {
      case 3: {
        return <VerifyHint step={user.kycStep}/>
      }
      case 4:
        return <VerifyHint step={user.kycStep}/>
    }
  }

  const getContent = () => {
    switch (user.kycStep) {
      case 0:
        return <PersonalInfoForm callback={() => isOpen(false)}/>
      case 1:
        return <ResidentialAddressForm/>
      case 2:
        return <ShippingAddressForm/>;
      case 3:
        return <VerifyIdentity/>;
      case 4:
        return <VerifyAddress/>;
      case 5:
        return <KycCompleted callback={() => isOpen(false)}/>;
    }
  }


  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="full-modal">
        <div className="full-modal-left">
          <h5>{user && getLeftTitle()}</h5>
          {user && <FullModalSteps items={ITEMS} step={user.kycStep}/>}
          {user && getLeftContent()}
        </div>
        <div className="full-modal-right">
          <div className="full-modal-close-button" onClick={() => isOpen(false)}/>
          {user && getContent()}
        </div>
      </div>
    </>
  )
}