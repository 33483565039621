import React, {useCallback, useEffect, useState} from "react";
import {ReactComponent as IconIn} from "../../../../../images/icons/transactions/in.svg";
import {ReactComponent as IconOut} from "../../../../../images/icons/transactions/out.svg";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import {useSelector} from "react-redux";
import {ModalTransaction} from "../../../../../components/Modal/ModalTransaction/ModalTransaction";
import {getAmount, mapTransactionType} from "../../../../../utils/transactionUtils";
import {ROUTE} from "../../../../../constants/routes";
import {useHistory} from "react-router-dom";

export const RecentTransactions = ({isMobile = false}) => {
  const [data, setData] = useState(null);
  const [modalTransactionIsOpen, setModalTransactionIsOpen] = useState(false);
  const user = useSelector(store => store.base.user);
  const newTransactions = useSelector(store => store.base.newTransactions);
  const [transaction, setTransaction] = useState(null);
  const history = useHistory();

  const getData = useCallback(async () => {
    await client.getAllTransactions({
      page: 0,
      size: 10
    })
      .then(setData)
      .catch((error) => notification.warning(error.message))
  }, [newTransactions])

  useEffect(() => {
    getData();
  }, [newTransactions]);

  const open = (e) => {
    setTransaction(e);

    if (isMobile) {
      history.push(ROUTE.DASHBOARD_WALLET_TRANSACTION.replace(":id", e.id))
    } else {
      setModalTransactionIsOpen(true);
    }
  }


  const renderData = () => {
    if (data.content.length === 0) {
      return <div className="no-data">No data</div>;
    }
    return data.content.map((e, i) => {
      return <div key={i} className="transactions-item" onClick={() => open(e)}>
        {user.id === e.toUserId ? <IconIn/> : <IconOut/>}
        <div className="transactions-item-info">
          <div>{mapTransactionType(e.type)}</div>
          <div className="transactions-item-date">{e.createdAt.substring(0, 10)}</div>
        </div>
        {getAmount(user, e)}
      </div>
    })
  }

  return (
    <div className={isMobile ? "block block-big no-shadow" : "block block-big"}>
      <div className="block-title">Recent transactions</div>

      <div className="transaction-item-list">
        {user && data && renderData()}
      </div>
      {modalTransactionIsOpen && transaction &&
        <ModalTransaction transaction={transaction} isOpen={setModalTransactionIsOpen}/>}
    </div>
  )
}