import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {notification} from "../../../../../../components/Notification";
import QRCode from "qrcode.react";
import {ReactComponent as IconDone} from "../../../../../../images/icons/stars.svg"
import {Button, SIZE as BUTTON_SIZE, TYPE} from "../../../../../../components/Button/Button";
import {isMobile} from "../../../../../../utils/checkDevice";
import {Input, INPUT_ICON} from "../../../../../../components/Input/Input";
import {PAYMENT_TYPES} from "../../../../../../utils/paymentUtils";
import {ROUTE} from "../../../../../../constants/routes";
import {useHistory} from "react-router-dom";
import {LS} from "../../../../../../constants/ls";
import classNames from "classnames";

export const DepositStep2 = ({isOpen, data, center = true}) => {
  const user = useSelector(store => store.base.user);
  const history = useHistory();
  const [buttonText, setButtonText] = useState("copy");
  const [countdown, setCountdown] = useState("");
  const [status, setStatus] = useState(data.status)

  useEffect(() => {
    let timer = data.expireIn;
    setInterval(function () {
      let minutes = parseInt(timer / 60, 10);
      let seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setCountdown(minutes + ":" + seconds);

      if (--timer === 0) {
        setStatus("EXPIRED");
      }
    }, 1000);
  }, [data]);


  const copy = (v) => {
    navigator.clipboard.writeText(v);
    notification.success("Copied to clipboard");
  }

  const renderEpinData = () => {
    return <>
      <div className="center modal-deposit-done">
        <IconDone/>
      </div>
      <div className="center">
        <h5>Done!</h5>
      </div>
      <div className="modal-button-container">
        <Button
          type={TYPE.PRIMARY}
          text="Close"
          onClick={() => close()}
          fullWidth={isMobile()}
        />
      </div>
    </>
  }

  const renderCountdown = () => {
    return <>Waiting for your payment <b>{countdown}</b></>
  }

  const close = () => {
    if (isMobile()) {
      history.push(ROUTE.DASHBOARD_WALLET);
      localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET);
    } else {
      isOpen(false);
    }
  }

  const renderCryptoData = () => {
    return <>
      <div className={classNames(
        {"center": center, "mb-2-5": !center},
      )
      }>
        Make the deposit as soon as possible. If you have chosen crypto payment, you must submit the payment within one
        hour, or the order will expire.
      </div>
      <div className={classNames(
        "modal-deposit-order-crypto-container",
        {"gap-1": !center, "mb-2-5": !center}
      )
      }>
        <div>{data.cryptoAmount} {data.paymentMethod}</div>
        <div>to</div>
        <div className="copy-container">
          <div>{data.cryptoAddress}</div>
          <Button size={BUTTON_SIZE.SMALL} fullWidth={false} text={buttonText} onClick={() => {
            navigator.clipboard.writeText(data.cryptoAddress);
            setButtonText("copied!");
          }}/>
        </div>
        <div>or scan this QR code</div>
        <div>
          <QRCode value={data.cryptoAddress}/>
        </div>
        <div>
          {status !== "EXPIRED" ? renderCountdown() : "Order expired"}
        </div>
      </div>
      <div className="modal-button-container">
        <Button
          type={TYPE.PRIMARY}
          text="Close"
          onClick={() => close()}
          fullWidth={isMobile()}
        />
      </div>
    </>
  }

  const renderBankData = () => {
    return <>
      <div className={classNames(
        {"mb-2-5": !center}
      )
      }>
        To fund your account using bank transfer make sure you include your account user ID and transfer amount in
        euros.
      </div>
      <div>
        <form>
          <Input
            name="accountId"
            label="Your Account User ID"
            value={user?.id}
            icon={INPUT_ICON.COPY}
            onClick={(e) => copy(user?.id)}
            readOnly
          />
          <Input
            name="bankName"
            label="Bank name"
            value="BBVA (Banco Bilbao Vizcaya Argentaria)"
            icon={INPUT_ICON.COPY}
            onClick={(e) => copy("BBVA (Banco Bilbao Vizcaya Argentaria)")}
            readOnly
          />
          <Input
            name="accountHolder"
            label="Account holder"
            value="Urb.sitio de Calahonda c.nac. 340 km. 197. 29649 Mijas, Málaga, Spain"
            icon={INPUT_ICON.COPY}
            onClick={(e) => copy("Urb.sitio de Calahonda c.nac. 340 km. 197. 29649 Mijas, Málaga, Spain")}
            readOnly
          />
          <Input
            name="accountHolder2"
            label="Account holder"
            value="OneTalk Services LLC"
            icon={INPUT_ICON.COPY}
            onClick={(e) => copy(user?.id)}
            readOnly
          />
          <Input
            name="iban"
            label="IBAN (EUR Account)"
            value="ES8201827115540201619531"
            icon={INPUT_ICON.COPY}
            onClick={(e) => copy("OneTalk Services LLC")}
            readOnly
          />
          <Input
            name="bic"
            label="BIC (Bank Identifier Code)"
            value="ES8201827115540201619531"
            icon={INPUT_ICON.COPY}
            onClick={(e) => copy("ES8201827115540201619531")}
            readOnly
          />
        </form>
      </div>
      <div className="modal-button-container">
        <Button
          type={TYPE.PRIMARY}
          text="Download"
          onClick={(e) => {
            e.preventDefault();
            window.open(data.invoice, "_blank");
          }}
          fullWidth={isMobile()}
        />
        <Button
          type={TYPE.PRIMARY}
          text="Close"
          onClick={() => close()}
          fullWidth={isMobile()}
        />
      </div>
    </>
  }

  const renderData = () => {
    if (data.paymentMethod === PAYMENT_TYPES.BTC) {
      return renderCryptoData();
    } else if (data.paymentMethod === PAYMENT_TYPES.BANK) {
      return renderBankData();
    } else if (data.paymentMethod === PAYMENT_TYPES.EPIN) {
      return renderEpinData();
    }
  }

  return (
    <>
      {renderData()}
    </>
  )
}