import React, { useEffect, useRef } from "react";
import "./Landing.css"
import { Button, TYPE } from "../../components/Button/Button";
import { ReactComponent as IconArrowDown } from "../../images/icons/arrow-down.svg"
import { useHistory } from "react-router-dom";
import { ROUTE } from "../../constants/routes";
import { LandingCardMobile } from "./LandingCardMobile";
import { isMobile } from "../../utils/checkDevice";
import { LandingProducts } from "./components/LandingProducts";
import { LandingCardsFooter } from "./components/LandingCardsFooter";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export const LandingCard = () => {
  const history = useHistory();
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const scrollTo1 = () => ref1.current.scrollIntoView();
  const scrollTo2 = () => ref2.current.scrollIntoView();

  useEffect(() => {
    CookieConsent.run({

      categories: {
        necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
        },
        analytics: {}
      },

      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'Cookies',
              description: 'Our website uses some cookies. You can disable data collection and cookies by changing your browser settings, but it may affect how this website functions',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences'
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Somebody said ... cookies?',
                  description: 'I want one!'
                },
                {
                  title: 'Strictly Necessary cookies',
                  description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Performance and Analytics',
                  description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics'
                },
                // {
                //   title: 'More information',
                //   description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                // }
              ]
            }
          }
        }
      }
    });
  }, []);


  return (
    isMobile() ? <LandingCardMobile /> : <div>
      <div ref={ref1} className="landing-card-first-page">
        <div className="plr-0-5 flex column sb h-100vh">
          <header className="mx-auto pt-2 w-full max-w-78">
            <div className="flex items-center sb">
              <div className="landing-card-logo"></div>
              <ul className="flex items-center ml-1-5 fs-1-2">
                <li className="plr-2 ptb-0-5 cp no-wrap">Shopping</li>
                <li className="plr-2 ptb-0-5 cp no-wrap landing-li-active">Debit cards</li>
                <li className="plr-2 ptb-0-5 cp no-wrap">Invite & Earn</li>
              </ul>
              <div className="flex items-center gap-23 ml-1-5">
                <div>
                  <Button
                    type={TYPE.PRIMARY_LANDING}
                    text="Log in"
                    onClick={() => history.push(ROUTE.LOGIN)}
                  />
                </div>
                <div>
                  <Button
                    type={TYPE.SECONDARY_LANDING}
                    text="Get a free account"
                    onClick={() => history.push(ROUTE.SIGNUP)}
                  />
                </div>
              </div>
            </div>
          </header>

          <div className="flex column flex-1 center jsc landing-cards-center">
            <div className="grid lading-cards-grid max-w-78 mx-auto">
              <div className="flex column gap-5">
                <picture className="h-full ml-5">
                  <img width={125} height={125} alt="Payonow" src="/img/landing-cards/img1.png" />
                </picture>
                <picture className="h-full">
                  <img width={114} height={114} alt="Payonow" src="/img/landing-cards/img2.png" />
                </picture>
                <picture className="h-full ml-5">
                  <img width={129} height={129} alt="Payonow" src="/img/landing-cards/img3.png" />
                </picture>

              </div>

              <div className="flex column items-center sc t mx-auto jsc gap-4-5">
                <div className="center fs-4 ls-2 fw-500 lh-82">Money Made Simple <br />No Restriction Anywhere</div>
                <div className="center fs-1-3 a">
                  Optimize Your Cash and Crypto Management for Seamless Day-to-Day Transactions Worldwide! Experience
                  the
                  future of financial management with an all-in-one platform designed to make your daily spending
                  effortless and accessible from anywhere in the world. This is the modern way to handle your finances.
                </div>
              </div>

              <div className="flex column gap-5">
                <picture className="h-full">
                  <img width={119} height={119} alt="Payonow" src="/img/landing-cards/img4.png" />
                </picture>
                <picture className="h-full ml-5">
                  <img width={133} height={133} alt="Payonow" src="/img/landing-cards/img5.png" />
                </picture>
                <picture className="h-full">
                  <img width={129} height={129} alt="Payonow" src="/img/landing-cards/img6.png" />
                </picture>
              </div>
            </div>

            <div className="mx-auto center fit-content gap-2-8 flex column" onClick={scrollTo2}>
              <div className="flex column items-center cp fs-0-875">
                <span>Check wallets</span>
                <IconArrowDown />
              </div>
            </div>

          </div>

        </div>


      </div>
      <div ref={ref2} className="landing-card-second-page">
        <div className="w-62-5 mx-auto flex column items-center jsc h-full h-100vh gap-6-8">
          <div className="landing-cards-block w-full landing-cards-block-1">
            <div className="flex items-center jsc">
              <picture>
                <img alt="Payonow" src="/img/landing-cards/cards-landing.png" />
              </picture>
            </div>
            <div className="flex column gap-2-8">
              <div className="fs-3 fw-500">Go from cash to crypto with ease</div>
              <ul className="list-style-disc landing-cards-ul">
                <li>Deposit in BTC or USDT to fund your wallet.</li>
                <li>Deposit with SEPA transfer to fund your wallet.</li>
                <li>Works everywhere in the world.</li>
                <li>Earn 1-2% cash back on all transactions.</li>
                <li>No spending limits.</li>
                <li>Shop online and earn up to 20% in real cash back.</li>
              </ul>
            </div>
            <div className="flex items-center jsc">
              <Button
                type={TYPE.SECONDARY}
                fullWidth={false}
                text="Get a free account"
                onClick={() => history.push(ROUTE.SIGNUP)}
              />
            </div>
          </div>
          <LandingProducts />
        </div>
      </div>

      <LandingCardsFooter />
    </div>
  )
}