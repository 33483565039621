import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getStepTitle, ORDER_CARD_STEP} from "../../../../utils/orderCardUtils";
import {FullModalSteps} from "../components/FullModalSteps/FullModalSteps";
import {CardTypes} from "./components/CardTypes";
import {CardSelect} from "./components/CardSelect";
import {OrderDetails} from "./components/OrderDetails";
import {DepositStep2} from "../../../../pages/dashboard/wallet/components/Deposit/components/DepositStep2";
import {OrderPayment} from "./components/OrderPayment";

const ITEMS = [
  {id: 0, title: "Type of debit cards"},
  {id: 1, title: "Select cards"},
  {id: 2, title: "Order details"},
  {id: 3, title: "Payment information"},
];

const getId = (step) => {
  switch (step) {
    case ORDER_CARD_STEP.TYPE:
      return 0;
    case ORDER_CARD_STEP.SELECT:
      return 1;
    case ORDER_CARD_STEP.DETAILS:
      return 2;
    case ORDER_CARD_STEP.PAYMENT:
      return 3;
  }
}

export const OrderCardsModal = ({isOpen, redirectToKyc}) => {
  const user = useSelector(store => store.base.user);
  const [step, setStep] = useState(ORDER_CARD_STEP.TYPE);
  const [data, setData] = useState(null);


  const getContent = () => {
    switch (step) {
      case ORDER_CARD_STEP.TYPE: return <CardTypes setStep={setStep} isOpen={isOpen} redirectToKyc={redirectToKyc}/>
      case ORDER_CARD_STEP.SELECT: return <CardSelect setStep={setStep} isOpen={isOpen} setData={setData}/>
      case ORDER_CARD_STEP.DETAILS: return <OrderDetails setStep={setStep} isOpen={isOpen} data={data} setData={setData}/>
      case ORDER_CARD_STEP.PAYMENT: return <OrderPayment setStep={setStep} isOpen={isOpen} data={data} />
    }
  }


  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="full-modal">
        <div className="full-modal-left">
          <h5>{user && getStepTitle(step)}</h5>
          <FullModalSteps items={ITEMS} step={getId(step)}/>
        </div>
        <div className="full-modal-right">
          <div className="full-modal-close-button" onClick={() => isOpen(false)}/>
          {user && getContent()}
        </div>
      </div>
    </>
  )
}