import React, {useState} from "react";
import {MobileHeader} from "../../../../../components/MobileHeader/MobileHeader";
import {ROUTE} from "../../../../../constants/routes";
import {DepositStep1} from "./components/DepositStep1";
import {DepositStep2} from "./components/DepositStep2";

export const Deposit = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);

  const renderContent = () => {
    if (step === 1) {
      return <DepositStep1 setStep={setStep} setData={setData}/>
    } else if (step === 2 && data) {
      return <DepositStep2 setStep={setStep} data={data}/>
    }
  }

  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Deposit funds"/>}
      {renderContent()}
    </div>
  )
}