import React, {useState} from "react";
import "../Modal.css";
import "./ModalSend.css"
import {ModalSendStep1} from "./components/ModalSendStep1";
import {ModalSendStep2} from "./components/ModalSendStep2";

export const ModalSend = ({isOpen, setModalSecurityPinIsOpen}) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(1);

  const renderContent = () => {
    if (step === 1) {
      return <ModalSendStep1 isOpen={isOpen} setStep={setStep} setData={setData}/>
    } else if (step === 2) {
      return <ModalSendStep2 isOpen={isOpen} setStep={setStep} data={data} setModalSecurityPinIsOpen={setModalSecurityPinIsOpen}/>
    }
  }

  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="modal modal-send">
        <div className="modal-header">
          <div className="modal-title">Send</div>
          <div className="modal-close-button" onClick={() => isOpen(false)}/>
        </div>
        {renderContent()}
      </div>
    </>
  )
}