export default [
  {
    id: 1,
    name: "Afghanistan",
    iso: "AF"
  },
  {
    id: 2,
    name: "Aland Islands",
    iso: "AX"
  },
  {
    id: 3,
    name: "Albania",
    iso: "AL"
  },
  {
    id: 4,
    name: "Algeria",
    iso: "DZ"
  },
  {
    id: 5,
    name: "American Samoa",
    iso: "AS"
  },
  {
    id: 6,
    name: "Andorra",
    iso: "AD"
  },
  {
    id: 7,
    name: "Angola",
    iso: "AO"
  },
  {
    id: 8,
    name: "Anguilla",
    iso: "AI"
  },
  {
    id: 9,
    name: "Antarctica",
    iso: "AQ"
  },
  {
    id: 10,
    name: "Antigua and Barbuda",
    iso: "AG"
  },
  {
    id: 11,
    name: "Argentina",
    iso: "AR"
  },
  {
    id: 12,
    name: "Armenia",
    iso: "AM"
  },
  {
    id: 13,
    name: "Aruba",
    iso: "AW"
  },
  {
    id: 14,
    name: "Australia",
    iso: "AU"
  },
  {
    id: 15,
    name: "Austria",
    iso: "AT"
  },
  {
    id: 16,
    name: "Azerbaijan",
    iso: "AZ"
  },
  {
    id: 17,
    name: "Bahamas",
    iso: "BS"
  },
  {
    id: 18,
    name: "Bahrain",
    iso: "BH"
  },
  {
    id: 19,
    name: "Bangladesh",
    iso: "BD"
  },
  {
    id: 20,
    name: "Barbados",
    iso: "BB"
  },
  {
    id: 21,
    name: "Belarus",
    iso: "BY"
  },
  {
    id: 22,
    name: "Belgium",
    iso: "BE"
  },
  {
    id: 23,
    name: "Belize",
    iso: "BZ"
  },
  {
    id: 24,
    name: "Benin",
    iso: "BJ"
  },
  {
    id: 25,
    name: "Bermuda",
    iso: "BM"
  },
  {
    id: 26,
    name: "Bhutan",
    iso: "BT"
  },
  {
    id: 27,
    name: "Bolivia",
    iso: "BO"
  },
  {
    id: 28,
    name: "Bonaire",
    iso: "BQ"
  },
  {
    id: 29,
    name: "Bosnia and Herzegovina",
    iso: "BA"
  },
  {
    id: 30,
    name: "Botswana",
    iso: "BW"
  },
  {
    id: 31,
    name: "Bouvet Island",
    iso: "BV"
  },
  {
    id: 32,
    name: "Brazil",
    iso: "BR"
  },
  {
    id: 33,
    name: "British Indian Ocean Territory",
    iso: "IO"
  },
  {
    id: 34,
    name: "Brunei Darussalam",
    iso: "BN"
  },
  {
    id: 35,
    name: "Bulgaria",
    iso: "BG"
  },
  {
    id: 36,
    name: "Burkina Faso",
    iso: "BF"
  },
  {
    id: 37,
    name: "Burundi",
    iso: "BI"
  },
  {
    id: 38,
    name: "Cambodia",
    iso: "KH"
  },
  {
    id: 39,
    name: "Cameroon",
    iso: "CM"
  },
  {
    id: 40,
    name: "Canada",
    iso: "CA"
  },
  {
    id: 41,
    name: "Cape Verde",
    iso: "CV"
  },
  {
    id: 42,
    name: "Cayman Islands",
    iso: "KY"
  },
  {
    id: 43,
    name: "Central African Republic",
    iso: "CF"
  },
  {
    id: 44,
    name: "Chad",
    iso: "TD"
  },
  {
    id: 45,
    name: "Chile",
    iso: "CL"
  },
  {
    id: 46,
    name: "China",
    iso: "CN"
  },
  {
    id: 47,
    name: "Christmas Island",
    iso: "CX"
  },
  {
    id: 48,
    name: "Cocos (Keeling) Islands",
    iso: "CC"
  },
  {
    id: 49,
    name: "Colombia",
    iso: "CO"
  },
  {
    id: 50,
    name: "Comoros",
    iso: "KM"
  },
  {
    id: 51,
    name: "Congo",
    iso: "CG"
  },
  {
    id: 52,
    name: "Cook Islands",
    iso: "CK"
  },
  {
    id: 53,
    name: "Costa Rica",
    iso: "CR"
  },
  {
    id: 54,
    name: "Cote dIvoire",
    iso: "CI"
  },
  {
    id: 55,
    name: "Croatia",
    iso: "HR"
  },
  {
    id: 56,
    name: "Cuba",
    iso: "CU"
  },
  {
    id: 57,
    name: "Curacao",
    iso: "CW"
  },
  {
    id: 58,
    name: "Cyprus",
    iso: "CY"
  },
  {
    id: 59,
    name: "Czech Republic",
    iso: "CZ"
  },
  {
    id: 60,
    name: "Denmark",
    iso: "DK"
  },
  {
    id: 61,
    name: "Djibouti",
    iso: "DJ"
  },
  {
    id: 62,
    name: "Dominica",
    iso: "DM"
  },
  {
    id: 63,
    name: "Dominican Republic",
    iso: "DO"
  },
  {
    id: 64,
    name: "Ecuador",
    iso: "EC"
  },
  {
    id: 65,
    name: "Egypt",
    iso: "EG"
  },
  {
    id: 66,
    name: "El Salvador",
    iso: "SV"
  },
  {
    id: 67,
    name: "Equatorial Guinea",
    iso: "GQ"
  },
  {
    id: 68,
    name: "Eritrea",
    iso: "ER"
  },
  {
    id: 69,
    name: "Estonia",
    iso: "EE"
  },
  {
    id: 70,
    name: "Ethiopia",
    iso: "ET"
  },
  {
    id: 71,
    name: "Falkland Islands (Malvinas)",
    iso: "FK"
  },
  {
    id: 72,
    name: "Faroe Islands",
    iso: "FO"
  },
  {
    id: 73,
    name: "Fiji",
    iso: "FJ"
  },
  {
    id: 74,
    name: "Finland",
    iso: "FI"
  },
  {
    id: 75,
    name: "France",
    iso: "FR"
  },
  {
    id: 76,
    name: "French Guiana",
    iso: "GF"
  },
  {
    id: 77,
    name: "French Polynesia",
    iso: "PF"
  },
  {
    id: 78,
    name: "French Southern Territories",
    iso: "TF"
  },
  {
    id: 79,
    name: "Gabon",
    iso: "GA"
  },
  {
    id: 80,
    name: "Gambia",
    iso: "GM"
  },
  {
    id: 81,
    name: "Georgia",
    iso: "GE"
  },
  {
    id: 82,
    name: "Germany",
    iso: "DE"
  },
  {
    id: 83,
    name: "Ghana",
    iso: "GH"
  },
  {
    id: 84,
    name: "Gibraltar",
    iso: "GI"
  },
  {
    id: 85,
    name: "Greece",
    iso: "GR"
  },
  {
    id: 86,
    name: "Greenland",
    iso: "GL"
  },
  {
    id: 87,
    name: "Grenada",
    iso: "GD"
  },
  {
    id: 88,
    name: "Guadeloupe",
    iso: "GP"
  },
  {
    id: 89,
    name: "Guam",
    iso: "GU"
  },
  {
    id: 90,
    name: "Guatemala",
    iso: "GT"
  },
  {
    id: 91,
    name: "Guernsey",
    iso: "GG"
  },
  {
    id: 92,
    name: "Guinea",
    iso: "GN"
  },
  {
    id: 93,
    name: "Guinea-Bissau",
    iso: "GW"
  },
  {
    id: 94,
    name: "Guyana",
    iso: "GY"
  },
  {
    id: 95,
    name: "Haiti",
    iso: "HT"
  },
  {
    id: 96,
    name: "Heard Island and McDonald Islands",
    iso: "HM"
  },
  {
    id: 97,
    name: "Holy See (Vatican City State)",
    iso: "VA"
  },
  {
    id: 98,
    name: "Honduras",
    iso: "HN"
  },
  {
    id: 99,
    name: "Hong Kong",
    iso: "HK"
  },
  {
    id: 100,
    name: "Hungary",
    iso: "HU"
  },
  {
    id: 101,
    name: "Iceland",
    iso: "IS"
  },
  {
    id: 102,
    name: "India",
    iso: "IN"
  },
  {
    id: 103,
    name: "Indonesia",
    iso: "ID"
  },
  {
    id: 104,
    name: "Iran",
    iso: "IR"
  },
  {
    id: 105,
    name: "Iraq",
    iso: "IQ"
  },
  {
    id: 106,
    name: "Ireland",
    iso: "IE"
  },
  {
    id: 107,
    name: "Isle of Man",
    iso: "IM"
  },
  {
    id: 108,
    name: "Israel",
    iso: "IL"
  },
  {
    id: 109,
    name: "Italy",
    iso: "IT"
  },
  {
    id: 110,
    name: "Jamaica",
    iso: "JM"
  },
  {
    id: 111,
    name: "Japan",
    iso: "JP"
  },
  {
    id: 112,
    name: "Jersey",
    iso: "JE"
  },
  {
    id: 113,
    name: "Jordan",
    iso: "JO"
  },
  {
    id: 114,
    name: "Kazakhstan",
    iso: "KZ"
  },
  {
    id: 115,
    name: "Kenya",
    iso: "KE"
  },
  {
    id: 116,
    name: "Kiribati",
    iso: "KI"
  },
  {
    id: 117,
    name: "Korea, Democratic People's Republic of",
    iso: "KP"
  },
  {
    id: 118,
    name: "Korea, Republic of",
    iso: "KR"
  },
  {
    id: 119,
    name: "Kuwait",
    iso: "KW"
  },
  {
    id: 120,
    name: "Kyrgyzstan",
    iso: "KG"
  },
  {
    id: 121,
    name: "Lao People's Democratic Republic",
    iso: "LA"
  },
  {
    id: 122,
    name: "Latvia",
    iso: "LV"
  },
  {
    id: 123,
    name: "Lebanon",
    iso: "LB"
  },
  {
    id: 124,
    name: "Lesotho",
    iso: "LS"
  },
  {
    id: 125,
    name: "Liberia",
    iso: "LR"
  },
  {
    id: 126,
    name: "Libyan Arab Jamahiriya",
    iso: "LY"
  },
  {
    id: 127,
    name: "Liechtenstein",
    iso: "LI"
  },
  {
    id: 128,
    name: "Lithuania",
    iso: "LT"
  },
  {
    id: 129,
    name: "Luxembourg",
    iso: "LU"
  },
  {
    id: 130,
    name: "Macao",
    iso: "MO"
  },
  {
    id: 131,
    name: "Macedonia",
    iso: "MK"
  },
  {
    id: 132,
    name: "Madagascar",
    iso: "MG"
  },
  {
    id: 133,
    name: "Malawi",
    iso: "MW"
  },
  {
    id: 134,
    name: "Malaysia",
    iso: "MY"
  },
  {
    id: 135,
    name: "Maldives",
    iso: "MV"
  },
  {
    id: 136,
    name: "Mali",
    iso: "ML"
  },
  {
    id: 137,
    name: "Malta",
    iso: "MT"
  },
  {
    id: 138,
    name: "Marshall Islands",
    iso: "MH"
  },
  {
    id: 139,
    name: "Martinique",
    iso: "MQ"
  },
  {
    id: 140,
    name: "Mauritania",
    iso: "MR"
  },
  {
    id: 141,
    name: "Mauritius",
    iso: "MU"
  },
  {
    id: 142,
    name: "Mayotte",
    iso: "YT"
  },
  {
    id: 143,
    name: "Mexico",
    iso: "MX"
  },
  {
    id: 144,
    name: "Micronesia",
    iso: "FM"
  },
  {
    id: 145,
    name: "Moldova",
    iso: "MD"
  },
  {
    id: 146,
    name: "Monaco",
    iso: "MC"
  },
  {
    id: 147,
    name: "Mongolia",
    iso: "MN"
  },
  {
    id: 148,
    name: "Montenegro",
    iso: "ME"
  },
  {
    id: 149,
    name: "Montserrat",
    iso: "MS"
  },
  {
    id: 150,
    name: "Morocco",
    iso: "MA"
  },
  {
    id: 151,
    name: "Mozambique",
    iso: "MZ"
  },
  {
    id: 152,
    name: "Myanmar",
    iso: "MM"
  },
  {
    id: 153,
    name: "Namibia",
    iso: "NA"
  },
  {
    id: 154,
    name: "Nauru",
    iso: "NR"
  },
  {
    id: 155,
    name: "Nepal",
    iso: "NP"
  },
  {
    id: 156,
    name: "Netherlands",
    iso: "NL"
  },
  {
    id: 157,
    name: "New Caledonia",
    iso: "NC"
  },
  {
    id: 158,
    name: "New Zealand",
    iso: "NZ"
  },
  {
    id: 159,
    name: "Nicaragua",
    iso: "NI"
  },
  {
    id: 160,
    name: "Niger",
    iso: "NE"
  },
  {
    id: 161,
    name: "Nigeria",
    iso: "NG"
  },
  {
    id: 162,
    name: "Niue",
    iso: "NU"
  },
  {
    id: 163,
    name: "Norfolk Island",
    iso: "NF"
  },
  {
    id: 164,
    name: "Northern Mariana Islands",
    iso: "MP"
  },
  {
    id: 165,
    name: "Norway",
    iso: "NO"
  },
  {
    id: 166,
    name: "Oman",
    iso: "OM"
  },
  {
    id: 167,
    name: "Pakistan",
    iso: "PK"
  },
  {
    id: 168,
    name: "Palau",
    iso: "PW"
  },
  {
    id: 169,
    name: "Palestinian Territory, Occupied",
    iso: "PS"
  },
  {
    id: 170,
    name: "Panama",
    iso: "PA"
  },
  {
    id: 171,
    name: "Papua New Guinea",
    iso: "PG"
  },
  {
    id: 172,
    name: "Paraguay",
    iso: "PY"
  },
  {
    id: 173,
    name: "Peru",
    iso: "PE"
  },
  {
    id: 174,
    name: "Philippines",
    iso: "PH"
  },
  {
    id: 175,
    name: "Pitcairn",
    iso: "PN"
  },
  {
    id: 176,
    name: "Poland",
    iso: "PL"
  },
  {
    id: 177,
    name: "Portugal",
    iso: "PT"
  },
  {
    id: 178,
    name: "Puerto Rico",
    iso: "PR"
  },
  {
    id: 179,
    name: "Qatar",
    iso: "QA"
  },
  {
    id: 180,
    name: "Reunion",
    iso: "RE"
  },
  {
    id: 181,
    name: "Romania",
    iso: "RO"
  },
  {
    id: 182,
    name: "Russian Federation",
    iso: "RU"
  },
  {
    id: 183,
    name: "Rwanda",
    iso: "RW"
  },
  {
    id: 184,
    name: "Saint Barthelemy",
    iso: "BL"
  },
  {
    id: 185,
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    iso: "SH"
  },
  {
    id: 186,
    name: "Saint Kitts and Nevis",
    iso: "KN"
  },
  {
    id: 187,
    name: "Saint Lucia",
    iso: "LC"
  },
  {
    id: 188,
    name: "Saint Martin (French Part)",
    iso: "MF"
  },
  {
    id: 189,
    name: "Saint Pierre and Miquelon",
    iso: "PM"
  },
  {
    id: 190,
    name: "Saint Vincent and The Grenadines",
    iso: "VC"
  },
  {
    id: 191,
    name: "Samoa",
    iso: "WS"
  },
  {
    id: 192,
    name: "San Marino",
    iso: "SM"
  },
  {
    id: 193,
    name: "Sao Tome and Principe",
    iso: "ST"
  },
  {
    id: 194,
    name: "Saudi Arabia",
    iso: "SA"
  },
  {
    id: 195,
    name: "Senegal",
    iso: "SN"
  },
  {
    id: 196,
    name: "Serbia",
    iso: "RS"
  },
  {
    id: 197,
    name: "Seychelles",
    iso: "SC"
  },
  {
    id: 198,
    name: "Sierra Leone",
    iso: "SL"
  },
  {
    id: 199,
    name: "Singapore",
    iso: "SG"
  },
  {
    id: 200,
    name: "Sint Maarten (Dutch Part)",
    iso: "SX"
  },
  {
    id: 201,
    name: "Slovakia",
    iso: "SK"
  },
  {
    id: 202,
    name: "Slovenia",
    iso: "SI"
  },
  {
    id: 203,
    name: "Solomon Islands",
    iso: "SB"
  },
  {
    id: 204,
    name: "Somalia",
    iso: "SO"
  },
  {
    id: 205,
    name: "South Africa",
    iso: "ZA"
  },
  {
    id: 206,
    name: "South Georgia and The South Sandwich Islands",
    iso: "GS"
  },
  {
    id: 207,
    name: "South Sudan",
    iso: "SS"
  },
  {
    id: 208,
    name: "Spain",
    iso: "ES"
  },
  {
    id: 209,
    name: "Sri Lanka",
    iso: "LK"
  },
  {
    id: 210,
    name: "Sudan",
    iso: "SD"
  },
  {
    id: 211,
    name: "Suriname",
    iso: "SR"
  },
  {
    id: 212,
    name: "Svalbard and Jan Mayen",
    iso: "SJ"
  },
  {
    id: 213,
    name: "Sweden",
    iso: "SE"
  },
  {
    id: 214,
    name: "Switzerland",
    iso: "CH"
  },
  {
    id: 215,
    name: "Syrian Arab Republic",
    iso: "SY"
  },
  {
    id: 216,
    name: "Taiwan",
    iso: "TW"
  },
  {
    id: 217,
    name: "Tajikistan",
    iso: "TJ"
  },
  {
    id: 218,
    name: "Tanzania",
    iso: "TZ"
  },
  {
    id: 219,
    name: "Thailand",
    iso: "TH"
  },
  {
    id: 220,
    name: "Timor-Leste",
    iso: "TL"
  },
  {
    id: 221,
    name: "Togo",
    iso: "TG"
  },
  {
    id: 222,
    name: "Tokelau",
    iso: "TK"
  },
  {
    id: 223,
    name: "Tonga",
    iso: "TO"
  },
  {
    id: 224,
    name: "Trinidad and Tobago",
    iso: "TT"
  },
  {
    id: 225,
    name: "Tunisia",
    iso: "TN"
  },
  {
    id: 226,
    name: "Turkey",
    iso: "TR"
  },
  {
    id: 227,
    name: "Turkmenistan",
    iso: "TM"
  },
  {
    id: 228,
    name: "Turks and Caicos Islands",
    iso: "TC"
  },
  {
    id: 229,
    name: "Tuvalu",
    iso: "TV"
  },
  {
    id: 230,
    name: "Uganda",
    iso: "UG"
  },
  {
    id: 231,
    name: "Ukraine",
    iso: "UA"
  },
  {
    id: 232,
    name: "United Arab Emirates",
    iso: "AE"
  },
  {
    id: 233,
    name: "United Kingdom",
    iso: "GB"
  },
  {
    id: 234,
    name: "United States",
    iso: "US"
  },
  {
    id: 235,
    name: "United States Minor Outlying Islands",
    iso: "UM"
  },
  {
    id: 236,
    name: "Uruguay",
    iso: "UY"
  },
  {
    id: 237,
    name: "Uzbekistan",
    iso: "UZ"
  },
  {
    id: 238,
    name: "Vanuatu",
    iso: "VU"
  },
  {
    id: 239,
    name: "Venezuela",
    iso: "VE"
  },
  {
    id: 240,
    name: "Vietnam",
    iso: "VN"
  },
  {
    id: 241,
    name: "Virgin Islands, British",
    iso: "VG"
  },
  {
    id: 242,
    name: "Virgin Islands, U.S.",
    iso: "VI"
  },
  {
    id: 243,
    name: "Wallis and Futuna",
    iso: "WF"
  },
  {
    id: 244,
    name: "Western Sahara",
    iso: "EH"
  },
  {
    id: 245,
    name: "Yemen",
    iso: "YE"
  },
  {
    id: 246,
    name: "Zambia",
    iso: "ZM"
  },
  {
    id: 247,
    name: "Zimbabwe",
    iso: "ZW"
  },
  {
    id: 248,
    name: "Kosovo",
    iso: "RKS"
  },
  {
    id: 249,
    name: "Netherlands Antilles",
    iso: "ANT"
  }
];
