import React from "react";
import {useSelector} from "react-redux";
import {ReactComponent as IconVisa} from "../../../../../images/icons/card/visa-big.svg";
import {Button, TYPE} from "../../../../Button/Button";
import {ORDER_CARD_STEP} from "../../../../../utils/orderCardUtils";
import {isMobile} from "../../../../../utils/checkDevice";
import {KYC_STATUS} from "../../../../../constants/kycStatuses";
import {notification} from "../../../../Notification";
import classNames from "classnames";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../../../../constants/routes";


export const CardTypes = ({setStep, isOpen, redirectToKyc}) => {
  const history = useHistory();
  const user = useSelector(store => store.base.user);

  const handleNextStep = () => {
    if (user.kycStatus !== KYC_STATUS.APPROVED) {
      if (isMobile()) {
        history.push(ROUTE.DASHBOARD_KYC);
      } else {
        isOpen(false);
        redirectToKyc(true);
      }
      notification.info("Please pass identification first, then you can order cards.")
    } else {
      setStep(ORDER_CARD_STEP.SELECT);
    }
  }

  return (
    <div>
      <div className={classNames(
        "flex", "jsc", "gap-3-8",
        {"column": isMobile()}
      )
      }>
        <div className="flex column gap-1-5">
          <div className="card card-plastic card-order">
            <IconVisa/>
          </div>
          <div className="fs-1-125 fw-700">Plastic VISA Card</div>
          <div>Experience simple financial freedom with our physical Plastic VISA Card issued in EUR.
          </div>
          <div>Enjoy a 1% cash back in LMGTs on all your transactions.</div>
          <div className="flex column gap-0-625 fs-0-875">
            <div className="fw-700">Specification:</div>
            <div className="flex column gap-0-625">
              <div className="grid grid-column-2-1fr">
                <div>Price</div>
                <div>€ 195.00</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Delivery</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Activation</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Monthly fee</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Card to card</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Wallet to card</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Wallet to wallet</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Transactions</div>
                <div>4% + € 0.50</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Loading</div>
                <div>2%</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Second year fee</div>
                <div>€ 49.00</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Forex fees</div>
                <div>2.99%</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Cashback LMGT</div>
                <div>1%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex column gap-1-5">
          <div className="card card-metal card-order">
            <IconVisa/>
          </div>
          <div className="fs-1-125 fw-700">Silver Metal VISA Card</div>
          <div>Experience financial freedom with our exceptional physical Silver Metal VISA Card issued in EUR.
          </div>
          <div>Enjoy a 2% cash back in LMGT on all your transactions.</div>
          <div className="flex column gap-0-625 fs-0-875">
            <div className="fw-700">Specification:</div>
            <div className="flex column gap-0-625">
              <div className="grid grid-column-2-1fr">
                <div>Price</div>
                <div>€ 295.00</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Delivery</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Activation</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Monthly fee</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Card to card</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Wallet to card</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Wallet to wallet</div>
                <div>Free</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Transactions</div>
                <div>3% + € 0.50</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Loading</div>
                <div>1%</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Second year fee</div>
                <div>€ 99.00</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Forex fees</div>
                <div>2.99%</div>
              </div>
              <div className="grid grid-column-2-1fr">
                <div>Cashback LMGT</div>
                <div>2%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kyc-full-modal-button-container">
        <Button
          type={TYPE.PRIMARY}
          text="Next"
          onClick={handleNextStep}
          fullWidth={isMobile()}
        />
      </div>
    </div>
  )
}