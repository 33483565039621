import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {setUser} from "../../../store/actions/actions";
import {Input} from "../../../components/Input/Input";
import {Button, TYPE as BUTTON_TYPE} from "../../../components/Button/Button";
import {ROUTE} from "../../../constants/routes";
import {USER_STATUS} from "../../../constants/userStatuses";

export const SecurityCodeData = ({isOpen, isMobile = false}) => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.base.user);
  const history = useHistory();
  const [code, setCode] = useState("");
  const [errorCode, setErrorCode] = useState(null);
  const [buttonActive, setButtonActive] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await client.confirmEmail({
      code
    })
      .then(() => {
        if (!isMobile) {
          isOpen(false);
        }
        dispatch(setUser({...user, status: USER_STATUS.MEMBER}));
        history.push(ROUTE.DASHBOARD_WALLET);
      })
      .catch((error) => notification.warning(error.message));

  }

  const handleResend = async () => {
    await client.resendCode()
      .then(() => notification.success("OTP has been sent"))
      .catch((error) => notification.warning(error.message));
  }

  const resendEl = () => {
    return <div>No email arrived? <span onClick={handleResend} className="link">Resend it here.</span></div>
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Input
          name="code"
          label="Enter one-time password"
          error={errorCode}
          onChange={(e) => {
            setCode(e.target.value);
            if (e.target.value.length > 0) {
              setButtonActive(true);
            } else {
              setButtonActive(false);
            }
          }}
          onSubmit={handleSubmit}
          required
          autoFocus
          subtext={resendEl()}
        />

        <div className="modal-button-container">
          <Button
            type={buttonActive ? BUTTON_TYPE.PRIMARY_GRADIENT : BUTTON_TYPE.INACTIVE}
            text="Activate"
          />
        </div>
      </form>
    </>
  )
}