import React from "react";
import {ROUTE} from "../../../../../constants/routes";
import {MobileHeader} from "../../../../../components/MobileHeader/MobileHeader";
import {TransactionInfo} from "./components/TransactionInfo";
import {useParams} from "react-router-dom";

export const Transaction = () => {
  const params = useParams();
  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Transaction info"/>}
      <TransactionInfo id={params.id}/>
    </div>
  )
}