import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { client } from "../../../../../services/client";
import { notification } from "../../../../../components/Notification";
import { Input, INPUT_TYPES as INPUT_TYPE } from "../../../../../components/Input/Input";
import { Button, TYPE } from "../../../../../components/Button/Button";
import { isMobile } from "../../../../../utils/checkDevice";
import { setUser } from "../../../../../store/actions/actions";
import { ROUTE } from "../../../../../constants/routes";
import { useHistory } from "react-router-dom";

export const UpdatePhoneStep2 = ({ isOpen, data }) => {
  const user = useSelector(store => store.base.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);


  const confirm = async (e) => {
    e.preventDefault();

    if (loading) {
      return true;
    }

    setLoading(true);

    await client.updatePhoneConfirm({
      confirmationCode: otp,
      phone: data.phone,
      phoneCode: data.phoneCode
    })
      .then(() => {
        dispatch(setUser({ ...user, phone: data.phone, phoneCode: data.phoneCode }));
        notification.success("Phone number updated");
        if (!isMobile()) {
          isOpen(false);
        } else {
          history.push(ROUTE.DASHBOARD_WALLET)
        }
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }


  return (
    <>
      <div>
        One time password has been sent to your email. To confirm updating phone number, please enter the OTP.
      </div>
      <div>
        <form onSubmit={confirm}>
          <Input
            name="otp"
            label="OTP"
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
            autoFocus={true}
            type={INPUT_TYPE.TEXT}
            required
          />
          <div className="modal-button-container">
            {loading ? <div className="loader" /> : <Button
              type={TYPE.PRIMARY}
              text="Confirm"
              fullWidth={isMobile()}
            />}
          </div>
        </form>
      </div>
    </>
  )
}