import React from "react";
import {useHistory} from "react-router-dom";
import "./MobileHeader.css"
import {ReactComponent as ArrowIcon} from "../../images/icons/arrow-left.svg";
import {LS} from "../../constants/ls";

export const MobileHeader = ({url, text, func = null}) => {
  const history = useHistory();

  return(
    <div className="mobile-header">
      <div className="mobile-header-icon" onClick={() => {
        if (func === null) {
          localStorage.setItem(LS.ROUTE, url)
          history.push(url);
        }
        if (func) {
          func();
        }
      }}><ArrowIcon/></div>
      <div className="mobile-header-title">{text}</div>
    </div>
  )
}