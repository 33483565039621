import React, {useState} from "react";
import "./SelectDropdown.css"
import {ReactComponent as IconNotFound} from "../../../../images/icons/search/search-user-not-found.svg"
import {Input} from "../../../Input/Input";


export const SelectDropdown = ({items, callback, isOpen, search = false}) => {
  const [data, setData] = useState(items);

  const handleSearch = (e) => {
    const v = e.target.value;
    const d = items.filter((el) => el.title.toLowerCase().includes(v.toLowerCase()));
    setData(d);
  }

  return (
    <>
      <div className="select-dropdown">
        {search && <Input
          label="Search"
          onChange={handleSearch}
          autofocus
        />
          }
        {data === null || data === undefined || data.length === 0 ? <div className="select-item-not-found">
          <IconNotFound/>
          <div>Not found</div>
        </div> : data.map((e, i) => {
          return <div key={i} className="select-dropdown-item" onClick={() => {
            callback(e);
            isOpen(false);
          }}>
            {e.icon !== undefined && e.icon}
            {e.title}
          </div>
        })}
      </div>
    </>
  )
}