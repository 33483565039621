import React from "react";
import { IntegratedWallets } from "../../../pages/dashboard/wallet/components/Integration/IntegratedWallets";
import "../Modal.css";
import "./ModalIntegratedWallets.css";

export const ModalIntegratedWallets = ({ isOpen, wallets }) => {
  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)} />
      <div className="modal">
        <div className="modal-header">
          <div className="modal-title">Integrated wallets</div>
          <div className="modal-close-button" onClick={() => isOpen(false)} />
        </div>
        <IntegratedWallets isOpen={isOpen} wallets={wallets} />
      </div>
    </>
  )
}