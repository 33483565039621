import React from "react";
import {
  TransferRequestStep1
} from "../../../../pages/dashboard/wallet/components/Request/components/TransferRequestStep1";


export const ModalTransferRequestStep1 = ({isOpen, setStep, setResponse}) => {
  return (
    <TransferRequestStep1 setStep={setStep} setResponse={setResponse}/>
  )
}