import React from "react";
import "./ModalTransaction.css";
import "../Modal.css";
import {TransactionInfo} from "../../../pages/dashboard/wallet/components/Transactions/components/TransactionInfo";

export const ModalTransaction = ({isOpen, transaction = null, id}) => {
  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="modal modal-transaction">
        <div className="modal-header">
          <div className="modal-title">Transaction info</div>
          <div className="modal-close-button" onClick={() => isOpen(false)}/>
        </div>
        <TransactionInfo isOpen={isOpen} transaction={transaction} id={id}/>
      </div>
    </>
  )
}