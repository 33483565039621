import React from 'react';
import {useRoutes} from "./routes";
import {BrowserRouter} from 'react-router-dom'
import {useSelector} from "react-redux";
import LanguageProvider from "./components/LanguageProvider/LanguageProvider";

function App() {
  const authInfo = useSelector((state) => ({
    isAuth: state.base.isAuth,
    user: state.base.user
  }));
  const {isAuth, user} = authInfo;

  const routes = useRoutes(isAuth, user?.status);

  return (
    <LanguageProvider>
      <BrowserRouter>
        {routes}
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default App;
