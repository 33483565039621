import React, { useEffect, useState } from "react";
import "../Drawer.css";
import "./DrawerNotifications.css";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerNotifications, setUnreadCount } from "../../../store/actions/actions";
import { ReactComponent as IconClose } from "../../../images/icons/close.svg";
import { ReactComponent as IconBellBig } from "../../../images/icons/notifications/bell-big.svg";
import { client } from "../../../services/client";
import { ROUTE } from "../../../constants/routes";
import { useHistory } from "react-router-dom";

export const DrawerNotifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector(store => store.base.drawerNotifications);
  const [data, setData] = useState(null);
  const unreadCount = useSelector(store => store.base.unreadCount);


  useEffect(() => {
    if (state === true) {
      client.getAllMessages({
        page: 0,
        size: 10
      }).then(setData);
    }
  }, [state]);

  const markRead = (e) => {
    client.readMessage(e.id)
      .then(() => {
        let el = document.getElementById("message" + e.id);
        el.classList.remove("drawer-notifications-item-unread");
        dispatch(setUnreadCount(unreadCount - 1))
      });
  }

  const handleContent = (content) => {
    if (content.includes("[[REQ_ID")) {
      const regId = /\[\[REQ_ID_(?<id>\d+?)\b/i;
      const regRep = /\[\[REQ_ID_\d+]]/i;

      const match = regId.exec(content);
      const id = match?.groups?.id;
      const r = content.replace(regRep, "");
      return [r, ROUTE.DASHBOARD_REQUEST_TRANSFER.replace(":id", id)];
    }
    if (content.includes("[[TRANS_ID")) {
      const transId = /\[\[TRANS_ID_(?<id>\d+?)\b/i;
      const transRep = /\[\[TRANS_ID_\d+]]/i;

      const match = transId.exec(content);
      const id = match?.groups?.id;
      const r = content.replace(transRep, "");
      return [r, ROUTE.DASHBOARD_TRANSACTION.replace(":id", id)];
    } if (content.includes("[[ORDER_ID")) {
      const orderId = /\[\[ORDER_ID_(?<id>\d+?)\b/i;
      const orderRep = /\[\[ORDER_ID_\d+]]/i;

      const match = orderId.exec(content);
      const id = match?.groups?.id;
      const r = content.replace(orderRep, "");
      return [r, ROUTE.DASHBOARD_ORDER.replace(":id", id)];
    } else {
      return [content, null];
    }


  }

  const renderNoData = () => {
    return <div className="notifications-empty">
      <IconBellBig />
      <div>
        <div className="no-data notifications-empty-title">Nothing here</div>
        <div className="no-data">You don't have notifications</div>
      </div>
    </div>
  }

  const renderMessages = () => {
    return data.content.map((e, i) => {
      const r = handleContent(e.content);
      return <div id={"message" + e.id} key={i} className={e.status === "READ" ? "drawer-notifications-item" : "drawer-notifications-item drawer-notifications-item-unread"} onClick={() => {
        e.status === "UNREAD" && markRead(e);
        history.push(r[1]);
      }}>
        <div className="drawer-notification-status"></div>
        <div className="drawer-notification-item-content">
          <div>{e.title}</div>
          <div>{r[0]}</div>
          <div>{e.createdAt}</div>
        </div>
      </div>
    })
  }


  return (
    <>
      {state && <div className="drawer-bg" onClick={() => dispatch(setDrawerNotifications(false))} />}

      <div
        className={state === true ? "drawer notifications-drawer drawer-enabled notifications-drawer-enabled" : "drawer notifications-drawer"}>
        <div className="notifications-drawer-header">
          <div>Notifications</div>
          <IconClose onClick={() => dispatch(setDrawerNotifications(false))} />
        </div>
        {data === null || data.content.length === 0 ? renderNoData() :
          <div className="drawer-notifications-container">{renderMessages()}</div>}
      </div>
    </>
  )
}