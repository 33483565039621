
import React from "react";

import { ReactComponent as IconFb } from "../../../images/icons/social/fb.svg"
import { ReactComponent as IconX } from "../../../images/icons/social/x.svg"
import { ReactComponent as IconIg } from "../../../images/icons/social/ig.svg"

export const LandingCardsFooter = () => {
    const DOMAIN = process.env.REACT_APP_DOMAIN || "https://payonow.com"
    return (
        <div className="landing-cards-footer">
            <div className="landing-cards-footer-inner">
                <div className="landing-cards-footer-menu">
                    <div>
                        <div className="fw-400">Shopping with cash back</div>
                        <div>Increase cash back with our debit card</div>
                        <div>Popular stores</div>
                        <div>Highest cash back stores</div>
                    </div>
                    <div>
                        <div className="fw-400">Policies</div>
                        <div onClick={() => window.open(DOMAIN + "/terms.pdf", "_blank")}>Terms of Service</div>
                        <div onClick={() => window.open(DOMAIN + "/policy.pdf", "_blank")}>Privacy Policy</div>
                        <div onClick={() => window.open(DOMAIN + "/agreement.pdf", "_blank")}>Cardholder Agreement</div>
                        <div>Cookies Policy</div>
                    </div>
                    <div>
                        <div className="fw-400">Debit cards</div>
                        <div>Basic debit card</div>
                        <div>Metal debit card</div>
                    </div>
                    <div>
                        <div className="fw-400">Support</div>
                        <div>Help center</div>
                        <div>Contact support</div>
                    </div>
                </div>

                <div className="landing-cards-footer-icons">
                    <IconFb />
                    <IconX />
                    <IconIg />
                </div>
                <div className="mw-43 fs-0-8125 fw-300 center ln-1-25">
                    Payonow, a venture under the umbrella of Excitement Factory S.R.L. located at Los Yoses, Calle 39, Avenidas 8 och 10, San José, San Pedro, 11501, Costa Rica, functions seamlessly under the financial licenses extended by EU banks and VISA through our cooperative partnerships.
                    <div>© 2022-{new Date().getFullYear()} Payonow</div>
                </div>
            </div>
        </div>
    )
}