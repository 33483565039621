import React from "react";
import "./SearchUserDropdown.css"
import {ReactComponent as IconNotFound} from "../../../images/icons/search/search-user-not-found.svg"

export const SearchUserDropdown = ({users, setSelected, setIsOpen}) => {
  return (
    <>
      <div className="search-user-dropdown">
        {users === null || users === undefined || users.length === 0 ? <div className="search-user-not-found">
          <IconNotFound/>
          <div>Not found</div>
        </div> : users.map((e, i) => {
          return <div key={i} className="search-user-dropdown-item" onClick={() => {
            setSelected(e);
            setIsOpen(false);
          }}>
            {e}
          </div>
        })}
      </div>
    </>

  )
}