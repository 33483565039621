import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {client} from "../../../services/client";
import {
  setNewTransactions,
  setUnreadCount,
  setUser,
  setWallet
} from "../../../store/actions/actions";
import "./DashboardLayout.css";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";
import {Sidebar} from "../../Sidebar/Sidebar";
import {isMobile} from "../../../utils/checkDevice";
import {TopPanel} from "../../TopPanel/TopPanel";
import {MobileFooter} from "../../MobileFooter/MobileFooter";
import {DrawerNotifications} from "../../Drawer/DrawerNotifications/DrawerNotifications";
import {DrawerAccount} from "../../Drawer/DrawerAccount";
import {notification} from "../../Notification";

export const DashboardLayout = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);
  const wallet = useSelector(store => store.base.wallet);
  const unreadCount = useSelector(store => store.base.unreadCount);
  const newTransactions = useSelector(store => store.base.newTransactions);


  const fetchCurrentUser = useCallback(async () => {
    const isAuth = !!localStorage.accessToken && !!localStorage.refreshToken;
    if (user === null && isAuth) {
      await client.getCurrentUser()
        .then((r) => dispatch(setUser(r)))
        .catch((error) => notification.warning(error.message));
    }
  }, [user, history]);

  const fetchWallet = useCallback(async () => {
    if (wallet === null) {
      await client.getWallet()
        .then((r) => dispatch(setWallet(r)))
        .catch((error) => notification.warning(error.message));
    }
  }, [wallet, history]);

  const fetchUnreadCount = useCallback(async () => {
      await client.getUnreadCount()
        .then((r) => dispatch(setUnreadCount(r.count)))
  }, [unreadCount, history]);


  useEffect(() => {
    fetchCurrentUser();
    fetchWallet();
    fetchUnreadCount();

    if (localStorage.route !== undefined) {
      const _url = history.location.pathname;
      if (_url === localStorage.route) {
        history.push(_url);
      } else {
        history.push(localStorage.route);
      }
    }
  }, []);

  useEffect(() => {
    const sse = client.getStream();

    sse.addEventListener("unread-count", (event) => {
      dispatch(setUnreadCount(event.data));
    });

    sse.addEventListener("balance-e-wallet", (event) => {
      dispatch(setWallet({...wallet, ewalletBalance: +event.data}));
    });

    sse.addEventListener("new-transaction", (event) => {
      dispatch(setNewTransactions(newTransactions + 1));
    });

    sse.addEventListener("error", (event) => {
      console.log(event);
    });
  }, [dispatch])


  return (
    <LanguageConsumer>
      {({dict}) => (
        isMobile() ? <div className="dashboard-layout-mobile">
            <DrawerAccount/>
            <DrawerNotifications/>
            {children}
            <MobileFooter/>
          </div> :
          <div className="dashboard-layout">
            <Sidebar/>
            <DrawerNotifications/>
            <div className="dashboard-content-container">
              <TopPanel/>
              <div className="dashboard-content">
                {children}
              </div>
            </div>
          </div>
      )}
    </LanguageConsumer>
  )
}