import React from "react";
import { WalletIntegration } from "../../../pages/dashboard/wallet/components/Integration/WalletIntegration";
import "../Modal.css";
import "./ModalIntegration.css";

export const ModalIntegration = ({ isOpen }) => {
  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)} />
      <div className="modal modal-integration">
        <div className="modal-header">
          <div className="modal-title">Wallet integration</div>
          <div className="modal-close-button" onClick={() => isOpen(false)} />
        </div>
        <WalletIntegration isOpen={isOpen} />
      </div>
    </>
  )
}