import React from "react";
import "./FullModalSteps.css"
import {ReactComponent as IconDone} from "../../../../../images/icons/kyc/kyc-step-done.svg";
import {ReactComponent as IconCurrent} from "../../../../../images/icons/kyc/kyc-step-current.svg";
import {ReactComponent as IconNext} from "../../../../../images/icons/kyc/kyc-step-next.svg";

export const FullModalSteps = ({items, step}) => {

  const getClass = (id) => {
    if (id < step) {
      return " kyc-step-title-done";
    } else if (id > step) {
      return " kyc-step-title-next"
    } else {
      return "";
    }
  }

  const getIcon = (id) => {
    if (id < step) {
      return <IconDone/>
    } else if (id > step) {
      return <IconNext/>
    } else {
      return <IconCurrent/>
    }
  }

  const renderItems = () => {
    return items.map((k, v) => {
      return <div key={k.id} className="kyc-step-item">
        {getIcon(k.id)}
        <div className={"subtitle1" + getClass(k.id)}>{k.title}</div>
      </div>
    })
  }

  return(
    <div className="kyc-steps">
      {renderItems()}
    </div>
  )
}