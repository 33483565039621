import React, { useEffect, useState } from "react";
import { isMobile } from "../../../../../../utils/checkDevice";
import { useDispatch, useSelector } from "react-redux";
import "../../KycFullModal.css"
import { Input } from "../../../../../Input/Input";
import 'react-calendar/dist/Calendar.css';
import country from "../../../../../../constants/country";
import { client } from "../../../../../../services/client";
import { Button, TYPE } from "../../../../../Button/Button";
import { setUser } from "../../../../../../store/actions/actions";
import { notification } from "../../../../../Notification";
import { Select } from "../../../../../Select/Select";

export const ResidentialAddressForm = () => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [postCode, setPostCode] = useState();

  useEffect(() => {
    country.map((e, i) => {
      countries.push({
        id: e.id,
        title: e.name,
        iso: e.iso,
        value: e.id,
        icon: <span className={"fi fi-" + e.iso.toLowerCase()}></span>
      })
    });
  }, []);

  useEffect(() => {
    client.getUserResidentialAddress()
      .then((r) => {
        setAddress(r.address);
        setCity(r.city);
        setPostCode(r.postCode);
        if (r.countryId !== undefined && r.countryId !== null && r.countryId !== "") {
          setSelectedCountry(countries.filter((e) => e.id === r.countryId)[0]);
        } else {
          getCountryByIp();
        }
      })
      .catch((error) => notification.warning(error.message));
  }, []);

  const getCountryByIp = () => {
    client.getIpData().then((r) => {
      if (r && Object.keys(r).length > 0) {
        setSelectedCountry(countries.filter((e) => e.iso === r.iso)[0]);
      } else {
        setSelectedCountry(countries[0]);
      }
    });
  }

  const renderHeader = () => {
    if (isMobile()) {
      return <div>
        <div className="subtitle1">Residential address</div>
      </div>
    } else {
      return <div>
        <h3>Residential address</h3>
        <div className="subtitle1 kyc-description">Fill out your basic information: your legal name, date of birth,
          country of citizenship, tax id, source of income and if you are a politically exposed person.
        </div>
      </div>
    }
  }

  const update = async (e) => {
    e.preventDefault();
    await client.updateUserResidentialAddress({
      address,
      city,
      postCode,
      countryId: selectedCountry.id
    })
      .then(() => {
        dispatch(setUser({ ...user, kycStep: 2 }))
      })
      .catch((error) => notification.warning(error.message))
  }

  return (
    <div>
      {renderHeader()}
      {selectedCountry ? <form className="kyc-form" onSubmit={update}>
        <Input
          name="address"
          label="Address"
          value={address}
          // error={errorEmail}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          onSubmit={update}
          required
        />
        <Input
          name="city"
          label="City"
          value={city}
          // error={errorEmail}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          onSubmit={update}
          required
        />
        <Input
          name="postCode"
          label="Post code"
          value={postCode}
          // error={errorEmail}
          onChange={(e) => {
            setPostCode(e.target.value);
          }}
          onSubmit={update}
          required
        />
        <div className="kyc-form-input">
          {selectedCountry && <Select
            items={countries}
            search
            label="Residential country"
            selected={selectedCountry}
            callback={setSelectedCountry} />}
        </div>

        <div className="kyc-full-modal-button-container">
          {!isMobile() && <Button
            type={TYPE.SECONDARY}
            text="Back"
            onClick={(e) => {
              e.preventDefault();
              dispatch(setUser({ ...user, kycStep: 0 }));
            }}
            fullWidth={false}
          />}
          <Button
            type={isMobile() ? TYPE.PRIMARY_GRADIENT : TYPE.PRIMARY}
            text="Next"
            onClick={(e) => {

            }}
            fullWidth={isMobile()}
          />
        </div>
      </form> : <div className="loader form-loader" />}
    </div>
  )
}