import React, {useState} from "react";
import {MobileHeader} from "../../../../../components/MobileHeader/MobileHeader";
import {ROUTE} from "../../../../../constants/routes";
import {SendStep1} from "./components/SendStep1";
import {SendStep2} from "./components/SendStep2";

export const Send = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(1);
  const [modalSecurityPinIsOpen, setModalSecurityPinIsOpen] = useState(false);

  const renderContent = () => {
    if (step === 1) {
      return <SendStep1 setStep={setStep} setData={setData}/>
    } else if (step === 2) {
      return <SendStep2 setStep={setStep} data={data} setModalSecurityPinIsOpen={setModalSecurityPinIsOpen}/>
    }
  }

  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Send"/>}
      {renderContent()}
    </div>
  )
}