import React from "react";
import {ROUTE} from "../../../../../constants/routes";
import {MobileHeader} from "../../../../../components/MobileHeader/MobileHeader";
import {OrderInfo} from "./components/OrderInfo";
import {useParams} from "react-router-dom";

export const Order = () => {
  const params = useParams();
  
  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Order info"/>}
      <OrderInfo id={params.id}/>
    </div>
  )
}