import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ROUTE} from "../../constants/routes";
import {useDispatch} from "react-redux";
import {logout} from "../../store/actions/actions";

export const RefRedirect = () => {
  const history = useHistory()
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    history.push(ROUTE.SIGNUP + "?ref=" + params.ref);
  }, [])

  return (
    <></>
  )
}