import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button, TYPE } from "../../components/Button/Button";
import { ROUTE } from "../../constants/routes";
import { ReactComponent as IconArrowDown } from "../../images/icons/arrow-down.svg";
import "./Landing.css";
import { LandingCardsFooter } from "./components/LandingCardsFooter";
import { LandingProducts } from "./components/LandingProducts";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export const LandingCardMobile = () => {
  const history = useHistory();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const scrollTo1 = () => ref1.current.scrollIntoView();
  const scrollTo2 = () => ref2.current.scrollIntoView();
  const scrollTo3 = () => ref3.current.scrollIntoView();

  useEffect(() => {
    CookieConsent.run({

      categories: {
        necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
        },
        analytics: {}
      },

      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'Cookies',
              description: 'Our website uses some cookies. You can disable data collection and cookies by changing your browser settings, but it may affect how this website functions',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences'
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Somebody said ... cookies?',
                  description: 'I want one!'
                },
                {
                  title: 'Strictly Necessary cookies',
                  description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Performance and Analytics',
                  description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics'
                },
                // {
                //   title: 'More information',
                //   description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                // }
              ]
            }
          }
        }
      }
    });
  }, []);



  return (
    <div>
      <div ref={ref1} className="landing-card-first-page">
        <div className="landing-card-second-page-inner1-mobile gap-0-5">
          <picture className="h-full center">
            <img alt="Payonow" src="/img/logo-white.svg" />
          </picture>
          <div className="flex items-center column center gap-4-5">
            <div className="fs-1-85 fw-300 ls-0-78">Money Made Simple<br />
              No Restriction Anywhere
            </div>
            <picture className="mw-17-85">
              <div className="guest-cards"></div>
            </picture>
          </div>
          <div className="flex items-center jsc gap-1 column">
            <Button
              type={TYPE.PRIMARY_LANDING}
              text="Log in"
              fullWidth={true}
              onClick={() => history.push(ROUTE.LOGIN)}
            />
            <Button
              type={TYPE.SECONDARY_LANDING}
              text="Get a free account"
              fullWidth
              onClick={() => history.push(ROUTE.SIGNUP)}
            />
          </div>
          <div className="mx-auto center flex column mt-1" onClick={scrollTo2}>
            <IconArrowDown />
          </div>
        </div>

      </div>
      <div ref={ref2} className="landing-card-second-page">
        <div className="flex column items-center jsc gap-2 landing-card-second-page-inner2-mobile">
          <div className="landing-card-mobile-block landing-card-second-page-block1 ">
            <div className="mb-1-42 fs-1-428 fw-500 ls-06">Send money to anyone free of charge.</div>
            <div className="fw-300 ls-0-42 lh-24 mb-3-57">Optimize Your Cash and Crypto Management for Seamless
              Day-to-Day Transactions Worldwide!
            </div>
            <div className="flex column gap-10px">
              <div className="flex items-center gap-10px jsc">
                <img width={65} height={65} alt="Payonow" src="/img/landing-cards/a1.png" />
                <img width={24} height={24} alt="Payonow" src="/img/landing-cards/arrows.svg" />
                <img width={65} height={65} alt="Payonow" src="/img/landing-cards/a2.png" />
                <img width={24} height={24} alt="Payonow" src="/img/landing-cards/arrows.svg" />
                <img width={65} height={65} alt="Payonow" src="/img/landing-cards/a3.png" />
              </div>
              <div className="flex items-center jsc gap-85px">
                <img className="rotate-right-90" width={24} height={24} alt="Payonow"
                  src="/img/landing-cards/arrows.svg" />
                <img className="rotate-right-90" width={24} height={24} alt="Payonow"
                  src="/img/landing-cards/arrows.svg" />
                <img className="rotate-right-90" width={24} height={24} alt="Payonow"
                  src="/img/landing-cards/arrows.svg" />
              </div>
              <div className="flex items-center gap-10px jsc">
                <img width={65} height={65} alt="Payonow" src="/img/landing-cards/a4.png" />
                <img width={24} height={24} alt="Payonow" src="/img/landing-cards/arrows.svg" />
                <img width={65} height={65} alt="Payonow" src="/img/landing-cards/a5.png" />
                <img width={24} height={24} alt="Payonow" src="/img/landing-cards/arrows.svg" />
                <img width={65} height={65} alt="Payonow" src="/img/landing-cards/a6.png" />
              </div>
            </div>
            <div className="landing-earth" />
          </div>

          <div className="landing-card-mobile-block fw-300 ls-0-42 lh-24">
            <div>Experience the future of financial management with an all-in-one platform designed to make your daily
              spending effortless and accessible from anywhere in the world. This is the modern way to handle your
              finances.
            </div>
          </div>

          <Button
            type={TYPE.SECONDARY_LANDING}
            text="Get a free account"
            onClick={() => history.push(ROUTE.SIGNUP)}
          />
        </div>
      </div>

      <div ref={ref3} className="landing-card-second-page">
        <div className="flex column jsc items-center gap-2 landing-card-second-page-inner2-mobile">
          <div className="landing-card-mobile-block fw-300 ls-0-42 lh-24 w-full">
            <div className="mb-1-42 fs-1-428 fw-500 ls-06">Go from cash to crypto with ease.</div>
            <ul className="list-style-disc landing-cards-ul">
              <li>Fund with crypto or bank transfers.</li>
              <li>Accessible world-wide, no geo restrictions.</li>
              <li>Earn cash back on every transaction.</li>
            </ul>
            <picture className="landing-card-coins">
              <img width={81} height={74} alt="Payonow" src="/img/landing-cards/btc/btc1.png" />
              <img width={71} height={77} alt="Payonow" src="/img/landing-cards/btc/btc2.png" />
              <img width={69} height={76} alt="Payonow" src="/img/landing-cards/btc/btc3.png" />
              <img width={79} height={77} alt="Payonow" src="/img/landing-cards/btc/btc4.png" />
              <img width={86} height={75} alt="Payonow" src="/img/landing-cards/btc/btc5.png" />
            </picture>
          </div>

          <Button
            type={TYPE.SECONDARY_LANDING}
            text="Get a free account"
            onClick={() => history.push(ROUTE.SIGNUP)}
          />
        </div>
      </div>

      <LandingProducts />
      <LandingCardsFooter />
    </div>
  )
}