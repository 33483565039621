import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalIntegration } from "../../../../../components/Modal/ModalIntegration/ModalIntegration";
import { ROUTE } from "../../../../../constants/routes";
import { Button, SIZE } from "../../../../../components/Button/Button";
import { client } from "../../../../../services/client";
import { ModalIntegratedWallets } from "../../../../../components/Modal/ModalIntegratedWallets/ModalIntegratedWallets";

export const WalletIntegrationSnippet = ({ isMobile = false }) => {
    const history = useHistory();
    const user = useSelector(store => store.base.user);
    const [totalWallets, setTotalWallets] = useState();
    const [lmgtWallets, setLmgtWallets] = useState();
    const [modalIntegrationIsOpen, setModalIntegrationIsOpen] = useState(false);
    const [modalIntegratedWalletsIsOpen, setModalIntegratedWalletsIsOpen] = useState(false);

    useEffect(() => {
        client.getLmgtWallets()
            .then((r) => {
                setTotalWallets(r.length);
                setLmgtWallets(r);
            })
            .catch()
    }, [])


    const open = (e) => {
        if (isMobile) {
            history.push(ROUTE.DASHBOARD_WALLET_INTEGRATION)
        } else {
            setModalIntegrationIsOpen(true);
        }
    }

    const renderTotalWallets = () => {
        if (totalWallets && totalWallets === 0) {
            return <span>{totalWallets}</span>
        } else if (totalWallets > 0) {
            return <span className="link" onClick={() => {
                if (isMobile) {
                    history.push(ROUTE.DASHBOARD_WALLET_INTEGRATED)
                } else {
                    setModalIntegratedWalletsIsOpen(true)
                }
            }
            }>{totalWallets}</span>
        }
    }


    const renderData = () => {
        return <div>
            <div className="mb-1-42">Integrated wallets: {renderTotalWallets()}</div>
            <Button
                text="Add more"
                size={SIZE.SMALL}
                fullWidth={false}
                onClick={() => open()}
            />
        </div>
    }

    return (
        <div className={isMobile ? "block block-big no-shadow" : "block block-big"}>
            <div className="block-title">Wallet integration</div>

            <div>
                {user && renderData()}

            </div>
            {modalIntegrationIsOpen && <ModalIntegration isOpen={setModalIntegrationIsOpen} />}
            {modalIntegratedWalletsIsOpen && lmgtWallets && <ModalIntegratedWallets isOpen={setModalIntegratedWalletsIsOpen} wallets={lmgtWallets} />}
        </div>
    )
}