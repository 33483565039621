import React, {useState} from "react";
import {ReactComponent as IconDetails} from "../../../../../../images/icons/transactions/details.svg"
import {ReactComponent as IconSent} from "../../../../../../images/icons/wallet/send.svg"
import {useSelector} from "react-redux";
import {client} from "../../../../../../services/client";
import {notification} from "../../../../../../components/Notification";
import {Button, TYPE} from "../../../../../../components/Button/Button";
import {isMobile} from "../../../../../../utils/checkDevice";
import {toEur} from "../../../../../../utils/tools";
import {Input, INPUT_TYPES as INPUT_TYPE} from "../../../../../../components/Input/Input";
import {ROUTE} from "../../../../../../constants/routes";
import {LS} from "../../../../../../constants/ls";
import {useHistory} from "react-router-dom";

export const SendStep2 = ({isOpen, data, setModalSecurityPinIsOpen}) => {
  const user = useSelector(store => store.base.user);
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const history = useHistory();

  const confirm = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }
    setLoading(true);

    await client.checkPin({
      pin: pin
    })
      .then(() => {
        client.sendFunds({
          username: data.toUser,
          amount: +data.amount
        })
          .then(() => setSent(true))
          .catch((error) => notification.warning(error.message))
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        notification.warning(error.message);
        setLoading(false);
      });
  }

  const close = () => {
    if (isMobile()) {
      history.push(ROUTE.DASHBOARD_WALLET);
      localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET);
    } else {
      isOpen(false);
    }
  }


  const getForm = () => {
    if (user) {
      if (sent) {
        return <>
          <div className="modal-button-container">
            <Button
              type={TYPE.PRIMARY}
              text="Close"
              onClick={() => close()}
              fullWidth={isMobile()}
            />
          </div>
        </>
      } else {
        if (user.pinSet === true) {
          return <form onSubmit={confirm}>
            <Input
              name="pin"
              label="Security PIN"
              onChange={(e) => setPin(e.target.value)}
              value={pin}
              autoFocus={true}
              type={INPUT_TYPE.PASSWORD}
              required
            />
            <div className="modal-button-container">
              {loading ? <div className="loader"/> : <Button
                type={TYPE.PRIMARY}
                text="Confirm"
                fullWidth={isMobile()}
              />}
            </div>
          </form>
        } else {
          return <>
            <div className="error center">You did not set your security pin. To make transfers you must enter you
              security
              pin. Please click the button bellow to set your pin and then try again.
            </div>
            <div className="modal-button-container">
              <Button
                type={TYPE.PRIMARY}
                text="Security pin"
                onClick={(e) => {
                  e.preventDefault();
                  if (isMobile()) {
                    history.push(ROUTE.DASHBOARD_ACCOUNT_PIN);
                  } else {
                    isOpen(false);
                    setModalSecurityPinIsOpen(true);
                  }
                }}
                fullWidth={isMobile()}
              />
            </div>
          </>
        }
      }
    }
  }

  const renderIcon = () => {
    if (sent) {
      return <div className="modal-send-icon">
        <IconSent/>
        <div>Sent</div>
      </div>
    } else {
      return <div className="modal-send-icon-details">
        <IconDetails/>
        <div>Details</div>
      </div>
    }
  }

  const renderContent = () => {
    return <>
      {renderIcon()}
      <div className="modal-transfer-info-block">
        <div className="modal-transfer-info-block-item">
          <div>From</div>
          <div>E-Wallet</div>
        </div>
        <div className="modal-transfer-info-block-item">
          <div>To user</div>
          <div>{data.toUser}</div>
        </div>
        <div className="modal-transfer-info-block-item">
          <div>Amount</div>
          <div>{toEur(+data.amount)}</div>
        </div>
      </div>
      {getForm()}
    </>
  }

  return (
    renderContent()
  )
}