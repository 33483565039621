import React from "react";
import "./Button.css";
import {ReactComponent as ShareIcon} from "../../images/icons/button/share.svg"
import {ReactComponent as CopyIcon} from "../../images/icons/button/copy.svg";
import {ReactComponent as ActivateIcon} from "../../images/icons/button/activate.svg";

export const SIZE = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large"
}

export const TYPE = {
  PRIMARY: "primary",
  PRIMARY_GRADIENT: "primary-gradient",
  SECONDARY: "secondary",
  INACTIVE: "inactive",
  WHITE: "white",
  PRIMARY_LANDING: "primary-landing",
  SECONDARY_LANDING: "secondary-landing"
}

export const ICON = {
  SHARE: "share",
  COPY: "copy",
  ACTIVATE: "activate",
}

export const JUSTIFY_CONTENT = {
  CENTER: "CENTER",
  SPACE_BETWEEN: "SPACE_BETWEEN",
}

export const Button = ({
                         type,
                         size = SIZE.MEDIUM,
                         fullWidth = true,
                         onClick,
                         text,
                         rightIcon,
                         leftIcon,
                         justifyContent = JUSTIFY_CONTENT.CENTER,
                         submit = false
                       }) => {
  const buildClasses = () => {
    let classes = "button button-type-" + type + " button-size-" + size;
    if (fullWidth) {
      classes += " button-fullwidth"
    }
    if (justifyContent === JUSTIFY_CONTENT.SPACE_BETWEEN) {
      classes += " button-space-between"
    }
    return classes;
  }

  const renderRightIcon = (icon) => {
    switch (icon) {
      case ICON.SHARE:
        return <div className="blue-icon">
          {<ShareIcon/>}
        </div>
      case ICON.COPY:
        return <div className="white-icon">
          {<CopyIcon/>}
        </div>
    }
  }

  const renderLeftIcon = (icon) => {
    switch (icon) {
      case ICON.ACTIVATE:
        return <div className="white-icon">
          {<ActivateIcon/>}
        </div>
    }
  }

  return (
    <button className={buildClasses()} onClick={onClick} type={submit ? "submit" : ""}>
      {renderLeftIcon(leftIcon)}
      <div>{text}</div>
      {renderRightIcon(rightIcon)}
    </button>
  )
}