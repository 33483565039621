import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KYC_STATUS } from "../../../../../../constants/kycStatuses";
import { ReactComponent as UploadIcon } from "../../../../../../images/icons/kyc/upload.svg";
import { client } from "../../../../../../services/client";
import { setUser } from "../../../../../../store/actions/actions";
import { isMobile } from "../../../../../../utils/checkDevice";
import { Button, TYPE } from "../../../../../Button/Button";
import { notification } from "../../../../../Notification";
import { Select } from "../../../../../Select/Select";

const ID_TYPE = [
  {
    id: 1,
    title: "Utility bill",
    value: "UTILITY_BILL"
  },
  {
    id: 2,
    title: "Other",
    value: "OTHER"
  },
]

export const VerifyAddress = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.base.user);
  const [idType, setIdType] = useState(ID_TYPE[0]);
  const [proof, setProof] = useState(null);
  const [errorProof, setErrorProof] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (proof === null) {
      setErrorProof("Front proof required");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("idType", idType.value)
    formData.append("proof", proof)
    formData.append("docNumber", "123")

    await client.verifyAddress(formData)
      .then(() => {
        dispatch(setUser({...user, kycStep: 5, kycStatus: KYC_STATUS.PENDING}));
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }

  const renderHeader = () => {
    if (isMobile()) {
      return <div>
        <div className="subtitle1">Verify your address</div>
      </div>
    } else {
      return <div>
        <h3>Verify your address</h3>
        <div className="subtitle1 kyc-description">Upload address proof</div>
      </div>
    }
  }
  return (
    <div>
      {renderHeader()}
      <form className="kyc-form" onSubmit={handleSubmit}>
        <div className="input">
          <div className="input">
            {idType && <Select
              items={ID_TYPE}
              label="Document type"
              selected={idType}
              callback={setIdType}/>}
          </div>
        </div>

        <div className={proof !== null ? "kyc-load-file kyc-load-file-active" : "kyc-load-file"}>
          <input
            accept="image/*"
            style={{display: 'none'}}
            id="proof"
            type="file"
            onChange={(e) => {
              setProof(e.target.files[0]);
              setErrorProof(null);
            }}
          />
          {proof === null ? <label htmlFor="proof">
            <UploadIcon/>
            <div>Click here to upload address proof</div>
          </label> : <div>
            <img src={URL.createObjectURL(proof)}/>
            <div className="paragraph-small kyc-load-file-filename">
              <div>{proof.name}</div>
              <div className="link" onClick={() => setProof(null)}>Remove</div>
            </div>
          </div>
          }
          {errorProof && <div className="input-error-message">{errorProof}</div>}
        </div>

        <div className="kyc-full-modal-button-container">
          {!isMobile() && <Button
            type={TYPE.SECONDARY}
            text="Back"
            onClick={(e) => {
              e.preventDefault();
              dispatch(setUser({...user, kycStep: 3}));
            }}
            fullWidth={false}
          />}
          <Button
            type={isMobile() ? TYPE.PRIMARY_GRADIENT : TYPE.PRIMARY}
            text="Next"
            onClick={(e) => {

            }}
            fullWidth={isMobile()}
          />
        </div>

      </form>
    </div>
  )
}