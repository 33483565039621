import React, {useState} from "react";
import "../Modal.css";
import "./ModalTransferRequest.css"
import {ModalTransferRequestDataStep1} from "./components/RequestData/ModalTransferRequestDataStep1";
import {ModalTransferRequestDataStep2} from "./components/RequestData/ModalTransferRequestDataStep2";
import {ModalTransferRequestDataStep3} from "./components/RequestData/ModalTransferRequestDataStep3";

export const ModalTransferRequestData = ({isOpen, reqId}) => {
  const [step, setStep] = useState(1);
  const [response, setResponse] = useState(null);

  const renderContent = () => {
    if (step === 1) {
      return <ModalTransferRequestDataStep1 isOpen={isOpen} setStep={setStep}  setResponse={setResponse}  reqId={reqId}/>
    } else if (step === 2 && response) {
      return <ModalTransferRequestDataStep2 isOpen={isOpen} setStep={setStep} response={response}/>
    } else if (step === 3 && response) {
      return <ModalTransferRequestDataStep3 isOpen={isOpen} setStep={setStep} response={response}/>
    }
  }

  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="modal modal-transfer-request">
        <div className="modal-header">
          <div className="modal-title">Transfer info</div>
          <div className="modal-close-button" onClick={() => isOpen(false)}/>
        </div>
        {renderContent()}
      </div>
    </>
  )
}