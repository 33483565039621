import React, { useEffect, useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import styles from './Notice.styles';

const useStyles = createUseStyles(styles);
const contentPrefix = {
  info: '',
  success: '',
  warning: '',
};

export const Notice= ({ id, type, content, delay = 5000, onClose }) => {
  const classes = useStyles();
  const timerRef = useRef(null);

  const handleClose = useCallback(() => {
    setFadeOut(true);
    window.setTimeout(() => {
      if (onClose) {
        onClose(id);
      }
    }, 500);
  }, [onClose, id]);

  const [fadeOut, setFadeOut] = useState(false);
  useEffect(() => {
    timerRef.current = window.setTimeout(() => {
      handleClose();
    }, delay - 500);
    return () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    };
  }, [handleClose, delay]);

  return (
    <li
      className={classnames(classes.notice, classes.fadeIn, type, {
        [classes.fadeOut]: fadeOut,
      })}
    >
      {contentPrefix[type]}
      {content}
      {/*<IconClose className={classes.closeButton} onClick={handleClose} />*/}
    </li>
  );
};
