import React from 'react';
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { DashboardLayout } from "./components/Layouts/DashboardLayout/DashboardLayout";
import { GuestLayout } from "./components/Layouts/GuestLayout/GuestLayout";
import { ROUTE } from "./constants/routes";
import { USER_STATUS } from "./constants/userStatuses";
import { Login } from "./pages/auth/login/Login";
import { ResetPasswordConfirm } from './pages/auth/reset-password/ResetPasswordConfirm';
import { ResetPasswordRequest } from "./pages/auth/reset-password/ResetPasswordRequest";
import { Signup } from "./pages/auth/signup/Signup";
import { Account } from './pages/dashboard/account/Account/Account';
import { Invite } from './pages/dashboard/account/Invite/Invite';
import { UpdatePassword } from './pages/dashboard/account/UpdatePassword/UpdatePassword';
import { UpdatePhone } from './pages/dashboard/account/UpdatePhone/UpdatePhone';
import { UpdatePin } from "./pages/dashboard/account/UpdatePin/UpdatePin";
import { OrderCards } from "./pages/dashboard/cards/OrderCards";
import { CryptoMobile } from './pages/dashboard/crypto/CryptoMobile';
import { Kyc } from "./pages/dashboard/kyc/Kyc";
import { Wallet } from "./pages/dashboard/wallet/Wallet";
import { Deposit } from "./pages/dashboard/wallet/components/Deposit/Deposit";
import { TransferRequest } from "./pages/dashboard/wallet/components/Request/TransferRequest";
import { TransferRequestData } from "./pages/dashboard/wallet/components/Request/TransferRequestData";
import { Send } from "./pages/dashboard/wallet/components/Send/Send";
import { Transaction } from "./pages/dashboard/wallet/components/Transactions/Transaction";
import { LandingCard } from "./pages/landing/LandingCard";
import { SecurityCode } from "./pages/otp/SecurityCode";
import { OrderRedirect } from "./pages/redirect/OrderRedirect";
import { RefRedirect } from "./pages/redirect/RefRedirect";
import { TransactionRedirect } from "./pages/redirect/TransactionRedirect";
import { TransferRequestRedirect } from "./pages/redirect/TransferRequestRedirect";
import { Order } from './pages/dashboard/wallet/components/Orders/Order';
import { WalletConfirmRedirect } from './pages/redirect/WalletConfirmRedirect';
import { WalletIntegrationConfirm } from './pages/dashboard/wallet/components/Integration/WalletIntegrationConfirm';
import { WalletIntegration } from './pages/dashboard/wallet/components/Integration/WalletIntegration';
import { IntegratedWallets } from './pages/dashboard/wallet/components/Integration/IntegratedWallets';


export const useRoutes = (isAuth, status) => {
  if (isAuth && status === USER_STATUS.MEMBER) {
    return (
      <DashboardLayout>
        <Switch>
          <Route path={ROUTE.DASHBOARD} exact>
            <Redirect to={ROUTE.DASHBOARD_WALLET} />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET} exact>
            <Wallet />
          </Route>

          <Route path={ROUTE.DASHBOARD_CRYPTO} exact>
            <CryptoMobile />
          </Route>

          <Route path={ROUTE.DASHBOARD_ACCOUNT} exact>
            <Account />
          </Route>

          <Route path={ROUTE.DASHBOARD_ACCOUNT_PIN} exact>
            <UpdatePin />
          </Route>

          <Route path={ROUTE.DASHBOARD_ACCOUNT_PHONE} exact>
            <UpdatePhone />
          </Route>

          <Route path={ROUTE.DASHBOARD_ACCOUNT_PASSWORD} exact>
            <UpdatePassword />
          </Route>

          <Route path={ROUTE.DASHBOARD_ACCOUNT_INVITE} exact>
            <Invite />
          </Route>

          <Route path={ROUTE.DASHBOARD_KYC} exact>
            <Kyc />
          </Route>

          <Route path={ROUTE.DASHBOARD_CARDS_ORDER} exact>
            <OrderCards />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET_SEND} exact>
            <Send />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET_DEPOSIT} exact>
            <Deposit />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET_RECEIVE} exact>
            <TransferRequest />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET_REQUEST_TRANSFER_DATA} exact>
            <TransferRequestData />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET_TRANSACTION} exact>
            <Transaction />
          </Route>

          <Route path={ROUTE.TRANSACTION}>
            <TransactionRedirect />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET_ORDER} exact>
            <Order />
          </Route>

          <Route path={ROUTE.ORDER}>
            <OrderRedirect />
          </Route>

          <Route path={ROUTE.TRANSFER}>
            <TransferRequestRedirect />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET_INTEGRATION}>
            <WalletIntegration />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET_INTEGRATION_CONFIRM}>
            <WalletIntegrationConfirm />
          </Route>

          <Route path={ROUTE.DASHBOARD_WALLET_INTEGRATED}>
            <IntegratedWallets />
          </Route>

          <Route path={ROUTE.ROOT}>
            <Redirect to={ROUTE.DASHBOARD_WALLET} />
          </Route>

          <Route path={ROUTE.OTP} exact>
            <SecurityCode />
          </Route>


        </Switch>
      </DashboardLayout>
    )
  } else {
    return (
      <GuestLayout>
        <Switch>
          <Route path={ROUTE.ROOT} exact>
            <LandingCard />
          </Route>

          <Route path={ROUTE.LOGIN} exact>
            <Login />
          </Route>

          <Route path={ROUTE.SIGNUP} exact>
            <Signup />
          </Route>

          <Route path={ROUTE.PASSWORD_RESET_REQUEST} exact>
            <ResetPasswordRequest />
          </Route>

          <Route path={ROUTE.PASSWORD_RESET_CONFIRM} exact>
            <ResetPasswordConfirm />
          </Route>

          <Route path={ROUTE.REF_LINK} exact>
            <RefRedirect />
          </Route>

          <Route path={ROUTE.TRANSACTION}>
            <TransactionRedirect />
          </Route>

          <Route path={ROUTE.ORDER}>
            <OrderRedirect />
          </Route>

          <Route path={ROUTE.TRANSFER}>
            <TransferRequestRedirect />
          </Route>

          <Route path={ROUTE.OTP} exact>
            <SecurityCode />
          </Route>

          <Route path={ROUTE.WALLET_INTEGRATION_CONFIRM_REDIRECT}>
            <WalletConfirmRedirect />
          </Route>

          <Route path={ROUTE.ROOT}>
            <Redirect to={ROUTE.ROOT} />
          </Route>
        </Switch>
      </GuestLayout>
    )
  }
}