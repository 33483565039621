import React, {useState} from "react";
import {TransferRequestDataStep1} from "./components/RequestData/TransferRequestDataStep1";
import {TransferRequestDataStep2} from "./components/RequestData/TransferRequestDataStep2";
import {TransferRequestDataStep3} from "./components/RequestData/TransferRequestDataStep3";
import {MobileHeader} from "../../../../../components/MobileHeader/MobileHeader";
import {ROUTE} from "../../../../../constants/routes";
import {useParams} from "react-router-dom";

export const TransferRequestData = () => {
  const [step, setStep] = useState(1);
  const [response, setResponse] = useState(null);
  const params = useParams();

  const renderContent = () => {
    if (step === 1) {
      return <TransferRequestDataStep1 setStep={setStep} setResponse={setResponse}  reqId={params.id}/>
    } else if (step === 2 && response) {
      return <TransferRequestDataStep2 setStep={setStep} response={response}/>
    } else if (step === 3 && response) {
      return <TransferRequestDataStep3 setStep={setStep} response={response}/>
    }
  }

  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Requested transfer"/>}
      {renderContent()}
    </div>
  )
}