export const ORDER_CARD_STEP = {
  TYPE: "TYPE",
  SELECT: "SELECT",
  DETAILS: "DETAILS",
  PAYMENT: "PAYMENT"
}

export const getStepTitle = (step) => {
  switch (step) {
    case ORDER_CARD_STEP.TYPE: return "Type of debit cards";
    case ORDER_CARD_STEP.SELECT: return "Select cards";
    case ORDER_CARD_STEP.DETAILS: return "Order details";
    case ORDER_CARD_STEP.PAYMENT: return "Payment information";
  }
}