import { jwt } from './jwt';
import { api } from './api';

import { API_URLS } from './apiUrls';

const BASIC_CODE = process.env.REACT_APP_BASIC_CODE;

export class Client {

  //Users
  auth2fa = ({ email, password }) =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  refreshToken = () =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=refresh_token&refresh_token=${jwt.refreshToken}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  //Users
  getCurrentUser = () => api.get(API_URLS.USERS.CURRENT);
  signup = (data) => api.post(API_URLS.USERS.SIGNUP, data);
  changePassword = (data) => api.put(API_URLS.USERS.CHANGE_PASSWORD, data);
  changeUsername = (data) => api.put(API_URLS.USERS.CHANGE_USERNAME, data);
  confirmEmail = (data) => api.post(API_URLS.USERS.CONFIRM_EMAIL, data);
  resendCode = () => api.post(API_URLS.USERS.RESEND_CODE);
  updatePinRequest = () => api.post(API_URLS.USERS.UPDATE_PIN_REQUEST);
  updatePinConfirm = (data) => api.post(API_URLS.USERS.UPDATE_PIN_CONFIRM, data);
  checkPin = (data) => api.post(API_URLS.USERS.CHECK_PIN, data);
  requestResetPassword = (data) => api.post(API_URLS.USERS.PASSWORD_RESET_REQUEST, data);
  confirmResetPassword = (data) => api.post(API_URLS.USERS.PASSWORD_RESET_CONFIRM, data);
  updatePhoneRequest = () => api.post(API_URLS.USERS.UPDATE_PHONE_REQUEST);
  updatePhoneConfirm = (data) => api.post(API_URLS.USERS.UPDATE_PHONE_CONFIRM, data);
  getIpData = () => api.get(API_URLS.USERS.IP_DATA);
  searchUser = (data) => api.get(API_URLS.USERS.SEARCH(data));
  checkRef = (data) => api.get(API_URLS.USERS.CHECK_REF(data));

  //KYC
  getUserInfo = () => api.get(API_URLS.USER_INFO.GET_USER_INFO);
  updateUserInfo = (data) => api.put(API_URLS.USER_INFO.UPDATE_USER_INFO, data);
  getUserResidentialAddress = () => api.get(API_URLS.USER_RESIDENTIAL_ADDRESS.GET_USER_RESIDENTIAL_ADDRESS);
  updateUserResidentialAddress = (data) => api.put(API_URLS.USER_RESIDENTIAL_ADDRESS.UPDATE_USER_RESIDENTIAL_ADDRESS, data);
  getUserShippingAddress = () => api.get(API_URLS.USER_SHIPPING_ADDRESS.GET_USER_SHIPPING_ADDRESS);
  updateUserShippingAddress = (data) => api.put(API_URLS.USER_SHIPPING_ADDRESS.UPDATE_USER_SHIPPING_ADDRESS, data);
  verifyIdentity = (data) => api.post(API_URLS.USER_VERIFY.IDENTITY, data);
  verifyAddress = (data) => api.post(API_URLS.USER_VERIFY.ADDRESS, data);

  //Orders
  getAllActivePaymentMethods = () => api.get(API_URLS.ORDERS.PAYMENT_METHODS);
  getOrderById = (id) => api.get(API_URLS.ORDERS.GET_BY_ID(id));
  getLastPendingOrder = () => api.get(API_URLS.ORDERS.LAST_PENDING);
  createOrder = (data) => api.post(API_URLS.ORDERS.CREATE, data);
  getPrice = (quantity, loadAmount) => api.get(API_URLS.ORDERS.PRICE(quantity, loadAmount));

  //Wallets
  getWallet = () => api.get(API_URLS.WALLETS.GET);
  getLmgtWallets = () => api.get(API_URLS.WALLETS.GET_LMGT_WALLETS);
  requestLmgtWalletIntegration = (data) => api.post(API_URLS.WALLETS.REQUEST_LMGT_WALLET_INTEGRATION, data);
  confirmLmgtWalletIntegration = (data) => api.post(API_URLS.WALLETS.CONFIRM_LMGT_WALLET_INTEGRATION, data);

  //Transfers
  sendFunds = (data) => api.post(API_URLS.TRANSFERS.SEND, data);

  //Transfer requests
  createTransferRequest = (data) => api.post(API_URLS.TRANSFER_REQUESTS.CREATE, data);
  getTransferRequestById = (id) => api.get(API_URLS.TRANSFER_REQUESTS.GET_BY_ID(id));
  resolveTransferRequest = (id) => api.put(API_URLS.TRANSFER_REQUESTS.RESOLVE(id));
  declineTransferRequest = (id) => api.put(API_URLS.TRANSFER_REQUESTS.DECLINE(id));
  cancelTransferRequest = (id) => api.put(API_URLS.TRANSFER_REQUESTS.CANCEL(id));
  getTransferRequestsFromMe = (params) => api.get(API_URLS.TRANSFER_REQUESTS.FROM_ME, {params});
  getTransferRequestsToMe = (params) => api.get(API_URLS.TRANSFER_REQUESTS.TO_ME, {params});

  //Transactions
  getAllTransactions = (params) => api.get(API_URLS.TRANSACTIONS.GET_ALL, {params});
  getTransactionById = (id) => api.get(API_URLS.TRANSACTIONS.GET_BY_ID(id));

  //Stat
  getTransactionStat = () => api.get(API_URLS.STAT.TRANSACTIONS);

  //Messages
  getUnreadCount = () => api.get(API_URLS.MESSAGES.GET_UNREAD_COUNT);
  getAllMessages = (params) => api.get(API_URLS.MESSAGES.GET_ALL, {params});
  getMessageById = (id) => api.get(API_URLS.MESSAGES.GET_BY_ID(id));
  readMessage = (id) => api.put(API_URLS.MESSAGES.READ(id));


  //SSE
  getStream = () => api.setStream(API_URLS.SSE.SUBSCRIBE);
}

export const client = new Client();
