import React, {useState} from "react";
import QRCode from "qrcode.react";
import {Button, SIZE as BUTTON_SIZE, TYPE} from "../../../../../../components/Button/Button";
import {isMobile} from "../../../../../../utils/checkDevice";
import {ROUTE} from "../../../../../../constants/routes";
import {LS} from "../../../../../../constants/ls";
import {useHistory} from "react-router-dom";

export const TransferRequestStep2 = ({isOpen, setStep, response}) => {
  const history = useHistory();
  const [buttonText, setButtonText] = useState("copy");

  const close = () => {
    if (isMobile()) {
      history.push(ROUTE.DASHBOARD_WALLET);
      localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET);
    } else {
      isOpen(false);
    }
  }


  return (
    <>
      <div className="center">
        Notification about request has been sent to <b>{response?.toUsername}</b>. <br/>
        The user will receive notification in dashboard, but you can send the link directly.
      </div>
      <div className="copy-container">
        <div>{response?.url}</div>
        <Button size={BUTTON_SIZE.SMALL} fullWidth={false} text={buttonText} onClick={() => {
          navigator.clipboard.writeText(response?.url);
          setButtonText("copied!");
        }}/>
      </div>
      <div className="center">Or show the QR code <br/>
        <b>{response?.toUsername}</b> can scan this</div>
      <div className="center">
        <QRCode value={response?.url}/>
      </div>
      <div className="modal-button-container">
        <Button
          type={TYPE.PRIMARY}
          text="OK"
          onClick={() => close()}
          fullWidth={isMobile()}
        />
      </div>
    </>
  )
}