import React, {useEffect, useState} from "react";
import {isMobile} from "../../../../../../utils/checkDevice";
import {useDispatch, useSelector} from "react-redux";
import "../../KycFullModal.css"
import {Input} from "../../../../../Input/Input";
import 'react-calendar/dist/Calendar.css';
import country from "../../../../../../constants/country";
import {client} from "../../../../../../services/client";
import {Button, TYPE} from "../../../../../Button/Button";
import {setUser} from "../../../../../../store/actions/actions";
import {notification} from "../../../../../Notification";
import {Select} from "../../../../../Select/Select";

export const ShippingAddressForm = () => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [postCode, setPostCode] = useState();
  const [isResidentialData, setIsResidentialData] = useState(false);

  useEffect(() => {
    country.map((e, i) => {
      countries.push({
        id: e.id,
        title: e.name,
        iso: e.iso,
        value: e.id,
        icon: <span className={"fi fi-" + e.iso.toLowerCase()}></span>
      })
    });
  }, []);

  useEffect(() => {
    client.getUserShippingAddress()
      .then((r) => {
        setAddress(r.address);
        setCity(r.city);
        setPostCode(r.postCode);
        if (r.countryId !== undefined && r.countryId !== null && r.countryId !== "") {
          setSelectedCountry(countries.filter((e) => e.id === r.countryId)[0]);
        } else {
          getCountryByIp();
        }
      })
      .catch((error) => notification.warning(error.message));
  }, []);

  const getCountryByIp = () => {
    client.getIpData().then((r) => {
      if (r && Object.keys(r).length > 0) {
        setSelectedCountry(countries.filter((e) => e.iso === r.iso)[0]);
      } else {
        setSelectedCountry(countries[0]);
      }
    });
  }

  const getCounties = () => {
    return country.map((el, i) => {
      return <option selected={el.id === countryId} key={i} value={el.id}>{el.name}</option>
    })
  }

  const loadResidentialAddress = () => {
    client.getUserResidentialAddress()
      .then((r) => {
        setAddress(r.address);
        setCity(r.city);
        setPostCode(r.postCode);
        if (r.countryId !== undefined && r.countryId !== null && r.countryId !== "") {
          setSelectedCountry(countries.filter((e) => e.id === r.countryId)[0]);
        } else {
          getCountryByIp();
        }
      })
      .catch((error) => notification.warning(error.message));
  }

  const renderHeader = () => {
    if (isMobile()) {
      return <div>
        <div className="subtitle1">Shipping address</div>
      </div>
    } else {
      return <div>
        <h3>Shipping address</h3>
        <div className="subtitle1 kyc-description">Fill out your shipping address if you want the delivery of debit
          cards to be made to a separate location or you may load data from your residential address.
        </div>
      </div>
    }
  }

  const update = async (e) => {
    e.preventDefault();
    await client.updateUserShippingAddress({
      address,
      city,
      postCode,
      countryId: selectedCountry.id
    })
      .then(() => {
        dispatch(setUser({...user, kycStep: 3}))
      })
      .catch((error) => notification.warning(error.message))
  }

  return (
    <div>
      {renderHeader()}
      {selectedCountry ? <form className="kyc-form" onSubmit={update}>
        <Input
          name="address"
          label="Address"
          value={address}
          // error={errorEmail}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          onSubmit={update}
          required
          readOnly={isResidentialData}
        />
        <Input
          name="city"
          label="City"
          value={city}
          // error={errorEmail}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          onSubmit={update}
          required
          readOnly={isResidentialData}
        />
        <Input
          name="postCode"
          label="Post code"
          value={postCode}
          // error={errorEmail}
          onChange={(e) => {
            setPostCode(e.target.value);
          }}
          onSubmit={update}
          required
          readOnly={isResidentialData}
        />
        <div className="kyc-form-input">
          {selectedCountry && <Select
            items={countries}
            search
            label="Country"
            selected={selectedCountry}
            callback={setSelectedCountry}/>}
        </div>

        <div className="auth-terms subtitle">
          <input type="checkbox" id="is_residential_data" onChange={(e) => {
            if (e.target.checked) {
              loadResidentialAddress();
            }
            setIsResidentialData(!isResidentialData);
          }} checked={isResidentialData}/>
          <label for="is_residential_data">Use your residential data</label>
        </div>

        <div className="kyc-full-modal-button-container">
          {!isMobile() && <Button
            type={TYPE.SECONDARY}
            text="Back"
            onClick={(e) => {
              e.preventDefault();
              dispatch(setUser({...user, kycStep: 1}));
            }}
            fullWidth={false}
          />}
          <Button
            type={isMobile() ? TYPE.PRIMARY_GRADIENT : TYPE.PRIMARY}
            text="Next"
            onClick={(e) => {

            }}
            fullWidth={isMobile()}
          />
        </div>
      </form> : <div className="loader form-loader"/>}
    </div>
  )
}