import React from "react";
import {ReactComponent as BellIcon} from "../../../../images/icons/top-panel/bell.svg";
import {setDrawerNotifications} from "../../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import "./NotificationIcon.css"

export const NotificationIcon = () => {
  const unreadCount = useSelector(store => store.base.unreadCount);
  const dispatch = useDispatch();

  return (
    <div className="notification-icon">
      {unreadCount > 0 && <div className="unread-counter" onClick={() => dispatch(setDrawerNotifications(true))}>{unreadCount}</div>}
      <BellIcon onClick={() => dispatch(setDrawerNotifications(true))}/>
    </div>
  )
}