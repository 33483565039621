import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconShare } from "../../images/icons/share.svg";
import { ReactComponent as AccountIcon } from "../../images/icons/top-panel/account.svg";
import { ReactComponent as IconEyeClosed } from "../../images/icons/top-panel/eye-closed.svg";
import { ReactComponent as IconEyeOpened } from "../../images/icons/top-panel/eye-opened.svg";
import { setShowBalance } from "../../store/actions/actions";
import { ProfileDropdownMenu } from "../Dropdown/ProfileDropdown/ProfileDropdownMenu";
import { ModalUpdatePassword } from "../Modal/ModalUpdatePassword/ModalUpdatePassword";
import { ModalUpdatePhone } from "../Modal/ModalUpdatePhone/ModalUpdatePhone";
import { ModalUpdatePin } from "../Modal/ModalUpdatePin/ModalUpdatePin";
import { useComponentVisible } from "../UseComponentVisible/UseComponentVisible";
import "./TopPanel.css";
import { NotificationIcon } from "./components/NotificationIcon/UnreadCounter";
import { ROUTE } from "../../constants/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ModalInvite } from "../Modal/ModalInvite/ModalInvite";
import { KycFullModal } from "../Modal/Full/KYC/KycFullModal";
import { KYC_STATUS } from "../../constants/kycStatuses";

export const TopPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);
  const showBalance = useSelector(store => store.base.showBalance);
  const [modalSecurityPinIsOpen, setModalSecurityPinIsOpen] = useState(false);
  const [modalPhoneIsOpen, setModalPhoneIsOpen] = useState(false);
  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false);
  const [modalInviteIsOpen, setModalInviteIsOpen] = useState(false);
  const [modalKycIsOpen, setModalKycIsOpen] = useState(false);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const handleClickProfilePopup = (event) => {
    setIsComponentVisible(!isComponentVisible);
  };

  const getTitle = () => {
    const url = history.location.pathname;
    if (url === ROUTE.DASHBOARD_ACCOUNT) {
      return "Account information";
    } else {
      return "Overview";
    }
  }

  const renderStatusLabel = () => {
    if (user) {
      if (user.kycStatus === KYC_STATUS.IN_PROGRESS) {
        return "Your account is not activated:";
      } else {
        return "Account status:"
      }
    }
  }

  const renderStatusButton = () => {
    if (user) {
      if (user.kycStatus === KYC_STATUS.IN_PROGRESS) {
        return <div className="mobile-button mobile-button-blue-fill" onClick={() => setModalKycIsOpen(true)}>
          Activate
        </div>
      } else if (user.kycStatus === KYC_STATUS.PENDING) {
        return <div className="mobile-button mobile-button-orange">
          Pending KYC
        </div>
      } else if (user.kycStatus === KYC_STATUS.REJECTED) {
        return <div className="mobile-button mobile-button-red">
          Rejected KYC
        </div>
      } else if (user.kycStatus === KYC_STATUS.APPROVED) {
        return <div className="mobile-button mobile-button-green">
          Approved
        </div>
      }
    }
  }

  return (
    <div className="top-panel">
      <h6 className="top-panel-left">{getTitle()}</h6>
      <div className="top-panel-right" ref={ref}>
        <div className="top-panel-status-container">
          <div>
            {renderStatusLabel()}
          </div>
          <div>
            {renderStatusButton()}
          </div>
        </div>
        <div className="top-panel-icons-container">
          {showBalance ? <IconEyeClosed onClick={() => dispatch(setShowBalance(false))} /> : <IconEyeOpened onClick={() => dispatch(setShowBalance(true))} />}
          <IconShare onClick={() => setModalInviteIsOpen(true)} />
          <NotificationIcon />
          <AccountIcon onClick={handleClickProfilePopup} />
        </div>
        {isComponentVisible && <ProfileDropdownMenu isOpen={setIsComponentVisible} setSecurityPinModal={setModalSecurityPinIsOpen} setPhoneModal={setModalPhoneIsOpen} setPasswordModal={setModalPasswordIsOpen} />}
      </div>
      {modalSecurityPinIsOpen && <ModalUpdatePin isOpen={setModalSecurityPinIsOpen} />}
      {modalPhoneIsOpen && <ModalUpdatePhone isOpen={setModalPhoneIsOpen} />}
      {modalPasswordIsOpen && <ModalUpdatePassword isOpen={setModalPasswordIsOpen} />}
      {modalInviteIsOpen && <ModalInvite isOpen={setModalInviteIsOpen} />}
      {modalKycIsOpen && <KycFullModal isOpen={setModalKycIsOpen} />}
    </div>
  )
}