import {toEur} from "./tools";

export const mapTransactionType = (type) => {
  switch(type) {
    case "USER_TRANSFER": return "User transfer";
  }
}

export const mapWalletType = (type) => {
  switch(type) {
    case "E_WALLET": return "E-Wallet";
  }
}

export const getAmount = (user, e) => {
  if (user.id === e.toUserId) {
    return <div className="transactions-item-amount-in">+{toEur(e.amount)}</div>
  } else {
    return <div className="transactions-item-amount-out">-{toEur(e.amount)}</div>
  }
}