import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/mobile-button.css';
import App from './App';
import {Provider} from "react-redux";
import interceptor from "./services/interceptor";
import {store} from "./store/configureStore";
import {jwt} from "./services/jwt";
import {logout, setAuth} from "./store/actions/actions";
import {NotificationManagerWithStyle} from "./components/Notification";

interceptor.injectStore(store);

const isAuth = !!localStorage.accessToken && !!localStorage.refreshToken;
store.dispatch(setAuth(isAuth));
if (isAuth && jwt.isExpired()) {
  store.dispatch(logout());
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App/>
      <NotificationManagerWithStyle/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
