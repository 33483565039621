import React from "react";
import {isMobile} from "../../../../../../utils/checkDevice";
import {Button, TYPE} from "../../../../../Button/Button";
import {ROUTE} from "../../../../../../constants/routes";
import {useHistory} from "react-router-dom";
import {LS} from "../../../../../../constants/ls";

export const KycCompleted = ({callback}) => {
  const history = useHistory();


  const renderHeader = () => {
    if (isMobile()) {
      return <div className="kyc-completed-mobile">
        <div className="kyc-completed-img"/>
        <h5>Done!</h5>
        <div className="paragraph-small kyc-completed-mobile-description">You're all set. We will process your updated
          KYC within the next 24-48 hours. Once your card order is accepted, we will manufacture and ship it within one
          week.
        </div>
      </div>
    } else {
      return <div>
        <h3>Completed</h3>
        <div className="subtitle1 kyc-description">You're all set. We will process your KYC within the next 24-48 hours.
          Once your card order is accepted, we will manufacture and ship it within one week.
        </div>
      </div>
    }
  }
  return (
    <div>
      {renderHeader()}

      <div className="kyc-full-modal-button-container">
        <Button
          type={isMobile() ? TYPE.PRIMARY_GRADIENT : TYPE.PRIMARY}
          text="OK"
          onClick={(e) => {
            if (isMobile()) {
              history.push(ROUTE.DASHBOARD_WALLET);
              localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET);
            } else {
              callback();
            }
          }}
          fullWidth={isMobile()}
        />
      </div>
    </div>
  )
}