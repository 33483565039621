import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Drawer.css";

import { useHistory } from "react-router-dom";
import { ROUTE } from "../../constants/routes";
import { ReactComponent as IconClose } from "../../images/icons/close.svg";
import { ReactComponent as IconLogout } from "../../images/icons/logout.svg";
import { ReactComponent as IconAccount } from "../../images/icons/menu/account.svg";
import { ReactComponent as IconHelp } from "../../images/icons/menu/help.svg";
import { ReactComponent as IconMobile } from "../../images/icons/menu/mobile.svg";
import { ReactComponent as IconPassword } from "../../images/icons/menu/password.svg";
import { ReactComponent as IconSecurity } from "../../images/icons/menu/security.svg";
import { logout, setDrawerAccount } from "../../store/actions/actions";
import { isMobile } from "../../utils/checkDevice";
import { getName } from "../../utils/userUtils";
import { ModalUpdatePassword } from "../Modal/ModalUpdatePassword/ModalUpdatePassword";
import { ModalUpdatePhone } from "../Modal/ModalUpdatePhone/ModalUpdatePhone";
import { ModalUpdatePin } from "../Modal/ModalUpdatePin/ModalUpdatePin";
import { LS } from "../../constants/ls";

export const DrawerAccount = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.base.user);
  const state = useSelector(store => store.base.drawerAccount);
  const history = useHistory();
  const [modalSecurityPinIsOpen, setModalSecurityPinIsOpen] = useState(false);
  const [modalPhoneIsOpen, setModalPhoneIsOpen] = useState(false);
  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false);

  return (
    <>
      {state && <div className="drawer-bg" onClick={() => dispatch(setDrawerAccount(false))} />}

      <div className={state === true ? "drawer drawer-enabled" : "drawer"}>
        <div className="drawer-header">
          <div className="drawer-header-close-container">
            <IconClose onClick={() => dispatch(setDrawerAccount(false))} />
          </div>
          <div>
            <h2>{getName(user)}</h2>
            <div className="drawer-header-email">{user?.email}</div>
          </div>
        </div>
        <div className="drawer-menu">
          <div className="drawer-menu-item" onClick={() => {
            history.push(ROUTE.DASHBOARD_ACCOUNT);
            localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_ACCOUNT);
            dispatch(setDrawerAccount(false));
          }}>
            <IconAccount />
            <div>Account</div>
          </div>
          <div className="drawer-menu-item" onClick={() => {
            if (isMobile()) {
              history.push(ROUTE.DASHBOARD_ACCOUNT_PHONE);
            } else {
              setModalPhoneIsOpen(true);
            }
            dispatch(setDrawerAccount(false));
          }}>
            <IconMobile />
            <div>Mobile phone</div>
          </div>
          <div className="drawer-menu-item" onClick={() => {
            if (isMobile()) {
              history.push(ROUTE.DASHBOARD_ACCOUNT_PASSWORD);
            } else {
              setModalPasswordIsOpen(true);
            }
            dispatch(setDrawerAccount(false));
          }}>
            <IconPassword />
            <div>Password</div>
          </div>
          <div className="drawer-menu-item" onClick={() => {
            if (isMobile()) {
              history.push(ROUTE.DASHBOARD_ACCOUNT_PIN);
            } else {
              setModalSecurityPinIsOpen(true);
            }
            dispatch(setDrawerAccount(false));
          }}>
            <IconSecurity />
            <div>Security pin</div>
          </div>
          <div className="drawer-menu-item">
            <IconHelp />
            <div>Get help</div>
          </div>
          <div className="drawer-menu-item" onClick={() => dispatch(logout())}>
            <IconLogout />
            <div>Logout</div>
          </div>
        </div>
      </div>
      {modalSecurityPinIsOpen && <ModalUpdatePin isOpen={setModalSecurityPinIsOpen} />}
      {modalPhoneIsOpen && <ModalUpdatePhone isOpen={setModalPhoneIsOpen} />}
      {modalPasswordIsOpen && <ModalUpdatePassword isOpen={setModalPasswordIsOpen} />}
    </>
  )
}