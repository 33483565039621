import React, { useEffect, useState } from "react";
import { isMobile } from "../../../../../../utils/checkDevice";
import { useDispatch, useSelector } from "react-redux";
import "../../KycFullModal.css"
import { Input } from "../../../../../Input/Input";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import country from "../../../../../../constants/country";
import { client } from "../../../../../../services/client";
import { Button, TYPE } from "../../../../../Button/Button";
import { setUser } from "../../../../../../store/actions/actions";
import { notification } from "../../../../../Notification";
import { Select } from "../../../../../Select/Select";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { GENDER_TYPES, INCOME_TYPES, POLITICAL_TYPES } from "../../../../../../constants/inputTypes";


export const PersonalInfoForm = ({ callback }) => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dob, setDob] = useState();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState(GENDER_TYPES[0]);
  const [tax, setTax] = useState();
  const [incomeType, setIncomeType] = useState(INCOME_TYPES[0]);
  const [politicalType, setPoliticalType] = useState(POLITICAL_TYPES[1]);
  const [mobileStep, setMobileStep] = useState(0);

  useEffect(() => {
    country.map((e, i) => {
      countries.push({
        id: e.id,
        title: e.name,
        value: e.id,
        iso: e.iso,
        icon: <span className={"fi fi-" + e.iso.toLowerCase()}></span>
      })
    });
  }, []);

  useEffect(() => {
    client.getUserInfo()
      .then((r) => {
        setFirstName(r.firstName);
        setLastName(r.lastName);
        setDob(r.dateOfBirth);
        setTax(r.tax);
        if (r.gender) {
          setGender(GENDER_TYPES.filter((e) => r.gender === e.value)[0]);
        }
        if (r.countryCitizenId !== undefined && r.countryCitizenId !== null && r.countryCitizenId !== "") {
          setSelectedCountry(countries.filter((e) => e.id === r.countryCitizenId)[0]);
        } else {
          getCountryByIp();
        }

        // setIncomeType(r.sourceOfIncome)
        // setPoliticalType(r.politicalPerson)
      })
      .catch((error) => notification.warning(error.message));
  }, [countries]);

  const getCountryByIp = () => {
    client.getIpData().then((r) => {
      if (r && Object.keys(r).length > 0) {
        setSelectedCountry(countries.filter((e) => e.iso === r.iso)[0]);
      } else {
        setSelectedCountry(countries[0]);
      }
    });
  }

  const renderHeader = () => {
    if (isMobile()) {
      return <div>
        <div className="subtitle1">Basic information</div>
      </div>
    } else {
      return <div>
        <h3>Basic information</h3>
        <div className="subtitle1 kyc-description">Fill out your basic information: your legal name, date of birth,
          country of citizenship, tax id, source of income and if you are a politically exposed person.
        </div>
      </div>
    }
  }

  const onChangeDob = (event) => {
    const offset = event.getTimezoneOffset()
    const date = new Date(event.getTime() - (offset * 60 * 1000))
    setDob(date.toISOString().slice(0, 10));
    setIsCalendarOpen(false);
  }

  const updateUserInfo = async (e) => {
    e.preventDefault();
    await client.updateUserInfo({
      firstName,
      lastName,
      tax,
      dob,
      gender: gender.value,
      countryId: selectedCountry.id,
      sourceOfIncome: incomeType.value,
      politicalPerson: politicalType.value
    })
      .then(() => {
        dispatch(setUser({ ...user, kycStep: 1 }))
      })
      .catch((error) => notification.warning(error.message))
  }

  return (
    <div>
      {renderHeader()}
      {(!isMobile() || (isMobile() && mobileStep === 1)) && countries.length > 0 ? <form className="kyc-form" onSubmit={updateUserInfo}>
        <Input
          name="firstName"
          label="First name"
          value={firstName}
          // error={errorEmail}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          // onSubmit={handleSignup}
          required
        />
        <Input
          name="lastName"
          label="Last name"
          value={lastName}
          // error={errorEmail}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          // onSubmit={handleSignup}
          required
        />

        <div className="kyc-form-input">
          {gender && <Select
            items={GENDER_TYPES}
            label="Gender"
            selected={gender}
            callback={setGender} />}
        </div>

        <div className="input dob">{isCalendarOpen ? <Calendar
          defaultActiveStartDate={new Date(1970, 0, 1)}
          onChange={onChangeDob} /> :
          <Input
            name="dob"
            label="Date of birth"
            // error={errorEmail}
            onChange={(e) => {
              setDob(e.target.value);
            }}
            // onSubmit={handleSignup}
            required
            value={dob}
            onFocus={() => setIsCalendarOpen(true)}
            onClick={() => setIsCalendarOpen(true)}
          />
        }</div>

        <div className="kyc-form-input">
          {selectedCountry && <Select
            items={countries}
            search
            label="Country of citizenship"
            selected={selectedCountry}
            callback={setSelectedCountry} />}
        </div>

        <Input
          name="tax"
          label="Personal tax ID/SSN"
          value={tax}
          // error={errorEmail}
          onChange={(e) => {
            setTax(e.target.value);
          }}
          // onSubmit={handleSignup}
          required
        />

        <div className="kyc-form-input">
          {incomeType && <Select
            items={INCOME_TYPES}
            label="Source of income"
            selected={incomeType}
            callback={setIncomeType} />}
        </div>

        <div className="kyc-form-input">
          {politicalType && <Select
            items={POLITICAL_TYPES}
            label="Political person"
            selected={politicalType}
            callback={setPoliticalType} />}
        </div>

        <div className="kyc-full-modal-button-container">
          {!isMobile() && <Button
            type={TYPE.SECONDARY}
            text="Back"
            onClick={(e) => callback()}
            fullWidth={false}
          />}
          <Button
            type={isMobile() ? TYPE.PRIMARY_GRADIENT : TYPE.PRIMARY}
            text="Next"
            onClick={(e) => {

            }}
            fullWidth={isMobile()}
          />
        </div>
      </form> : !isMobile() && <div className="loader form-loader" />}
      {isMobile() && mobileStep === 0 && <div className="kyc-mobile-step0">
        <div className="paragraph-medium">In order to apply for our services, you’ll have to verify your identity and
          address.
          <br />
          <br />
          You’ll need to provide:
        </div>
        <br />
        <br />
        <ul className="paragraph-medium">
          <li>Some personal information: your name, date of birth etc.</li>
          <li>A picture of your passport or ID card</li>
          <li>A utility bill or proof of address</li>
        </ul>
        <div className="kyc-full-modal-button-container">
          <Button
            type={isMobile() ? TYPE.PRIMARY_GRADIENT : TYPE.PRIMARY}
            text="Next"
            onClick={(e) => {
              setMobileStep(1);
            }}
            fullWidth={isMobile()}
          />
        </div>
      </div>}
    </div>
  )
}