import React, {useState} from "react";
import {ReactComponent as IconNotFound} from "../../../../../../images/icons/search/search-user-not-found.svg";
import {useComponentVisible} from "../../../../../../components/UseComponentVisible/UseComponentVisible";
import {client} from "../../../../../../services/client";
import {Input, INPUT_ICON} from "../../../../../../components/Input/Input";
import {Button, TYPE} from "../../../../../../components/Button/Button";
import {isMobile} from "../../../../../../utils/checkDevice";
import {SearchUserDropdown} from "../../../../../../components/Dropdown/SearchUserDropdown/SearchUserDropdown";

export const SendStep1 = ({isOpen, setStep, setData}) => {
  const [tab, setTab] = useState(1);
  const [users, setUsers] = useState(null);
  const [selected, setSelected] = useState(null);
  const [amount, setAmount] = useState(null);

  const TABS = [
    {id: 1, title: "Payonow users"},
    {id: 2, title: "My accounts"}
  ];

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const search = (v) => {
    client.searchUser(v)
      .then(setUsers)
  }

  const handleSearchUser = (e) => {
    let v = e.target.value;
    setSelected(v);
    if (v !== undefined && v !== "" && v !== null) {
      search(v);
      setIsComponentVisible(true);
    } else {
      setIsComponentVisible(false);
    }
  };

  const renderTabs = () => {
    return TABS.map((e, i) => {
      return <div className={e.id === tab ? "modal-send-tabs-item modal-send-tabs-item-active" : "modal-send-tabs-item"}
                  onClick={() => setTab(e.id)}>{e.title}</div>
    })
  }

  const renderContent = () => {
    if (tab === 1) {
      return getTab1Content();
    } else if (tab === 2) {
      return getTab2Content();
    }
  }

  const getTab1Content = () => {
    return <div>
      <form className="relative">
        <Input
          name="username"
          label="Search user by username"
          onChange={handleSearchUser}
          value={selected}
          icon={INPUT_ICON.SEARCH}
          required
        />
        <Input
          name="amount"
          label="Amount"
          icon={INPUT_ICON.EURO}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
        <div className="modal-button-container">
          <Button
            type={TYPE.PRIMARY}
            text="Next"
            onClick={(e) => {
              setData({
                toUser: selected,
                amount: +amount
              })
              setStep(2);
            }}
            fullWidth={isMobile()}
          />
        </div>
        {isComponentVisible && <SearchUserDropdown users={users} setSelected={setSelected} setIsOpen={setIsComponentVisible}/>}
      </form>
    </div>
  }

  const getTab2Content = () => {
    return <>
      <div className="no-data center modal-send-no-accounts">
        <IconNotFound/>
        At the moment you do not have accounts where you can send funds to.
      </div>
      <div className="modal-button-container">
      <Button
        type={TYPE.PRIMARY}
        text="OK"
        onClick={(e) => {
          isOpen(false)
        }}
        fullWidth={isMobile()}
      />
      </div>
    </>
  }

  return (
    <>
      <div>
        You can transfer funds from E-Wallet to Payonow users or to your accounts.
      </div>
      <div className="modal-send-tabs">
        {renderTabs()}
      </div>
      {renderContent()}
    </>
  )
}