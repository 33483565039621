import React from "react";
import "../Modal.css";
import "./ModalSecurity.css";
import {SecurityCodeData} from "../../../pages/otp/components/SecurityCodeData";

export const ModalSecurityCode = ({isOpen}) => {
  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="modal modal-otp">
        <div className="modal-header">
          <div className="modal-title">Check your email for OTP</div>
          <div className="modal-close-button" onClick={() => isOpen(false)}/>
        </div>
        <SecurityCodeData isOpen={isOpen}/>
      </div>
    </>
  )
}