import React from "react";
import { useSelector } from "react-redux";
import "../Modal.css";
import { Invite } from "../../../pages/dashboard/account/Invite/Invite";

export const ModalInvite = ({isOpen}) => {
  const user = useSelector(store => store.base.user);

  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="modal modal-send">
        <div className="modal-header">
          <div className="modal-title">Invite</div>
          <div className="modal-close-button" onClick={() => isOpen(false)}/>
        </div>
        <Invite/>
      </div>
    </>
  )
}