import React, { useEffect, useState } from "react";

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import { client } from "../../../../../services/client";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    hoverRadius: 12,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            // text: 'Transaction graph',
        },
    },
};


export const TransactionGraph = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        let labels = [];
        let income = [];
        let outcome = [];

        client.getTransactionStat().then((r) => {
            r = r.reverse();
            r.forEach(e => {
                labels.push(e["month"]);
                income.push(e["income"]);
                outcome.push(e["expenses"]);
            })
            setData({
                labels,
                datasets: [
                    {
                        id: 1,
                        label: 'Income',
                        data: income,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                    {
                        id: 2,
                        label: 'Expenses',
                        data: outcome,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                ]
            });
        });
    }, []);

    return (
        <div className="block block-big">
            <div className="block-title">Transaction graph</div>

            {data && <Line options={options} data={data} />}

        </div>
    )
}