import React from "react";
import "./ProfileDropdown.css"
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/actions";
import { ReactComponent as IconAccount } from "../../../images/icons/top-panel/account.svg";
import { ReactComponent as IconMobile } from "../../../images/icons/menu/mobile.svg";
import { ReactComponent as IconPassword } from "../../../images/icons/menu/password.svg";
import { ReactComponent as IconSecurity } from "../../../images/icons/menu/security.svg";
import { ReactComponent as IconHelp } from "../../../images/icons/menu/help.svg";
import { ReactComponent as IconLogout } from "../../../images/icons/logout.svg";
import { ROUTE } from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { isMobile } from "../../../utils/checkDevice";
import { LS } from "../../../constants/ls";

export const ProfileDropdownMenu = ({ isOpen, setSecurityPinModal, setPhoneModal, setPasswordModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <div className="profile-dropdown">
        <div className="profile-dropdown-item" onClick={() => {
          isOpen(false);
          history.push(ROUTE.DASHBOARD_ACCOUNT);
          localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_ACCOUNT);
        }}>
          <IconAccount />
          <div>Account</div>
        </div>
        <div className="profile-dropdown-item" onClick={() => {
          isOpen(false);
          if (isMobile()) {
            history.push(ROUTE.DASHBOARD_ACCOUNT_PHONE);
          } else {
            setPhoneModal(true);
          }
        }}>
          <IconMobile />
          <div>Mobile phone</div>
        </div>
        <div className="profile-dropdown-item" onClick={() => {
          isOpen(false);
          if (isMobile()) {
            history.push(ROUTE.DASHBOARD_ACCOUNT_PASSWORD);
          } else {
            setPasswordModal(true);
          }
        }}>
          <IconPassword />
          <div>Password</div>
        </div>
        <div className="profile-dropdown-item" onClick={() => {
          isOpen(false);
          if (isMobile()) {
            history.push(ROUTE.DASHBOARD_ACCOUNT_PIN);
          } else {
            setSecurityPinModal(true);
          }
        }}>
          <IconSecurity />
          <div>Security pin</div>
        </div>
        <div className="profile-dropdown-item">
          <IconHelp />
          <div>Get help</div>
        </div>
        <div className="profile-dropdown-item" onClick={() => dispatch(logout())}>
          <IconLogout />
          <div>Logout</div>
        </div>
      </div>
    </>

  )
}