import React, { useEffect, useState } from "react";
import { Button, TYPE } from "../../../../components/Button/Button";
import { INPUT_ICON, INPUT_TYPES, Input } from "../../../../components/Input/Input";
import { MobileHeader } from "../../../../components/MobileHeader/MobileHeader";
import { notification } from "../../../../components/Notification";
import { ROUTE } from "../../../../constants/routes";
import { client } from "../../../../services/client";
import { isMobile } from "../../../../utils/checkDevice";
import { VALIDATION_ERROR } from "../../../../utils/errorCodes";
import { validatePassword } from "../../../../utils/validators";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const UpdatePassword = ({ isOpen }) => {
    const history = useHistory();

    const [password, setPassword] = useState("");
    const [passwordRep, setPasswordRep] = useState("");
    const [passwordCurrent, setPasswordCurrent] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRep, setShowPasswordRep] = useState(false);
    const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);

    const [passwordIcon, setPasswordIcon] = useState(false);
    const [passwordIconRep, setPasswordIconRep] = useState(false);
    const [passwordIconCurrent, setPasswordIconCurrent] = useState(false);

    const [errorPassword, setErrorPassword] = useState(null);
    const [errorPasswordRep, setErrorPasswordRep] = useState(null);
    const [errorPasswordCurrent, setErrorPasswordCurrent] = useState(null);

    const [loading, setLoading] = useState(false);
    const [buttonActive, setButtonActive] = useState(false);

    const validateForm = () => {
        if (!validatePassword(password)) {
            setErrorPassword(VALIDATION_ERROR.PASS);
            return false;
        } else {
            setErrorPassword(null);
        }
        if (password !== passwordRep) {
            setErrorPasswordRep(VALIDATION_ERROR.PASS_REP);
            return false;
        } else {
            setErrorPasswordRep(null);
        }
        return true;
    }

    const handleButtonActive = () => {
        if (password === "" || passwordRep === "" || passwordCurrent === "") {
          setButtonActive(false);
        } else {
          setButtonActive(true);
        }
      }


    useEffect(() => {
        handleButtonActive();
    }, [password, passwordRep, passwordCurrent])

    const submit = async (e) => {
        e.preventDefault();

        if (loading) {
            return;
        }

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        await client.changePassword({
            oldPassword: passwordCurrent,
            newPassword: password
        })
            .then(() => {
                notification.success("Password updated");
                if (!isMobile()) {
                    isOpen(false);
                } else {
                    history.push(ROUTE.DASHBOARD_WALLET);
                }
            })
            .catch((error) => notification.warning(error.message))
            .finally(() => setLoading(false));
    }

    return (
        <>
            <div className="mobile-content">
                {isMobile() && <MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Update password"/>}
                <div>
                    Type your new password two times and confirm the updates by typing your old password.
                </div>

                <form onSubmit={submit} className="relative">
                    <Input
                        icon={passwordIcon && (showPassword ? INPUT_ICON.EYE_CLOSED : INPUT_ICON.EYE_OPEN)}
                        onClick={() => setShowPassword(!showPassword)}
                        name="password"
                        label="Password"
                        type={showPassword ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
                        error={errorPassword}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            if (e.target.value.length > 0) {
                                setPasswordIcon(true);
                            }
                        }}
                        onSubmit={submit}
                        required
                        autoFocus
                    />
                    <Input
                        icon={passwordIconRep && (showPasswordRep ? INPUT_ICON.EYE_CLOSED : INPUT_ICON.EYE_OPEN)}
                        onClick={() => setShowPasswordRep(!showPasswordRep)}
                        name="passwordRep"
                        label="Repeat password"
                        type={showPasswordRep ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
                        error={errorPasswordRep}
                        onChange={(e) => {
                            setPasswordRep(e.target.value);
                            if (e.target.value.length > 0) {
                                setPasswordIconRep(true);
                            }
                        }}
                        onSubmit={submit}
                        required
                    />

                    <div className="caption-small mb-2-5 auth-hint ">Password must be 8 to 24 character with lower case, upper case, number
                        and special character.
                    </div>

                    <Input
                        icon={passwordIconCurrent && (showPasswordCurrent ? INPUT_ICON.EYE_CLOSED : INPUT_ICON.EYE_OPEN)}
                        onClick={() => setShowPasswordCurrent(!showPasswordCurrent)}
                        name="passwordCurrent"
                        label="Current password"
                        type={showPasswordCurrent ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
                        error={errorPasswordCurrent}
                        onChange={(e) => {
                            setPasswordCurrent(e.target.value);
                            if (e.target.value.length > 0) {
                                setPasswordIconCurrent(true);
                            }
                        }}
                        onSubmit={submit}
                        required
                    />

                    <div className="modal-button-container">
                        {loading ? <div className="loader" /> : <Button
                            type={buttonActive ? TYPE.PRIMARY : TYPE.INACTIVE}
                            text="Save"
                            fullWidth={isMobile()}
                        />}
                    </div>
                </form>
            </div>
        </>
    )
}
