import React, {useEffect, useState} from "react";
import {Button, TYPE} from "../../../../Button/Button";
import {ORDER_CARD_STEP} from "../../../../../utils/orderCardUtils";
import {isMobile} from "../../../../../utils/checkDevice";
import {Select} from "../../../../Select/Select";
import {client} from "../../../../../services/client";
import {getIconByPaymentMethodId, ORDER_TYPES} from "../../../../../utils/paymentUtils";
import {notification} from "../../../../Notification";
import {ReactComponent as IconVisa} from "../../../../../images/icons/card/visa-big.svg";
import {toEur} from "../../../../../utils/tools";
import {Input, INPUT_TYPES as INPUT_TYPE} from "../../../../Input/Input";
import classNames from "classnames";


const plasticPrice = 195;
const metalPrice = 295;

export const OrderDetails = ({setStep, data, setData}) => {
  const [loading, setLoading] = useState(false);
  const [errorEpin, setErrorEpin] = useState(null);
  const [epin, setEpin] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const submit = async (e) => {
    // e.preventDefault();

    if (loading) {
      return false;
    }

    setLoading(true);

    await client.createOrder({
      paymentMethod: selectedPaymentMethod.value,
      products: [
        {id: 1, qty: data.selectedPlasticQty},
        {id: 2, qty: data.selectedMetalQty},
      ],
      epinCode: epin
    })
      .then((r) => {
        setData(r);
        setStep(ORDER_CARD_STEP.PAYMENT);
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }

  useEffect(async () => {
    const result = [];

    await client.getAllActivePaymentMethods()
      .then((r) => {
        r.map((el, i) =>
          result.push({
            id: el.id,
            value: el.code,
            title: el.name,
            icon: getIconByPaymentMethodId(el.id)
          }));
      })
      .catch((error) => notification.warning(error.message));

    setSelectedPaymentMethod(result[0])
    setPaymentMethods(result);
  }, []);

  return (
    <div>
      {!isMobile() && <h3 className="mb-1-625">Order details</h3>}
      <div className="subtitle1 mb-2-5">You can choose up to five cards in total in your account. Any combination is
        possible and is delivered to your registered shipping address. Metal cards will be delivered separately and can
        take up to four weeks to arrive.
      </div>
      <form>
        <div className="flex column gap-1-25">
          {data.selectedPlasticQty > 0 && <div className="flex gap-1 items-center fw-500">
            <div className="card-mini-container">
              <div className="card-plastic card-mini mx-0-auto">
                <IconVisa/>
              </div>
            </div>
            <div>
              x{data.selectedPlasticQty}
            </div>
            <div>Plastic debit card</div>
            <div>{toEur(data.selectedPlasticQty * plasticPrice)}</div>
          </div>}

          {data.selectedMetalQty > 0 && <div className="flex gap-1 items-center fw-500">
            <div className="card-mini-container">
              <div className="card-metal card-mini mx-0-auto">
                <IconVisa/>
              </div>
            </div>
            <div>
              x{data.selectedMetalQty}
            </div>
            <div>Metal debit card</div>
            <div>{toEur(data.selectedMetalQty * metalPrice)}</div>
          </div>}

          <div className="flex items-center gap-1 fs-1-3 fw-500">
            <div>TOTAL</div>
            <div>{toEur(data.selectedPlasticQty * plasticPrice + data.selectedMetalQty * metalPrice)}</div>
          </div>

          <div className={classNames(
            "relative input grid column",
            {"grid-column-2-1fr  gap-2-5": !isMobile()}
          )}>
            {paymentMethods && selectedPaymentMethod && <Select
              items={paymentMethods}
              label="Payment method"
              selected={selectedPaymentMethod}
              callback={setSelectedPaymentMethod}/>}
            {selectedPaymentMethod?.value === "EPIN" && <Input
              name="epin"
              label="Enter e-pin"
              onChange={(e) => {
                // resetErrors();
                setEpin(e.target.value);
              }}
              value={epin}
              type={INPUT_TYPE.TEXT}
              // onSubmit={submit}
              error={errorEpin}
              required
            />}
          </div>

        </div>
      </form>
      <div className="kyc-full-modal-button-container">
        {!isMobile() && <Button
          type={TYPE.SECONDARY}
          text="Back"
          onClick={() => setStep(ORDER_CARD_STEP.SELECT)}
          fullWidth={isMobile()}
        />}
        <Button
          type={TYPE.PRIMARY}
          text="Confirm"
          onClick={submit}
          fullWidth={isMobile()}
        />
      </div>
    </div>
  )
}