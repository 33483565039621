import React from "react";
import {MobileHeader} from "../../../components/MobileHeader/MobileHeader";
import {ROUTE} from "../../../constants/routes";
import {useDispatch, useSelector} from "react-redux";
import {PersonalInfoForm} from "../../../components/Modal/Full/KYC/components/PersonalInfoForm/PersonalInfoForm";
import {
  ResidentialAddressForm
} from "../../../components/Modal/Full/KYC/components/ResedentialAddressForm/ResidentialAddressForm";
import {setUser} from "../../../store/actions/actions";
import {
  ShippingAddressForm
} from "../../../components/Modal/Full/KYC/components/ShippingAddressForm/ShippingAddressForm";
import {VerifyIdentity} from "../../../components/Modal/Full/KYC/components/VerifyIdentity/VerifyIdentity";
import {VerifyAddress} from "../../../components/Modal/Full/KYC/components/VerifyAddress/VerifyAddress";
import {KycCompleted} from "../../../components/Modal/Full/KYC/components/KycCompleted/KycCompleted";

export const Kyc = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.base.user);

  const getContent = () => {
    switch (user.kycStep) {
      case 0:
        return <PersonalInfoForm/>
      case 1:
        return <ResidentialAddressForm/>
      case 2:
        return <ShippingAddressForm/>;
      case 3:
        return <VerifyIdentity/>;
      case 4:
        return <VerifyAddress/>;
      case 5:
        return <KycCompleted/>;
    }
  }

  const decreaseKycStep = () => {
    if (user.kycStep === 0) {
      return 0;
    } else {
      return user.kycStep - 1;
    }
  }

  return (
    <div className="mobile-content">
      {user && user.kycStep === 0 && <MobileHeader url={ROUTE.DASHBOARD_WALLET}
                                                 text={"Step " + (user.kycStep + 1) + " of 5"}/>}

      {user && user.kycStep > 0 && user.kycStep < 5 && <MobileHeader url={ROUTE.DASHBOARD_WALLET}
                             func={() => dispatch(setUser({...user, kycStep: decreaseKycStep()}))}
                             text={"Step " + (user.kycStep + 1) + " of 5"}/>}
      {user && getContent()}
    </div>
  )
}