import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { KycFullModal } from "../../../components/Modal/Full/KYC/KycFullModal";
import { OrderCardsModal } from "../../../components/Modal/Full/OrderCards/OrderCardsModal";
import { ModalDeposit } from "../../../components/Modal/ModalDeposit/ModalDeposit";
import { ModalOrder } from "../../../components/Modal/ModalOrder/ModalOrder";
import { ModalSend } from "../../../components/Modal/ModalSend/ModalSend";
import { ModalTransaction } from "../../../components/Modal/ModalTransaction/ModalTransaction";
import { ModalTransferRequest } from "../../../components/Modal/ModalTransferRequest/ModalTransferRequest";
import { ModalTransferRequestData } from "../../../components/Modal/ModalTransferRequest/ModalTransferRequestData";
import { ModalUpdatePin } from "../../../components/Modal/ModalUpdatePin/ModalUpdatePin";
import { ReactComponent as IconAdd } from "../../../images/icons/add.svg";
import { ReactComponent as BnbIcon } from "../../../images/icons/wallet/bnb.svg";
import { ReactComponent as BtcIcon } from "../../../images/icons/wallet/btc.svg";
import { ReactComponent as DepositIcon } from "../../../images/icons/wallet/deposit.svg";
import { ReactComponent as EthIcon } from "../../../images/icons/wallet/eth.svg";
import { ReactComponent as EurIcon } from "../../../images/icons/wallet/eur-item.svg";
import { ReactComponent as LmgtIcon } from "../../../images/icons/wallet/lmgt.svg";
import { ReactComponent as RequestIcon } from "../../../images/icons/wallet/request.svg";
import { ReactComponent as SendIcon } from "../../../images/icons/wallet/send.svg";
import { ReactComponent as UsdtIcon } from "../../../images/icons/wallet/usdt.svg";
import { setDrawerNotifications } from "../../../store/actions/actions";
import { isMobile } from "../../../utils/checkDevice";
import { toEur } from "../../../utils/tools";
import "./Wallet.css";
import { WalletMobile } from "./WalletMobile";
import { Card } from "./components/Card/Card";
import { TransactionGraph } from "./components/TransactionGraph/TransactionGraph";
import { RecentTransactions } from "./components/Transactions/RecentTransactions";
import { WalletIntegration, WalletIntegrationSnippet } from "./components/Integration/WalletIntegrationSnippet";


export const Wallet = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const wallet = useSelector(store => store.base.wallet);
  const [index1, setIndex1] = useState(2);
  const [index2, setIndex2] = useState(1);
  const [animationClass, setAnimationClass] = useState();
  const [reqId, setReqId] = useState(null);
  const [transId, setTransId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const [modalAccountNotActivatedIsOpen, setModalAccountNotActivatedIsOpen] = useState(false);
  const [modalOrderCardIsOpen, setModalOrderCardIsOpen] = useState(false);
  const [modalTransferRequestIsOpen, setModalTransferRequestIsOpen] = useState(false);
  const [modalTransactionIsOpen, setModalTransactionIsOpen] = useState(false);
  const [modalOrderIsOpen, setModalOrderIsOpen] = useState(false);
  const [modalSendIsOpen, setModalSendIsOpen] = useState(false);
  const [modalTransferRequestDataIsOpen, setModalTransferRequestDataIsOpen] = useState(false);
  const [modalSecurityPinIsOpen, setModalSecurityPinIsOpen] = useState(false);
  const [modalDepositIsOpen, setModalDepositIsOpen] = useState(false);


  const [class1, setClass1] = useState("cards-carousel-item cards-carousel-animation-init-item-1");
  const [class2, setClass2] = useState("cards-carousel-item cards-carousel-animation-init-item-2");

  useEffect(() => {
    const _reqId = new URLSearchParams(history.location.search).get('request');
    const _transId = new URLSearchParams(history.location.search).get('transaction');
    const _orderId = new URLSearchParams(history.location.search).get('order');
    if (_reqId != null) {
      setReqId(_reqId);
      dispatch(setDrawerNotifications(false));
      setModalTransferRequestDataIsOpen(true);
    } else if (_transId != null) {
      setTransId(_transId);
      dispatch(setDrawerNotifications(false));
      setModalTransactionIsOpen(true);
    } else if (_orderId != null) {
      setOrderId(_orderId);
      dispatch(setDrawerNotifications(false));
      setModalOrderIsOpen(true);
    }
  }, [location]);

  const handleCardClick = (clickedId) => {
    if (clickedId === 1) {
      if (index1 === 2) {
        setModalOrderCardIsOpen(true);
      } else {
        setIndex1(2)
        setIndex2(1);
        setClass1("cards-carousel-item cards-carousel-animation-item-1");
        setClass2("cards-carousel-item")
      }
    } else if (clickedId === 2) {
      if (index2 === 2) {
        setModalOrderCardIsOpen(true);
      } else {
        setIndex1(1);
        setIndex2(2);
        setClass1("cards-carousel-item");
        setClass2("cards-carousel-item cards-carousel-animation-item-2");
      }
    }
  }

  return (
    isMobile() ? <WalletMobile /> :
      <div className="wallet-layout">
        <div className="wallets-container">
          <div className="block block-big wallet-info">
            <div className="wallet-info-inner">
              <EurIcon />
              E-Wallet
            </div>
            <div className="wallet-info-inner wallet-balance">
              {wallet && toEur(wallet.ewalletBalance)}
            </div>
          </div>

          <div className="block block-big wallet-info">
            <div className="wallet-info-inner">
              <LmgtIcon />
              LMGT
            </div>
            <div className="wallet-info-inner wallet-crypto-balance-container">
              <div>{wallet && wallet.lmgtBalance} LMGT</div>
              <div>{wallet && wallet.lmgtValue !== undefined && toEur(wallet.lmgtValue)}</div>
            </div>
          </div>

          <div className="block block-big wallet-info">
            <div className="wallet-info-inner">
              <BtcIcon />
              Bitcoin
            </div>
            <div className="wallet-info-inner wallet-crypto-balance-container">
              <div>0 BTC</div>
              <div>{toEur(0)}</div>
            </div>
          </div>

          <div className="block block-big wallet-info">
            <div className="wallet-info-inner">
              <UsdtIcon />
              Tether
            </div>
            <div className="wallet-info-inner wallet-crypto-balance-container">
              <div>0 USDT</div>
              <div>{toEur(0)}</div>
            </div>
          </div>

          <div className="block block-big wallet-info">
            <div className="wallet-info-inner">
              <EthIcon />
              Ethereum
            </div>
            <div className="wallet-info-inner wallet-crypto-balance-container">
              <div>0 ETH</div>
              <div>{toEur(0)}</div>
            </div>
          </div>

          <div className="block block-big wallet-info">
            <div className="wallet-info-inner">
              <BnbIcon />
              BNB
            </div>
            <div className="wallet-info-inner wallet-crypto-balance-container">
              <div>0 BNB</div>
              <div>{toEur(0)}</div>
            </div>
          </div>

        </div>
        <div className="wallet-layout-inner">
          <div className="wallet-left-column">
            <div className="wallet-cards-container">

              <div className={class1} style={{ zIndex: index1 }} onClick={() => handleCardClick(1)}>
                <Card />
              </div>

              <div className={class2} style={{ zIndex: index2 }} onClick={() => handleCardClick(2)}>
                <div className="card-container">
                  <div className="card-empty">
                    <div className="card-empty-content">
                      <div>
                        <div className="card-empty-content-title">Add card</div>
                        <div className="card-empty-content-description">Order new card</div>
                      </div>
                      <IconAdd />
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <TransactionGraph />

          </div>


          <div className="wallet-right-column">
            <div className="block block-big">
              <div className="wallet-info">
                <div className="wallet-mobile-icon-item wallet-mobile-icon-item-green"
                  onClick={() => setModalTransferRequestIsOpen(true)}>
                  <RequestIcon />
                  <div>Receive</div>
                </div>
                <div className="button-divider"></div>
                <div className="wallet-mobile-icon-item wallet-mobile-icon-item-blue"
                  onClick={() => setModalSendIsOpen(true)}>
                  <SendIcon />
                  <div>Send</div>
                </div>
                <div className="button-divider"></div>
                <div className="wallet-mobile-icon-item wallet-mobile-icon-item-orange" onClick={() => setModalDepositIsOpen(true)}>
                  <DepositIcon />
                  <div>Deposit</div>
                </div>
              </div>
            </div>

            <WalletIntegrationSnippet />
            <RecentTransactions />

          </div>
        </div>

        {modalAccountNotActivatedIsOpen && <KycFullModal isOpen={setModalAccountNotActivatedIsOpen} />}
        {modalOrderCardIsOpen && <OrderCardsModal isOpen={setModalOrderCardIsOpen} redirectToKyc={setModalAccountNotActivatedIsOpen} />}
        {modalTransferRequestIsOpen && <ModalTransferRequest isOpen={setModalTransferRequestIsOpen} />}
        {modalSendIsOpen &&
          <ModalSend isOpen={setModalSendIsOpen} setModalSecurityPinIsOpen={setModalSecurityPinIsOpen} />}
        {modalDepositIsOpen && <ModalDeposit isOpen={setModalDepositIsOpen} />}
        {modalTransferRequestDataIsOpen && reqId &&
          <ModalTransferRequestData reqId={reqId} isOpen={setModalTransferRequestDataIsOpen} />}
        {modalTransactionIsOpen && transId &&
          <ModalTransaction id={transId} isOpen={setModalTransactionIsOpen} />}
        {modalOrderIsOpen && orderId &&
          <ModalOrder id={orderId} isOpen={setModalOrderIsOpen} />}
        {modalSecurityPinIsOpen && <ModalUpdatePin isOpen={setModalSecurityPinIsOpen} />}
      </div>
  )
}