import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ROUTE} from "../../constants/routes";

export const WalletConfirmRedirect = () => {
  const history = useHistory()
  const id = useParams();
  const isAuth = !!localStorage.accessToken && !!localStorage.refreshToken;

  useEffect(() => {
    if (isAuth) {
      setTimeout(() => {
        history.push(ROUTE.DASHBOARD_WALLET_INTEGRATION_CONFIRM.replace(":code", id.code))
      }, 1000)
    } else {
      history.push(ROUTE.LOGIN + "?redirect=" + window.location.pathname);
    }
  }, [history])

  return (
    <></>
  )
}