import React from "react";
import "./ModalOrder.css";
import "../Modal.css";
import {OrderInfo} from "../../../pages/dashboard/wallet/components/Orders/components/OrderInfo";

export const ModalOrder = ({isOpen, order = null, id}) => {
  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="modal modal-order">
        <div className="modal-header">
          <div className="modal-title">Order info</div>
          <div className="modal-close-button" onClick={() => isOpen(false)}/>
        </div>
        <OrderInfo isOpen={isOpen} order={order} id={id}/>
      </div>
    </>
  )
}