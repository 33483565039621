import React, {useEffect, useState} from "react";
import "../Auth.css";
import {isMobile} from "../../../utils/checkDevice";
import {MobileHeader} from "../../../components/MobileHeader/MobileHeader";
import {ROUTE} from "../../../constants/routes";
import {client} from "../../../services/client";
import {useHistory} from "react-router-dom";
import {jwt} from "../../../services/jwt";
import {setAuth, setUser} from "../../../store/actions/actions";
import {useDispatch} from "react-redux";
import {notification} from "../../../components/Notification";
import {USER_STATUS} from "../../../constants/userStatuses";
import {Input, INPUT_ICON, INPUT_TYPES} from "../../../components/Input/Input";
import phone from "../../../constants/phone";
import {Button, TYPE} from "../../../components/Button/Button";
import {validateEmail, validatePassword} from "../../../utils/validators";
import {VALIDATION_ERROR} from "../../../utils/errorCodes";
import {ModalSecurityCode} from "../../../components/Modal/ModalSecutityCode/ModalSecurityCode";
import {Select} from "../../../components/Select/Select";

export const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(null);
  const [errorTerms, setErrorTerms] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorPasswordRep, setErrorPasswordRep] = useState(null);

  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRep, setShowPasswordRep] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(false);
  const [passwordIconRep, setPasswordIconRep] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRep, setPasswordRep] = useState("");
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [buttonActive, setButtonActive] = useState(false);
  const [isModalSecurityCodeOpen, setIsModalSecurityCodeOpen] = useState(false);

  const [ref, setRef] = useState(null);

  useEffect(() => {
    const codes = phone.sort((a, b) => {
      if (a.en > b.en) {
        return 1;
      }
      if (a.en < b.en) {
        return -1;
      }
      return 0;
    });
    codes.map((e, i) => {
      phoneCodes.push({
        id: i,
        title: e.en + " (" + e.code + ")",
        value: e.code,
        iso: e.reduction,
        icon: <span className={"fi fi-" + e.reduction.toLowerCase()}></span>
      })
    });
  }, []);

  useEffect(() => {
    client.getIpData().then((r) => {
      if (Object.keys(r).length > 0) {
        const f = phoneCodes.filter((e) => e.iso === r.iso)[0]
        setSelectedPhoneCode(f);
      } else {
        setSelectedPhoneCode(phoneCodes.filter((e) => e.iso === "ES")[0]);
      }
    })
  }, []);

  const handleButtonActive = () => {
    if (email === "" || phoneNumber === "" || password === "" || passwordRep === "" || !terms) {
      setButtonActive(false);
    } else {
      setButtonActive(true);
    }
  }

  useEffect(() => {
    const r = new URLSearchParams(history.location.search).get('ref');
    if (ref !== null) {
      client.checkRef(r)
        .then(setRef)
        .catch((error) => notification.warning("Referral not found. You will be signed up without referral"));
    }
  }, [])

  useEffect(() => {
    handleButtonActive();
  }, [email, phoneNumber, password, passwordRep, terms])

  const validateForm = () => {
    if (!validateEmail(email)) {
      setErrorEmail(VALIDATION_ERROR.EMAIL);
      return false;
    } else {
      setErrorEmail(null);
    }
    if (!validatePassword(password)) {
      setErrorPassword(VALIDATION_ERROR.PASS);
      return false;
    } else {
      setErrorPassword(null);
    }
    if (password !== passwordRep) {
      setErrorPasswordRep(VALIDATION_ERROR.PASS_REP);
      return false;
    } else {
      setErrorPasswordRep(null);
    }
    if (!terms) {
      setErrorTerms(VALIDATION_ERROR.TERMS);
      return false;
    } else {
      setErrorTerms(null);
    }
    return true;
  }

  const handleSignup = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    await client.signup({
      email,
      phoneCode: selectedPhoneCode.value,
      phoneNumber,
      password,
      ref
    })
      .then((response) => {
        if (response.access_token !== '') {
          jwt.setJwtData(response.access_token, response.refresh_token);
          client.getCurrentUser()
            .then((user) => {
              dispatch(setUser(user));
              dispatch(setAuth(true));
              if (user.status === USER_STATUS.NEW) {
                if (isMobile()) {
                  history.push(ROUTE.OTP);
                } else {
                  setIsModalSecurityCodeOpen(true);
                }
              }
              if (user.status === USER_STATUS.MEMBER) {
                history.push(ROUTE.DASHBOARD);
              }
              setLoading(false);
            })
            .catch((error) => notification.warning(error.message));
        }
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }

  return (
    <div className="auth-container">
      {isMobile() ?
        <>
          <MobileHeader url={ROUTE.ROOT} text="Sign up"/>
        </> : <>
          <h3>Sign up</h3>
          <div className="auth-description">
            Ready to break free from the confines of ordinary life? Don't wait a moment longer! Apply for your Unlimited
            Visa Card today and seize the world by storm.
          </div>
        </>}

      <form onSubmit={handleSignup} className="relative">
        <div className="phone-number-container">
          {selectedPhoneCode && <Select
            items={phoneCodes}
            search
            isPhoneCode
            label="Phone code"
            selected={selectedPhoneCode}
            callback={setSelectedPhoneCode}/>}
          <Input
            name="phoneNumber"
            label="Phone number"
            error={errorPhoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            onSubmit={handleSignup}
            required
          />
        </div>
        <Input
          name="email"
          label="Email"
          error={errorEmail}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onSubmit={handleSignup}
          required
        />
        <Input
          icon={passwordIcon && (showPassword ? INPUT_ICON.EYE_CLOSED : INPUT_ICON.EYE_OPEN)}
          onIconClick={() => setShowPassword(!showPassword)}
          name="password"
          label="Password"
          type={showPassword ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
          error={errorPassword}
          onChange={(e) => {
            setPassword(e.target.value);
            if (e.target.value.length > 0) {
              setPasswordIcon(true);
            }
          }}
          onSubmit={handleSignup}
          required
        />
        <div className="caption-small auth-hint">Password must be 8 to 24 character with lower case, upper case, number
          and special character.
        </div>
        <Input
          icon={passwordIconRep && (showPasswordRep ? INPUT_ICON.EYE_CLOSED : INPUT_ICON.EYE_OPEN)}
          onIconClick={() => setShowPasswordRep(!showPasswordRep)}
          name="passwordRep"
          label="Repeat password"
          type={showPasswordRep ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
          error={errorPasswordRep}
          onChange={(e) => {
            setPasswordRep(e.target.value);
            if (e.target.value.length > 0) {
              setPasswordIconRep(true);
            }
          }}
          onSubmit={handleSignup}
          required
        />

        <div className="auth-terms subtitle">
          <input type="checkbox" id="terms" onChange={() => {
            if (!terms) {
              setErrorTerms(null);
            }
            setTerms(!terms);
          }} checked={terms}/>
          <label>Accept <span className="link"
                              onClick={() => console.log("pdf terms")}>Terms of Service</span> and <span
            className="link" onClick={() => console.log("pdf privacy")}>Privacy Policy</span></label>
        </div>
        {errorTerms && <div className="input-error-message">{errorTerms}</div>}

        <div className="auth-button-container">
          <Button
            type={buttonActive ? TYPE.PRIMARY : TYPE.INACTIVE}
            text="Get started"
          />
        </div>
        {!isMobile() && <div className="signup-link subtitle">
          Already have an account? <span onClick={() => history.push(ROUTE.LOGIN)} className="link">Log in</span>
        </div>}
      </form>
      {isModalSecurityCodeOpen &&
        <ModalSecurityCode isOpen={setIsModalSecurityCodeOpen}/>}
    </div>
  )
}