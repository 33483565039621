import React, {useEffect, useState} from "react";
import {Button, TYPE} from "../../../../../../components/Button/Button";
import {isMobile} from "../../../../../../utils/checkDevice";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {client} from "../../../../../../services/client";
import {notification} from "../../../../../../components/Notification";
import {ROUTE} from "../../../../../../constants/routes";
import {LS} from "../../../../../../constants/ls";

export const OrderInfo = ({isOpen, order = null, id}) => {
  const [data, setData] = useState(null);
  const user = useSelector(store => store.base.user);
  const history = useHistory();

  useEffect(() => {
    if (order === null) {
      client.getOrderById(id)
        .then(setData)
        .catch((error) => {
          isOpen(false);
          notification.warning(error.message)
        });
    } else {
      setData(order)
    }
  }, []);

  const close = () => {
    if (isMobile()) {
      history.push(ROUTE.DASHBOARD_WALLET);
      localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET);
    } else {
      isOpen(false);
    }
  }

  return (
    <>
      <div className="modal-transfer-info-block">
        <div className="modal-transfer-info-block-item">
          <div>ID</div>
          <div>{data?.id}</div>
        </div>
        <div className="modal-transfer-info-block-item">
          <div>Date time</div>
          <div>{data?.createdAt.replace("T", " ")} UTC</div>
        </div>
      </div>

      <div className="modal-button-container">
        <Button
          type={TYPE.PRIMARY}
          text="Close"
          onClick={() => close()}
          fullWidth={isMobile()}
        />
      </div>

    </>
  )
}