import React from "react";
import {ReactComponent as IconBtc} from "../images/icons/wallet/btc.svg";
import {ReactComponent as IconUsdt} from "../images/icons/wallet/usdt.svg"
import {ReactComponent as IconEur} from "../images/icons/wallet/eur-item.svg"
import {ReactComponent as IconBnb} from "../images/icons/wallet/bnb.svg"
import {ReactComponent as IconEpin} from "../images/icons/wallet/epin.svg"

export const ORDER_TYPES = {
  BUY_CARD: "BUY_CARD",
  LOAD_E_WALLET: "LOAD_E_WALLET"
}

export const PAYMENT_TYPES = {
  BTC: "BTC",
  EPIN: "EPIN",
  BANK: "BANK"
}

export const getIconByPaymentMethodId = (id) => {
  switch (id) {
    case 1:
      return <IconEpin/>;
    case 2:
      return <IconBtc/>;
    case 3:
      return <IconUsdt/>;
    case 4:
      return <IconBnb/>;
    case 5:
      return <IconEur/>;
  }
}