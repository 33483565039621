export const ROUTE = {
  ROOT: "/",
  LANDING: "/landing",

  LOGIN: "/login",
  SIGNUP: "/signup",
  REF_LINK: "/r/:ref",
  PASSWORD_RESET_REQUEST: "/password-reset-request",
  PASSWORD_RESET_CONFIRM: "/password-reset",
  SIGNUP_PAYMENT: "/signup/payment",
  SIGNUP_WAITING: "/signup/waiting",
  TRANSACTION: "/transaction/:id",
  TRANSFER: "/request/:id",
  ORDER: "/order/:id",
  OTP: "/otp",


  DASHBOARD: "/dashboard/main",
  DASHBOARD_WALLET: "/dashboard/wallet",
  DASHBOARD_WALLET_SEND: "/dashboard/wallet/send",
  DASHBOARD_WALLET_DEPOSIT: "/dashboard/wallet/deposit",
  DASHBOARD_WALLET_RECEIVE: "/dashboard/wallet/receive",
  DASHBOARD_WALLET_REQUEST_TRANSFER_DATA: "/dashboard/wallet/request/transfer/data/:id",
  DASHBOARD_REQUEST_TRANSFER: "/dashboard/wallet?request=:id",
  DASHBOARD_TRANSACTION: "/dashboard/wallet?transaction=:id",
  DASHBOARD_WALLET_TRANSACTION: "/dashboard/wallet/transaction/:id",
  DASHBOARD_WALLET_INTEGRATION: "/dashboard/wallet/integration",
  DASHBOARD_WALLET_INTEGRATION_CONFIRM: "/dashboard/wallet/confirm/:code",
  DASHBOARD_WALLET_INTEGRATED: "/dashboard/wallet/integrated",
  WALLET_INTEGRATION_CONFIRM_REDIRECT: "/wallet/integration/:code",
  DASHBOARD_ORDER: "/dashboard/wallet?order=:id",
  DASHBOARD_WALLET_ORDER: "/dashboard/wallet/order/:id",

  DASHBOARD_KYC: "/dashboard/kyc",
  DASHBOARD_CARDS_ORDER: "/dashboard/cards/order",
  DASHBOARD_CRYPTO: "/dashboard/crypto",

  DASHBOARD_ACCOUNT: "/dashboard/account",
  DASHBOARD_ACCOUNT_PIN: "/dashboard/account/pin",
  DASHBOARD_ACCOUNT_PHONE: "/dashboard/account/phone",
  DASHBOARD_ACCOUNT_PASSWORD: "/dashboard/account/password",
  DASHBOARD_ACCOUNT_INVITE: "/dashboard/account/invite"
}