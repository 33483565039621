import React, { useEffect, useState } from "react";
import { MobileHeader } from "../../../../../components/MobileHeader/MobileHeader";
import { ROUTE } from "../../../../../constants/routes";
import { isMobile } from "../../../../../utils/checkDevice";
import { toEur } from "../../../../../utils/tools";
import { client } from "../../../../../services/client";
import { notification } from "../../../../../components/Notification";

export const IntegratedWallets = ({ isOpen, wallets }) => {
    const [data, setData] = useState();

    useEffect(() => {
        if (wallets === null || wallets === undefined) {
            client.getLmgtWallets().then(setData).catch((error) => notification.warning(error.message))
        } else {
            setData(wallets);
        }
    }, [])

    return (
        <>
            {isMobile() && <MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Integrated wallets" />}
            <div className="mobile-content">
                <form>
                    <div className="modal-transfer-info-block">
                        {data && data.map((w) => {
                            return <div className="modal-transfer-info-block-item">
                                <div>{w.address}</div>
                                <div>{w.balance} LMGT</div>
                                <div>{toEur(w.value)}</div>
                            </div>
                        })}
                    </div>
                </form>
            </div>
        </>
    )
}