import React, { useEffect, useState } from "react";
import { isMobile } from "../../../utils/checkDevice";
import { MobileHeader } from "../../../components/MobileHeader/MobileHeader";
import { ROUTE } from "../../../constants/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { client } from "../../../services/client";
import { INPUT_ICON, INPUT_TYPES, Input } from "../../../components/Input/Input";
import { Button, TYPE } from "../../../components/Button/Button";
import { validatePassword } from "../../../utils/validators";
import { VALIDATION_ERROR } from "../../../utils/errorCodes";
import { notification } from "../../../components/Notification";

export const ResetPasswordConfirm = () => {
    const history = useHistory();

    const [error, setError] = useState(null);
    const [errorPassword, setErrorPassword] = useState(null);
    const [errorPasswordRep, setErrorPasswordRep] = useState(null);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRep, setShowPasswordRep] = useState(false);
    const [passwordIcon, setPasswordIcon] = useState(false);
    const [passwordIconRep, setPasswordIconRep] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordRep, setPasswordRep] = useState("");

    const [code, setCode] = useState(null);
    const [buttonActive, setButtonActive] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const c = new URLSearchParams(history.location.search).get('confirmationCode');
        if (c === null) {
            setError("Code required");
        }
        setCode(c);
    }, []);


    useEffect(() => {
        handleButtonActive();
    }, [password, passwordRep])

    const submit = async (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true);

        if (!validateForm()) {
            return;
        }

        await client.confirmResetPassword({
            confirmationCode: code,
            password: password
        })
            .then(() => {
                notification.success("Password changed. You can login now");
                history.push(ROUTE.LOGIN);
            })
            .catch((error) => setError(error.message))
            .finally(() => setLoading(false));
    }

    const handleButtonActive = () => {
        if (password === "" || passwordRep === "") {
            setButtonActive(false);
        } else {
            setButtonActive(true);
        }
    }

    const validateForm = () => {
        if (!validatePassword(password)) {
            setErrorPassword(VALIDATION_ERROR.PASS);
            return false;
        } else {
            setErrorPassword(null);
        }
        if (password !== passwordRep) {
            setErrorPasswordRep(VALIDATION_ERROR.PASS_REP);
            return false;
        } else {
            setErrorPasswordRep(null);
        }
        return true;
    }

    return (
        <div className="auth-container">
            {isMobile() ? <MobileHeader url={ROUTE.ROOT} text="New password" /> : <h3 className="mb-1-625">New password</h3>}

            {error ? <div className="center">
                {error}
                <div className="reset-password caption-small mt-1">
                    Do you want to try again? <span className="link" onClick={() => history.push(ROUTE.PASSWORD_RESET_REQUEST)}>Reset it here.</span>
                </div>
            </div> :
                <form onSubmit={(e) => submit(e)}>
                    <Input
                        icon={passwordIcon && (showPassword ? INPUT_ICON.EYE_CLOSED : INPUT_ICON.EYE_OPEN)}
                        onClick={() => setShowPassword(!showPassword)}
                        name="password"
                        label="Password"
                        type={showPassword ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
                        error={errorPassword}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            if (e.target.value.length > 0) {
                                setPasswordIcon(true);
                            }
                        }}
                        onSubmit={submit}
                        required
                    />
                    <div className="caption-small auth-hint">Password must be 8 to 24 character with lower case, upper case, number
                        and special character.
                    </div>
                    <Input
                        icon={passwordIconRep && (showPasswordRep ? INPUT_ICON.EYE_CLOSED : INPUT_ICON.EYE_OPEN)}
                        onClick={() => setShowPasswordRep(!showPasswordRep)}
                        name="passwordRep"
                        label="Repeat password"
                        type={showPasswordRep ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD}
                        error={errorPasswordRep}
                        onChange={(e) => {
                            setPasswordRep(e.target.value);
                            if (e.target.value.length > 0) {
                                setPasswordIconRep(true);
                            }
                        }}
                        onSubmit={submit}
                        required
                    />
                    <div className="auth-button-container">
                        <Button
                            type={buttonActive ? TYPE.PRIMARY : TYPE.INACTIVE}
                            text="Save"
                        />
                    </div>
                </form>
            }

        </div>
    )
}