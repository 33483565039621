import React, {useState} from "react";
import {Input, INPUT_ICON} from "../../../../../../components/Input/Input";
import {Button, TYPE} from "../../../../../../components/Button/Button";
import {SearchUserDropdown} from "../../../../../../components/Dropdown/SearchUserDropdown/SearchUserDropdown";
import {client} from "../../../../../../services/client";
import {isMobile} from "../../../../../../utils/checkDevice";
import {notification} from "../../../../../../components/Notification";
import {useComponentVisible} from "../../../../../../components/UseComponentVisible/UseComponentVisible";

export const TransferRequestStep1 = ({setStep, setResponse}) => {
  const [users, setUsers] = useState(null);
  const [selected, setSelected] = useState(null);

  const handleRequest = async (e) => {
    e.preventDefault();
    await client.createTransferRequest({
      username: e.target.username.value,
      amount: +e.target.amount.value
    })
      .then((r) => {
        setResponse(r)
        setStep(2);
      })
      .catch((error) => notification.warning(error.message));
  }

  const handleSearchUser = (e) => {
    let v = e.target.value;
    setSelected(v);
    if (v !== undefined && v !== "" && v !== null) {
      search(v);
      setIsComponentVisible(true);
    } else {
      setIsComponentVisible(false);
    }
  };

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const search = (v) => {
    client.searchUser(v)
      .then(setUsers)
  }


  return (
    <>
      <div>
        You can request funds to your E-Wallet from Payonow users.
      </div>
      <form onSubmit={handleRequest} className="relative">
        <Input
          name="username"
          label="Search user by username"
          onChange={handleSearchUser}
          value={selected}
          icon={INPUT_ICON.SEARCH}
          required
        />
        <Input
          name="amount"
          label="Amount"
          icon={INPUT_ICON.EURO}
          required
        />

        <div className="modal-button-container">
          <Button
            type={TYPE.PRIMARY}
            text="Request"
            fullWidth={isMobile()}
          />
        </div>
        {isComponentVisible &&
          <SearchUserDropdown users={users} setSelected={setSelected} setIsOpen={setIsComponentVisible}/>}
      </form>
    </>
  )
}