import React, {useEffect, useState} from "react";
import {getIconByPaymentMethodId, ORDER_TYPES} from "../../../../../../utils/paymentUtils";
import {client} from "../../../../../../services/client";
import {notification} from "../../../../../../components/Notification";
import {Input, INPUT_ICON, INPUT_TYPES as INPUT_TYPE} from "../../../../../../components/Input/Input";
import {isMobile} from "../../../../../../utils/checkDevice";
import {Button, TYPE} from "../../../../../../components/Button/Button";
import {Select} from "../../../../../../components/Select/Select";

export const DepositStep1 = ({setStep, setData}) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [epin, setEpin] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [errorAmount, setErrorAmount] = useState(null);
  const [errorEpin, setErrorEpin] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const resetErrors = () => {
    setErrorAmount(null);
  }

  useEffect(async () => {
    const result = [];

    await client.getAllActivePaymentMethods()
      .then((r) => {
        r.map((el, i) =>
          result.push({
            id: el.id,
            value: el.code,
            title: el.name,
            icon: getIconByPaymentMethodId(el.id)
          }));
      })
      .catch((error) => notification.warning(error.message));

    setSelectedPaymentMethod(result[0])
    setPaymentMethods(result);
  }, [])


  const submit = async (e) => {
    e.preventDefault();

    if (loading) {
      return false;
    }

    setLoading(true);

    await client.createOrder({
      paymentMethod: selectedPaymentMethod.value,
      products: [
        {id: 3, qty: 1, loadAmount: amount}
      ],
      epinCode: epin
    })
      .then((r) => {
        setData(r);
        setStep(2);
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }


  return (
    <>
      <div>
        Choose amount and method to deposit funds to your wallet.
      </div>
      <form onSubmit={submit}>
        <div className="input">
          {paymentMethods && selectedPaymentMethod && <Select
            items={paymentMethods}
            label="Payment method"
            selected={selectedPaymentMethod}
            callback={setSelectedPaymentMethod}/>}
        </div>
        <Input
          name="amount"
          label="Amount"
          icon={INPUT_ICON.EURO}
          onChange={(e) => {
            resetErrors();
            setAmount(e.target.value);
          }}
          value={amount}
          type={INPUT_TYPE.TEXT}
          onSubmit={submit}
          error={errorAmount}
          required
        />
        {selectedPaymentMethod?.value === "EPIN" && <Input
          name="epin"
          label="E-pin"
          onChange={(e) => {
            resetErrors();
            setEpin(e.target.value);
          }}
          value={epin}
          type={INPUT_TYPE.TEXT}
          onSubmit={submit}
          error={errorEpin}
          required
        />}
        <div className="modal-button-container">
          {loading ? <div className="loader"/> : <Button
            type={TYPE.PRIMARY}
            text="Next"
            fullWidth={isMobile()}
          />}
        </div>
      </form>
    </>
  )
}