import React, {useEffect, useState} from "react";
import {ReactComponent as LiIcon} from "../../../../../../images/icons/li.svg";
import {client} from "../../../../../../services/client";
import {notification} from "../../../../../Notification";
import country from "../../../../../../constants/country";
import {setUser} from "../../../../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";

export const VerifyHint = ({step}) => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState();
  const [residentialAddress, setResidentialAddress] = useState();

  const getUserInfo = () => {
    client.getUserInfo()
      .then(setUserInfo)
      .catch((error) => notification.warning(error.message));
  }

  const getResidentialAddress = () => {
    client.getUserResidentialAddress()
      .then((r) => {
        if (r.countryId !== undefined && r.countryId !== null && r.countryId !== "") {
          setResidentialAddress(country.filter((e) => e.id === r.countryId)[0]);
        }
      })
      .catch((error) => notification.warning(error.message));
  }

  useEffect(() => {
    getUserInfo();
    getResidentialAddress();
  }, [])

  return (
    <>
      <div className="kyc-left-data">
        <h6>The document must confirm</h6>
        <div className="kyc-left-data-table">
          <div className="kyc-left-data-table-item">
            <div>Legal name</div>
            <div>{userInfo?.firstName + " " + userInfo?.lastName}</div>
          </div>
          {step === 4 && <><div className="kyc-left-data-table-item">
            <div>Address</div>
            <div>{residentialAddress?.address}</div>
          </div>
            <div className="kyc-left-data-table-item">
              <div>Post code</div>
              <div>{residentialAddress?.postCode}</div>
            </div>
            <div className="kyc-left-data-table-item">
              <div>City</div>
              <div>{residentialAddress?.city}</div>
            </div>
          </>}
          <div className="kyc-left-data-table-item">
            <div>{step === 4 ? "Country" : "Citizenship"}</div>
            <div>{residentialAddress?.name}</div>
          </div>
          {step === 3 && <div className="kyc-left-data-table-item">
            <div>Date of birth</div>
            <div>{userInfo?.dateOfBirth}</div>
          </div>}
          <div className="kyc-left-data-table-item">
            <div>Made mistake?</div>
            <div className="link" onClick={() => {
              if (step === 3 || step === 4) {
                dispatch(setUser({...user, kycStep: 0}));
              }
            }}>Edit details
            </div>
          </div>
        </div>

        <div className="kyc-left-data">
          <h6>Image requirements</h6>
          <div className="kyc-left-data-table">
            <div className="kyc-left-data-table-item">
              <LiIcon/>
              <div>File size up to 3MB</div>
            </div>
            <div className="kyc-left-data-table-item">
              <LiIcon/>
              <div>Clear and readable file</div>
            </div>
            <div className="kyc-left-data-table-item">
              <LiIcon/>
              <div>JPG, JPEG, PNG format</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}