import React, {useEffect, useState} from "react";
import {toEur} from "../../../../../../../utils/tools";
import {useSelector} from "react-redux";
import {client} from "../../../../../../../services/client";
import {notification} from "../../../../../../../components/Notification";
import {Button, TYPE} from "../../../../../../../components/Button/Button";
import {isMobile} from "../../../../../../../utils/checkDevice";

export const TransferRequestDataStep1 = ({isOpen, setStep, reqId, setResponse}) => {
  const user = useSelector(store => store.base.user);
  const [data, setData] = useState(null);

  const confirm = async (e) => {
    e.preventDefault();
    await client.resolveTransferRequest(data.id)
      .then((r) => {
        setStep(2);
      })
      .catch((error) => notification.warning(error.message));
  }

  const decline = async (e) => {
    e.preventDefault();
    await client.declineTransferRequest(data.id)
      .then((r) => {
        setStep(3);
      })
      .catch((error) => notification.warning(error.message));
  }

  const cancel = async (e) => {
    e.preventDefault();
    await client.cancelTransferRequest(data.id)
      .then((r) => {
        isOpen(false)
      })
      .catch((error) => notification.warning(error.message));
  }

  const getData = async () => {
    await client.getTransferRequestById(reqId)
      .then((r) => {
        setData(r);
        setResponse(r);
      })
      .catch((error) => notification.warning(error.message));
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="modal-transfer-info-block">
        <div className="modal-transfer-info-block-item">
          <div>Transfer ID</div>
          <div>{data?.id}</div>
        </div>
        <div className="modal-transfer-info-block-item">
          <div>From user</div>
          <div>{data?.fromUsername}</div>
        </div>
        <div className="modal-transfer-info-block-item">
          <div>To user</div>
          <div>{data?.toUsername}</div>
        </div>
        <div className="modal-transfer-info-block-item">
          <div>Amount</div>
          <div>{data && toEur(data.amount)}</div>
        </div>
        <div className="modal-transfer-info-block-item">
          <div>Status</div>
          <div>{data?.status.toLowerCase()}</div>
        </div>
        <div className="modal-transfer-info-block-item">
          <div>Requested at</div>
          <div>{data?.createdAt}</div>
        </div>
        <div className="modal-transfer-info-block-item">
          <div>Updated at</div>
          <div>{data?.updatedAt}</div>
        </div>
      </div>

      {data && user && data.status === "NEW" && data.toUserId === user.id &&
        <div>Would you like to transfer requested amount?</div>}
      {data && user && <div className="modal-button-container">
        {data.status === "NEW" && data.toUserId === user.id && <>
          <Button
            type={TYPE.SECONDARY}
            text="Decline"
            onClick={(e) => decline(e)}
            fullWidth={isMobile()}
          />
          <Button
            type={TYPE.PRIMARY}
            text="Confirm"
            onClick={(e) => confirm(e)}
            fullWidth={isMobile()}
          />
        </>}
        {data.status === "NEW" && data.fromUserId === user.id && <>
          <Button
            type={TYPE.SECONDARY}
            text="Cancel request"
            onClick={(e) => cancel(e)}
            fullWidth={isMobile()}
          />
          <Button
            type={TYPE.PRIMARY}
            text="OK"
            onClick={(e) => isOpen(false)}
            fullWidth={isMobile()}
          />
        </>}
        {data.status !== "NEW" && <>
          <Button
            type={TYPE.PRIMARY}
            text="OK"
            onClick={(e) => isOpen(false)}
            fullWidth={isMobile()}
          />
        </>}
      </div>}
    </>
  )
}