import React, {useEffect, useState} from "react";
import "./Card.css"
import {toEur} from "../../../../../utils/tools";
import {useSelector} from "react-redux";
import {ReactComponent as IconVisa} from "../../../../../images/icons/card/visa.svg";
import {ReactComponent as IconChip} from "../../../../../images/icons/card/chip.svg";
import {KycFullModal} from "../../../../../components/Modal/Full/KYC/KycFullModal";
import {ROUTE} from "../../../../../constants/routes";
import {LS} from "../../../../../constants/ls";
import {useHistory} from "react-router-dom";
import {useComponentVisible} from "../../../../../components/UseComponentVisible/UseComponentVisible";
import {getName} from "../../../../../utils/userUtils";
import {ReactComponent as IconAdd} from "../../../../../images/icons/add.svg";
import {OrderCardsModal} from "../../../../../components/Modal/Full/OrderCards/OrderCardsModal";

export const Card = ({isMobile = false}) => {
  const history = useHistory();
  const user = useSelector(store => store.base.user);
  const [modalKycOpen, setModalKycOpen] = useState(false);
  const [modalOrderCardOpen, setModalOrderCardOpen] = useState(false);
  const [mobileClass, setMobileClass] = useState("card-container card-container-mobile");
  const showBalance = useSelector(store => store.base.showBalance);
  const balance = 0.00;

  useEffect(() => {
    setTimeout(() => {
      setMobileClass(mobileClass + " card-container-mobile-swipe")
    }, 500)
  }, [])

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const changeCardMenuState = (event) => {
    setIsComponentVisible(!isComponentVisible);
  };

  const openKyc = () => {
    // if (isMobile) {
    //   history.push(ROUTE.DASHBOARD_KYC);
    //   localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_KYC);
    // } else {
    //   setModalKycOpen(true);
    // }

    if (isMobile) {
      history.push(ROUTE.DASHBOARD_CARDS_ORDER);
      localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_CARDS_ORDER);
    } else {
      setModalOrderCardOpen(true);
    }
  }

  const renderBalance = () => {
    if (showBalance) {
      return toEur(balance);
    } else {
      return "€***.**"
    }
  }

  const getCard = () => {
    return <div className={isMobile ? "cards-carousel-animation-mobile-item-1 card card-plastic" : "card card-plastic"} onClick={() => {
      isMobile && openKyc();
    }}>
      <div className="card-header">
        <div className="card-balance-container">
          <div>{renderBalance()}</div>
        </div>
        <IconVisa/>
      </div>

      <div className="card-center">
        <IconChip/>
        <div className="card-number">
          **** **** **** 1234
        </div>
      </div>

      <div className="card-footer">
        <div>
          <div className="card-cardholder-label">
            Card holder
          </div>
          <div className="card-cardholder-name">{getName(user)}</div>
        </div>
        <div>
          <div className="card-expiry-label">Expiry Date</div>
          <div className="card-expiry-date">12/30</div>
        </div>
      </div>
    </div>
  }


  return (
    <>
      <div className={isMobile ? mobileClass : "card-container"}>
        {isMobile ? <div className="card-mobile-wrap">
          {getCard()}
        </div> : getCard()}


        {isMobile && <div className="card-mobile-wrap" onClick={() => isMobile && openKyc()}>
          <div className={isMobile ? "cards-carousel-animation-mobile-item-2 card-empty" : "card-empty"}>
            <div className="card-empty-content">
              <div>
                <div className="card-empty-content-title">Add card</div>
                <div className="card-empty-content-description">Order new card</div>
              </div>
              <IconAdd/>
            </div>
          </div>
        </div>}

        {modalKycOpen && <KycFullModal isOpen={setModalKycOpen}/>}
        {modalOrderCardOpen && <OrderCardsModal isOpen={setModalOrderCardOpen}/>}
      </div>
    </>
  )
}