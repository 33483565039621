import React, {useEffect, useRef, useState} from "react";
import "./Input.css";

export const INPUT_TYPES = {
  TEXT: "text",
  EMAIL: "email",
  PASSWORD: "password"
}

export const INPUT_ICON = {
  EYE_OPEN: "eye-open",
  EYE_CLOSED: "eye-closed",
  SEARCH: "search",
  EURO: "euro",
  SELECT: "select-arrow",
  COPY: "copy"
}

export const Input = ({
                        name,
                        type,
                        value,
                        onChange,
                        onSubmit,
                        label,
                        subtext,
                        error,
                        icon,
                        contentIcon = null,
                        disabled = false,
                        required = false,
                        pattern,
                        title,
                        minLength,
                        maxLength,
                        autoFocus = false,
                        onFocus = null,
                        onFocusOut = null,
                        onClick = null,
                        onIconClick = null,
                        readOnly = false
                      }) => {
  const [tmpPlaceholder, setTmpPlaceholder] = useState("");
  const [className, setClassName] = useState("");
  const [showLabel, setShowLabel] = useState(false);
  const ref = useRef(null);

  if (type === undefined) {
    type = INPUT_TYPES.TEXT
  }

  useEffect(() => {
    handleClassName(value, autoFocus);
  }, [error, label, value])

  const handleClassName = (e, focus) => {
    let cn = className;
    if (contentIcon && !cn.includes("contain-content-icon")) {
      cn = cn + " contain-content-icon";
    }
    if (e === "" || e === undefined || e === null) {
      if (focus) {
        if (!cn.includes("input-focus")) {
          cn = cn + " input-focus";
        }
        setShowLabel(true);
      } else {
        cn = cn.replaceAll("input-focus", "");
        setShowLabel(false);
      }
    } else {
      if (!cn.includes("input-focus")) {
        cn = cn + " input-focus";
      }
      setShowLabel(true)
    }
    if (error && error !== "") {
      if (!cn.includes("input-error")) {
        cn = cn + " input-error";
      }
    } else {
      cn = cn.replaceAll("input-error", "");
    }

    if (icon) {
      cn = cn + " contain-right-icon";
    }
    setClassName(cn);
  }

  return (
    <div className="input">
      {label && showLabel && <div className="caption-heavy input-label" onClick={() => ref.current.focus()}>
        {label}
      </div>}
      {contentIcon && <div className="content-icon" onClick={onClick}>{contentIcon}</div>}
      <input
        className={className}
        name={name}
        type={type}
        value={value}
        placeholder={label}
        onChange={onChange}
        onKeyDown={(e) => e.key === "Enter" && onSubmit()}
        autoComplete="off"
        disabled={disabled}
        required={required}
        pattern={pattern}
        title={title}
        minLength={minLength}
        maxLength={maxLength}
        onBlur={(e) => {
          e.target.placeholder = tmpPlaceholder;
          setTmpPlaceholder("");
          handleClassName(e.target.value);
          onFocusOut && onFocusOut();
        }}
        onFocus={(e) => {
          setTmpPlaceholder(e.target.placeholder);
          handleClassName(e.target.value, true)
          e.target.placeholder = "";
          onFocus && onFocus();
        }}
        onClick={onClick}
        autoFocus={autoFocus}
        readOnly={readOnly}
        ref={ref}
      />
      {icon && <div className={"icon-" + icon} onClick={onIconClick}/>}
      {subtext && <div className="input-subtext">{subtext}</div>}
      {error && <div className="input-error-message">{error}</div>}
    </div>
  )
}