import React, {useState} from "react";
import "../Modal.css";
import "./ModalDeposit.css"
import {ModalDepositStep1} from "./components/ModalDepositStep1";
import {ModalDepositStep2} from "./components/ModalDepositStep2";

export const ModalDeposit = ({isOpen}) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);

  const renderContent = () => {
    if (step === 1) {
      return <ModalDepositStep1 isOpen={isOpen} setStep={setStep} setData={setData}/>
    } else if (step === 2 && data) {
      return <ModalDepositStep2 isOpen={isOpen} setStep={setStep} data={data}/>
    }
  }

  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className={step && step === 2 ? "modal modal-deposit-order" : "modal modal-deposit"}>
        <div className="modal-header">
          <div className="modal-title">Deposit funds</div>
          <div className="modal-close-button" onClick={() => isOpen(false)}/>
        </div>
        {renderContent()}
      </div>
    </>
  )
}