import React, {useEffect, useState} from "react";
import {Button, TYPE} from "../../../../Button/Button";
import {ORDER_CARD_STEP} from "../../../../../utils/orderCardUtils";
import {isMobile} from "../../../../../utils/checkDevice";
import {Select} from "../../../../Select/Select";
import {ReactComponent as IconVisa} from "../../../../../images/icons/card/visa-big.svg";
import classNames from "classnames";
import {toEur} from "../../../../../utils/tools";


const QTY = [
  {value: 0, title: 0},
  {value: 1, title: 1},
  {value: 2, title: 2},
  {value: 3, title: 3},
  {value: 4, title: 4},
  {value: 5, title: 5}
];

const plasticPrice = 195;
const metalPrice = 295;

export const CardSelect = ({setStep, isOpen, setData}) => {

  const [amount, setAmount] = useState(0);
  const [selectedPlasticQty, setSelectedPlasticQty] = useState(QTY[0]);
  const [selectedMetalQty, setSelectedMetalQty] = useState(QTY[1]);


  useEffect(() => {
    let a = 0;
    if (selectedPlasticQty.value > 0) {
      a = a + selectedPlasticQty.value * plasticPrice;
    }
    if (selectedMetalQty.value > 0) {
      a = a + selectedMetalQty.value * metalPrice;
    }
    setAmount(a);
  }, [selectedPlasticQty, selectedMetalQty])


  return (
    <div>
      {!isMobile() && <h3 className="mb-1-625">Select Cards</h3>}
      <div className="subtitle1 mb-2-5">You can choose up to five cards in total in your account. Any
        combination is possible and is delivered to your registered shipping address. Metal cards will be delivered
        separately and can take up to four weeks to arrive.
      </div>
      <form className={classNames(
        "grid gap-2-5",
        {"grid-column-2-1fr": !isMobile()}
      )}>
        <div className="flex column">
          <div className="grid-column-2-auto">
            <div className="card-plastic card-mini">
              <IconVisa/>
            </div>
            <div className="relative">
              {selectedPlasticQty && <Select
                items={QTY}
                label="Plastic debit cards"
                selected={selectedPlasticQty}
                callback={setSelectedPlasticQty}/>}
            </div>
          </div>
          <div className="grid-column-2-auto">
            <div className="card-metal card-mini">
              <IconVisa/>
            </div>
            <div className="relative">
              {selectedMetalQty && <Select
                items={QTY}
                label="Metal debit cards"
                selected={selectedMetalQty}
                callback={setSelectedMetalQty}/>}
            </div>
          </div>
        </div>

        <div className="flex column">

        </div>
        <div className="flex items-center gap-1 fs-1-3 fw-500">
          <div>TOTAL</div>
          <div>{toEur(amount)}</div>
        </div>
      </form>
      <div className="kyc-full-modal-button-container">
        {!isMobile() && <Button
          type={TYPE.SECONDARY}
          text="Back"
          onClick={() => setStep(ORDER_CARD_STEP.TYPE)}
          fullWidth={isMobile()}
        />}
        <Button
          type={TYPE.PRIMARY}
          text="Next"
          onClick={() => {
            setData({
              selectedPlasticQty: selectedPlasticQty.value,
              selectedMetalQty: selectedMetalQty.value
            })
            setStep(ORDER_CARD_STEP.DETAILS);
          }}
          fullWidth={isMobile()}
        />
      </div>
    </div>
  )
}