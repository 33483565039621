export default [
  {
    code: '+44',
    en: 'United Kingdom',
    len: '12',
    reduction: 'GB',
  },
  {
    code: '+7',
    en: 'Russia',
    len: '11',
    reduction: 'RU',
  },
  {
    code: '+77',
    en: 'Kazakhstan',
    len: '11',
    reduction: 'KZ',
  },
  {
    code: '+370',
    en: 'Lithuania',
    len: '11',
    reduction: 'LT',
  },
  {
    code: '+375',
    en: 'Belarus',
    len: '12',
    reduction: 'BY',
  },
  {
    code: '+1',
    en: 'USA',
    len: '11',
    reduction: 'US',
  },
  {
    code: '+7940',
    en: 'Abkhazia',
    len: '11',
    reduction: 'AB',
  },
  {
    code: '+61',
    en: 'Australia',
    len: '11',
    reduction: 'AU',
  },
  {
    code: '+43',
    en: 'Austria',
    len: '12',
    reduction: 'AT',
  },
  {
    code: '+994',
    en: 'Azerbaijan',
    len: '12',
    reduction: 'AZ',
  },
  {
    code: '+355',
    en: 'Albania',
    len: '12',
    reduction: 'AL',
  },
  {
    code: '+213',
    en: 'Algeria',
    len: '12',
    reduction: 'DZ',
  },
  {
    code: '+244',
    en: 'Angola',
    len: '12',
    reduction: 'AO',
  },
  {
    code: '+376',
    en: 'Andorra',
    len: '9',
    reduction: 'AD',
  },
  {
    code: '+1268',
    en: 'Antigua And Barbuda',
    len: '11',
    reduction: 'AG',
  },
  {
    code: '+54',
    en: 'Argentina',
    len: '13',
    reduction: 'AR',
  },
  {
    code: '+374',
    en: 'Armenia',
    len: '11',
    reduction: 'AM',
  },
  {
    code: '+297',
    en: 'Aruba',
    len: '10',
    reduction: 'AW',
  },
  {
    code: '+93',
    en: 'Afghanistan',
    len: '11',
    reduction: 'AF',
  },
  {
    code: '+1242',
    en: 'Bahamas',
    len: '11',
    reduction: 'BS',
  },
  {
    code: '+880',
    en: 'Bangladesh',
    len: '13',
    reduction: 'BD',
  },
  {
    code: '+1246',
    en: 'Barbados',
    len: '11',
    reduction: 'BB',
  },
  {
    code: '+973',
    en: 'Bahrain',
    len: '11',
    reduction: 'BH',
  },
  {
    code: '+501',
    en: 'Belize',
    len: '10',
    reduction: 'BZ',
  },
  {
    code: '+32',
    en: 'Belgium',
    len: '11',
    reduction: 'BE',
  },
  {
    code: '+229',
    en: 'Benin',
    len: '11',
    reduction: 'BJ',
  },
  {
    code: '+225',
    en: 'CoteD Ivoire',
    len: '11',
    reduction: 'CI',
  },
  {
    code: '+1441',
    en: 'Bermuda',
    len: '11',
    reduction: 'BM',
  },
  {
    code: '+359',
    en: 'Bulgaria',
    len: '12',
    reduction: 'BG',
  },
  {
    code: '+591',
    en: 'Bolivia',
    len: '11',
    reduction: 'BO',
  },
  {
    code: '+387',
    en: 'Bosnia And Herzegovina',
    len: '11',
    reduction: 'BA',
  },
  {
    code: '+267',
    en: 'Botswana',
    len: '11',
    reduction: 'BW',
  },
  {
    code: '+55',
    en: 'Brazil',
    len: '12',
    reduction: 'BR',
  },
  {
    code: '+1284',
    en: 'British Virgin Islands',
    len: '11',
    reduction: 'VG',
  },
  {
    code: '+673',
    en: 'Brunei Darussalam',
    len: '10',
    reduction: 'BN',
  },
  {
    code: '+226',
    en: 'Burkina Faso',
    len: '11',
    reduction: 'BF',
  },
  {
    code: '+257',
    en: 'Burundi',
    len: '11',
    reduction: 'BI',
  },
  {
    code: '+975',
    en: 'Bhutan',
    len: '11',
    reduction: 'BT',
  },
  {
    code: '+678',
    en: 'Vanuatu',
    len: '10',
    reduction: 'VU',
  },
  {
    code: '+36',
    en: 'Hungary',
    len: '11',
    reduction: 'HU',
  },
  {
    code: '+58',
    en: 'Venezuela',
    len: '12',
    reduction: 'VE',
  },
  {
    code: '+670',
    en: 'Timor-Leste',
    len: '11',
    reduction: 'TL',
  },
  {
    code: '+84',
    en: 'Vietnam',
    len: '11',
    reduction: 'VN',
  },
  {
    code: '+241',
    en: 'Gabon',
    len: '11',
    reduction: 'GA',
  },
  {
    code: '+509',
    en: 'Haiti',
    len: '11',
    reduction: 'HT',
  },
  {
    code: '+220',
    en: 'Gambia',
    len: '10',
    reduction: 'GM',
  },
  {
    code: '+233',
    en: 'Ghana',
    len: '12',
    reduction: 'GH',
  },
  {
    code: '+590',
    en: 'Guadeloupe',
    len: '12',
    reduction: 'GP',
  },
  {
    code: '+502',
    en: 'Guatemala',
    len: '11',
    reduction: 'GT',
  },
  {
    code: '+224',
    en: 'Guinea',
    len: '11',
    reduction: 'GN',
  },
  {
    code: '+245',
    en: 'Guinea-Bissau',
    len: '10',
    reduction: 'GW',
  },
  {
    code: '+49',
    en: 'Germany',
    len: '12',
    reduction: 'DE',
  },
  {
    code: '+350',
    en: 'Gibraltar',
    len: '11',
    reduction: 'GI',
  },
  {
    code: '+852',
    en: 'HongKong',
    len: '11',
    reduction: 'HK',
  },
  {
    code: '+504',
    en: 'Honduras',
    len: '11',
    reduction: 'HN',
  },
  {
    code: '+1473',
    en: 'Grenada',
    len: '11',
    reduction: 'GD',
  },
  {
    code: '+299',
    en: 'Greenland',
    len: '9',
    reduction: 'GL',
  },
  {
    code: '+30',
    en: 'Greece',
    len: '12',
    reduction: 'GR',
  },
  {
    code: '+995',
    en: 'Georgia',
    len: '12',
    reduction: 'GE',
  },
  {
    code: '+671',
    en: 'Guam',
    len: '11',
    reduction: 'GU',
  },
  {
    code: '+45',
    en: 'Denmark',
    len: '10',
    reduction: 'DK',
  },
  {
    code: '+1767',
    en: 'Dominica',
    len: '11',
    reduction: 'DM',
  },
  {
    code: '+1809',
    en: 'Dominican Republic',
    len: '11',
    reduction: 'DO',
  },
  {
    code: '+20',
    en: 'Egypt',
    len: '12',
    reduction: 'EG',
  },
  {
    code: '+260',
    en: 'Zambia',
    len: '12',
    reduction: 'ZM',
  },
  {
    code: '+263',
    en: 'Zimbabwe',
    len: '12',
    reduction: 'ZW',
  },
  {
    code: '+972',
    en: 'Israel',
    len: '12',
    reduction: 'IL',
  },
  {
    code: '+91',
    en: 'India',
    len: '12',
    reduction: 'IN',
  },
  {
    code: '+62',
    en: 'Indonesia',
    len: '11',
    reduction: 'ID',
  },
  {
    code: '+962',
    en: 'Jordan',
    len: '12',
    reduction: 'JO',
  },
  {
    code: '+964',
    en: 'Iraq',
    len: '13',
    reduction: 'IQ',
  },
  {
    code: '+98',
    en: 'Iran',
    len: '12',
    reduction: 'IR',
  },
  {
    code: '+353',
    en: 'Ireland',
    len: '12',
    reduction: 'IE',
  },
  {
    code: '+354',
    en: 'Iceland',
    len: '10',
    reduction: 'IS',
  },
  {
    code: '+34',
    en: 'Spain',
    len: '11',
    reduction: 'ES',
  },
  {
    code: '+39',
    en: 'Italy',
    len: '12',
    reduction: 'IT',
  },
  {
    code: '+967',
    en: 'Yemen',
    len: '12',
    reduction: 'YE',
  },
  {
    code: '+1345',
    en: 'Cayman Islands',
    len: '11',
    reduction: 'KY',
  },
  {
    code: '+855',
    en: 'Cambodia',
    len: '11',
    reduction: 'KH',
  },
  {
    code: '+237',
    en: 'Cameroon',
    len: '11',
    reduction: 'CM',
  },
  {
    code: '+238',
    en: 'Cape Verde',
    len: '10',
    reduction: 'CV',
  },
  {
    code: '+974',
    en: 'Qatar',
    len: '11',
    reduction: 'QA',
  },
  {
    code: '+254',
    en: 'Kenya',
    len: '12',
    reduction: 'KE',
  },
  {
    code: '+357',
    en: 'Cyprus',
    len: '11',
    reduction: 'CY',
  },
  {
    code: '+86',
    en: 'China',
    len: '13',
    reduction: 'CN',
  },
  {
    code: '+57',
    en: 'Colombia',
    len: '12',
    reduction: 'CO',
  },
  {
    code: '+269',
    en: 'Comoros',
    len: '10',
    reduction: 'KM',
  },
  {
    code: '+242',
    en: 'Congo',
    len: '12',
    reduction: 'CG',
  },
  {
    code: '+3478',
    en: 'KOSOVO',
    len: '0',
    reduction: 'RK',
  },
  {
    code: '+506',
    en: 'Costa Rica',
    len: '11',
    reduction: 'CR',
  },
  {
    code: '+53',
    en: 'Cuba',
    len: '10',
    reduction: 'CU',
  },
  {
    code: '+996',
    en: 'Kyrgyzstan',
    len: '12',
    reduction: 'KG',
  },
  {
    code: '+965',
    en: 'Kuwait',
    len: '11',
    reduction: 'KW',
  },
  {
    code: '+599',
    en: 'Curacao',
    len: '11',
    reduction: 'AN',
  },
  {
    code: '+371',
    en: 'Latvia',
    len: '11',
    reduction: 'LV',
  },
  {
    code: '+266',
    en: 'Lesotho',
    len: '11',
    reduction: 'LS',
  },
  {
    code: '+231',
    en: 'Liberia',
    len: '10',
    reduction: 'LR',
  },
  {
    code: '+961',
    en: 'Lebanon',
    len: '11',
    reduction: 'LB',
  },
  {
    code: '+21',
    en: 'Libya',
    len: '12',
    reduction: 'LY',
  },
  {
    code: '+423',
    en: 'Liechtenstein',
    len: '12',
    reduction: 'LI',
  },
  {
    code: '+352',
    en: 'Luxembourg',
    len: '12',
    reduction: 'LU',
  },
  {
    code: '+230',
    en: 'Mauritius',
    len: '10',
    reduction: 'MU',
  },
  {
    code: '+222',
    en: 'Mauritania',
    len: '11',
    reduction: 'MR',
  },
  {
    code: '+261',
    en: 'Madagascar',
    len: '12',
    reduction: 'MG',
  },
  {
    code: '+853',
    en: 'Macao',
    len: '11',
    reduction: 'MO',
  },
  {
    code: '+389',
    en: 'Macedonia',
    len: '11',
    reduction: 'MK',
  },
  {
    code: '+265',
    en: 'Malawi',
    len: '12',
    reduction: 'MW',
  },
  {
    code: '+60',
    en: 'Malaysia',
    len: '11',
    reduction: 'MY',
  },
  {
    code: '+223',
    en: 'Mali',
    len: '11',
    reduction: 'ML',
  },
  {
    code: '+960',
    en: 'Maldives',
    len: '10',
    reduction: 'MV',
  },
  {
    code: '+356',
    en: 'Malta',
    len: '11',
    reduction: 'MT',
  },
  {
    code: '+212',
    en: 'Morocco',
    len: '12',
    reduction: 'MA',
  },
  {
    code: '+596',
    en: 'Martinique',
    len: '12',
    reduction: 'MQ',
  },
  {
    code: '+52',
    en: 'Mexico',
    len: '13',
    reduction: 'MX',
  },
  {
    code: '+258',
    en: 'Mozambique',
    len: '12',
    reduction: 'MZ',
  },
  {
    code: '+373',
    en: 'Moldova',
    len: '11',
    reduction: 'MD',
  },
  {
    code: '+377',
    en: 'Monaco',
    len: '12',
    reduction: 'MC',
  },
  {
    code: '+976',
    en: 'Mongolia',
    len: '11',
    reduction: 'MN',
  },
  {
    code: '+381',
    en: 'Montenegro',
    len: '11',
    reduction: 'ME',
  },
  {
    code: '+264',
    en: 'Namibia',
    len: '12',
    reduction: 'NA',
  },
  {
    code: '+674',
    en: 'Nauru',
    len: '10',
    reduction: 'NR',
  },
  {
    code: '+977',
    en: 'Nepal',
    len: '13',
    reduction: 'NP',
  },
  {
    code: '+227',
    en: 'Niger',
    len: '11',
    reduction: 'NE',
  },
  {
    code: '+234',
    en: 'Nigeria',
    len: '13',
    reduction: 'NG',
  },
  {
    code: '+31',
    en: 'Netherlands',
    len: '11',
    reduction: 'NL',
  },
  {
    code: '+505',
    en: 'Nicaragua',
    len: '11',
    reduction: 'NI',
  },
  {
    code: '+64',
    en: 'New Zealand',
    len: '11',
    reduction: 'NZ',
  },
  {
    code: '+687',
    en: 'New Caledonia',
    len: '9',
    reduction: 'NC',
  },
  {
    code: '+47',
    en: 'Norway',
    len: '10',
    reduction: 'NO',
  },
  {
    code: '+682',
    en: 'CookIslands',
    len: '8',
    reduction: 'CK',
  },
  {
    code: '+971',
    en: 'United Arab Emirates',
    len: '12',
    reduction: 'AE',
  },
  {
    code: '+968',
    en: 'Oman',
    len: '11',
    reduction: 'OM',
  },
  {
    code: '+92',
    en: 'Pakistan',
    len: '12',
    reduction: 'PK',
  },
  {
    code: '+970',
    en: 'Palestinian Territories',
    len: '12',
    reduction: 'PS',
  },
  {
    code: '+507',
    en: 'Panama',
    len: '11',
    reduction: 'PA',
  },
  {
    code: '+675',
    en: 'Papua New Guinea',
    len: '10',
    reduction: 'PG',
  },
  {
    code: '+595',
    en: 'Paraguay',
    len: '12',
    reduction: 'PY',
  },
  {
    code: '+51',
    en: 'Peru',
    len: '11',
    reduction: 'PE',
  },
  {
    code: '+48',
    en: 'Poland',
    len: '11',
    reduction: 'PL',
  },
  {
    code: '+351',
    en: 'Portugal',
    len: '12',
    reduction: 'PT',
  },
  {
    code: '+1787',
    en: 'Puerto Rico',
    len: '11',
    reduction: 'PR',
  },
  {
    code: '+262',
    en: 'Reunion',
    len: '12',
    reduction: 'RE',
  },
  {
    code: '+250',
    en: 'Rwanda',
    len: '12',
    reduction: 'RW',
  },
  {
    code: '+40',
    en: 'Romania',
    len: '11',
    reduction: 'RO',
  },
  {
    code: '+685',
    en: 'Samoa',
    len: '9',
    reduction: 'AS',
  },
  {
    code: '+378',
    en: 'San Marino',
    len: '11',
    reduction: 'SM',
  },
  {
    code: '+1758',
    en: 'Saint Lucia',
    len: '11',
    reduction: 'LC',
  },
  {
    code: '+966',
    en: 'Saudi Arabia',
    len: '12',
    reduction: 'SA',
  },
  {
    code: '+82',
    en: 'South Korea',
    len: '13',
    reduction: 'KP',
  },
  {
    code: '+850',
    en: 'North Korea',
    len: '13',
    reduction: 'KP',
  },
  {
    code: '+248',
    en: 'Seychelles',
    len: '10',
    reduction: 'SC',
  },
  {
    code: '+221',
    en: 'Senegal',
    len: '12',
    reduction: 'SN',
  },
  {
    code: '+1784',
    en: 'Saint Vincent And The Grenadines',
    len: '11',
    reduction: 'VC',
  },
  {
    code: '+1869',
    en: 'Saint Kitts And Nevis',
    len: '11',
    reduction: 'KN',
  },
  {
    code: '+65',
    en: 'Singapore',
    len: '10',
    reduction: 'SG',
  },
  {
    code: '+963',
    en: 'Syrian Arab Republic',
    len: '12',
    reduction: 'SY',
  },
  {
    code: '+421',
    en: 'Slovakia',
    len: '12',
    reduction: 'SK',
  },
  {
    code: '+386',
    en: 'Slovenia',
    len: '11',
    reduction: 'SI',
  },
  {
    code: '+677',
    en: 'Solomon Islands',
    len: '10',
    reduction: 'SB',
  },
  {
    code: '+249',
    en: 'Sudan',
    len: '12',
    reduction: 'SD',
  },
  {
    code: '+597',
    en: 'Suriname',
    len: '10',
    reduction: 'SR',
  },
  {
    code: '+232',
    en: 'Sierra Leone',
    len: '11',
    reduction: 'SL',
  },
  {
    code: '+992',
    en: 'Tajikistan',
    len: '12',
    reduction: 'TJ',
  },
  {
    code: '+66',
    en: 'Thailand',
    len: '11',
    reduction: 'TH',
  },
  {
    code: '+886',
    en: 'Taiwan',
    len: '12',
    reduction: 'TW',
  },
  {
    code: '+255',
    en: 'Tanzania',
    len: '12',
    reduction: 'TZ',
  },
  {
    code: '+228',
    en: 'Togo',
    len: '11',
    reduction: 'TG',
  },
  {
    code: '+676',
    en: 'Tonga',
    len: '10',
    reduction: 'TO',
  },
  {
    code: '+1868',
    en: 'Trinidad And Tobago',
    len: '11',
    reduction: 'TT',
  },
  {
    code: '+216',
    en: 'Tunisia',
    len: '11',
    reduction: 'TN',
  },
  {
    code: '+993',
    en: 'Turkmenistan',
    len: '11',
    reduction: 'TM',
  },
  {
    code: '+90',
    en: 'Turkey',
    len: '12',
    reduction: 'TR',
  },
  {
    code: '+256',
    en: 'Uganda',
    len: '12',
    reduction: 'UG',
  },
  {
    code: '+380',
    en: 'Ukraine',
    len: '12',
    reduction: 'UA',
  },
  {
    code: '+998',
    en: 'Uzbekistan',
    len: '12',
    reduction: 'UZ',
  },
  {
    code: '+598',
    en: 'Uruguay',
    len: '11',
    reduction: 'UY',
  },
  {
    code: '+298',
    en: 'Faroe Islands',
    len: '9',
    reduction: 'FO',
  },
  {
    code: '+679',
    en: 'Fiji',
    len: '10',
    reduction: 'FJ',
  },
  {
    code: '+63',
    en: 'Philippines',
    len: '12',
    reduction: 'PH',
  },
  {
    code: '+358',
    en: 'Finland',
    len: '12',
    reduction: 'FI',
  },
  {
    code: '+33',
    en: 'France',
    len: '11',
    reduction: 'FR',
  },
  {
    code: '+594',
    en: 'French Guiana',
    len: '12',
    reduction: 'GF',
  },
  {
    code: '+689',
    en: 'French Polynesia',
    len: '9',
    reduction: 'PF',
  },
  {
    code: '+385',
    en: 'Croatia',
    len: '11',
    reduction: 'HR',
  },
  {
    code: '+236',
    en: 'Central African Republic',
    len: '11',
    reduction: 'CF',
  },
  {
    code: '+235',
    en: 'Chad',
    len: '11',
    reduction: 'TD',
  },
  {
    code: '+420',
    en: 'Czech Republic',
    len: '12',
    reduction: 'CZ',
  },
  {
    code: '+56',
    en: 'Chile',
    len: '11',
    reduction: 'CL',
  },
  {
    code: '+41',
    en: 'Switzerland',
    len: '11',
    reduction: 'CH',
  },
  {
    code: '+46',
    en: 'Sweden',
    len: '11',
    reduction: 'SE',
  },
  {
    code: '+381',
    en: 'Serbia',
    len: '',
    reduction: 'SR',
  },
  {
    code: '+94',
    en: 'SriLanka',
    len: '11',
    reduction: 'LK',
  },
  {
    code: '+593',
    en: 'Ecuador',
    len: '12',
    reduction: 'EC',
  },
  {
    code: '+240',
    en: 'Equatorial Guinea',
    len: '12',
    reduction: 'GQ',
  },
  {
    code: '+503',
    en: 'El Salvador',
    len: '11',
    reduction: 'SV',
  },
  {
    code: '+372',
    en: 'Estonia',
    len: '11',
    reduction: 'EE',
  },
  {
    code: '+251',
    en: 'Ethiopia',
    len: '12',
    reduction: 'ET',
  },
  {
    code: '+27',
    en: 'South Africa',
    len: '11',
    reduction: 'ZA',
  },
  {
    code: '+1876',
    en: 'Jamaica',
    len: '11',
    reduction: 'JM',
  },
  {
    code: '+81',
    en: 'Japan',
    len: '12',
    reduction: 'JP',
  },
];
