import React, {useState} from "react";
import {TransferRequestStep1} from "./components/TransferRequestStep1";
import {TransferRequestStep2} from "./components/TransferRequestStep2";
import {MobileHeader} from "../../../../../components/MobileHeader/MobileHeader";
import {ROUTE} from "../../../../../constants/routes";

export const TransferRequest = () => {
  const [step, setStep] = useState(1);
  const [response, setResponse] = useState(null);

  const renderContent = () => {
    if (step === 1) {
      return <TransferRequestStep1 setStep={setStep} setResponse={setResponse}/>
    } else if (step === 2) {
      return <TransferRequestStep2 setStep={setStep} response={response}/>
    }
  }

  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Receive"/>}
      {renderContent()}
    </div>
  )
}