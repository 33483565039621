import React, {useState} from "react";
import "../Modal.css";
import "./ModalUpdatePin.css"
import {ModalUpdatePinStep1} from "./components/ModalUpdatePinStep1";
import {ModalUpdatePinStep2} from "./components/ModalUpdatePinStep2";

export const ModalUpdatePin = ({isOpen}) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);

  const renderContent = () => {
    if (step === 1) {
      return <ModalUpdatePinStep1 isOpen={isOpen} setStep={setStep} setData={setData}/>
    } else if (step === 2) {
      return <ModalUpdatePinStep2 isOpen={isOpen} setStep={setStep} pin={data}/>
    }
  }

  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="modal modal-send">
        <div className="modal-header">
          <div className="modal-title">Update system security code</div>
          <div className="modal-close-button" onClick={() => isOpen(false)}/>
        </div>
        {renderContent()}
      </div>
    </>
  )
}