import {
  LOG_OUT,
  SET_AUTH,
  SET_USER,
  SET_WALLET,
  SET_SHOW_BALANCE,
  SET_DRAWER_ACCOUNT,
  SET_DRAWER_NOTIFICATIONS,
  SET_UNREAD_COUNT,
  SET_NEW_TRANSACTIONS
} from './actionTypes';
import {createAction} from 'redux-actions'
import {jwt} from '../../services/jwt'

const actionList = {
  logout: createAction(LOG_OUT),
  setAuth: createAction(SET_AUTH),
  setUser: createAction(SET_USER),
  setWallet: createAction(SET_WALLET),
  setShowBalance: createAction(SET_SHOW_BALANCE),
  setDrawerAccount: createAction(SET_DRAWER_ACCOUNT),
  setDrawerNotifications: createAction(SET_DRAWER_NOTIFICATIONS),
  setUnreadCount: createAction(SET_UNREAD_COUNT),
  setNewTransactions: createAction(SET_NEW_TRANSACTIONS)
}

export const logout = () => {
  localStorage.clear()
  sessionStorage.clear();
  jwt.destroy()
  return actionList.logout()
}

export const setAuth = (isAuth) => {
  return actionList.setAuth(isAuth)
}

export const setUser = (user) => {
  return actionList.setUser(user)
}

export const setWallet = (wallet) => {
  return actionList.setWallet(wallet)
}

export const setShowBalance = (flag) => {
  return actionList.setShowBalance(flag)
}

export const setDrawerAccount = (state) => {
  return actionList.setDrawerAccount(state)
}

export const setDrawerNotifications = (state) => {
  return actionList.setDrawerNotifications(state)
}

export const setUnreadCount = (count) => {
  return actionList.setUnreadCount(count)
}

export const setNewTransactions = (payload) => {
  return actionList.setNewTransactions(payload)
}