import React, { useEffect, useState } from "react";
import { isNumber } from "chart.js/helpers";
import { client } from "../../../../../services/client";
import { Input, INPUT_TYPES as INPUT_TYPE } from "../../../../../components/Input/Input";
import { Button, TYPE } from "../../../../../components/Button/Button";
import { isMobile } from "../../../../../utils/checkDevice";
import { notification } from "../../../../../components/Notification";
import { useSelector } from "react-redux";
import phone from "../../../../../constants/phone";
import { Select } from "../../../../../components/Select/Select";

export const UpdatePhoneStep1 = ({ isOpen, setStep, setData }) => {
  const user = useSelector(store => store.base.user);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState(null);
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const codes = phone.sort((a, b) => {
      if (a.en > b.en) {
        return 1;
      }
      if (a.en < b.en) {
        return -1;
      }
      return 0;
    });
    codes.map((e, i) => {
      phoneCodes.push({
        id: i,
        title: e.en + " (" + e.code + ")",
        value: e.code,
        iso: e.reduction,
        icon: <span className={"fi fi-" + e.reduction.toLowerCase()}></span>
      })
    });
  }, []);

  useEffect(() => {
    if (user) {
      let p = null;
      setPhoneNumber(user.phone);
      if (user.phoneCode !== null) {
        p = phoneCodes.filter((e) => e.value === user.phoneCode)[0];
      }
      if (p === null) {
        client.getIpData().then((r) => {
          if (Object.keys(r).length > 0) {
            const f = phoneCodes.filter((e) => e.iso === r.iso)[0]
            p = f;
          } else {
            p = phoneCodes.filter((e) => e.iso === "ES")[0];
          }
        })
      }
      setPhoneCode(p);
    }
  }, []);

  const confirm = async (e) => {
    e.preventDefault();

    if (loading) {
      return false;
    }

    setLoading(true);

    await client.updatePhoneRequest()
      .then(() => {
        setData({
          phoneCode: phoneCode.value,
          phone: phoneNumber
        });
        setStep(2);
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }

  return (
    <>
      <form onSubmit={confirm} className="relative">
        <div className="phone-number-container">
          {phoneCode && <Select
            items={phoneCodes}
            search
            isPhoneCode
            label="Phone code"
            selected={phoneCode}
            callback={setPhoneCode} />}
          <Input
            name="phoneNumber"
            label="Phone number"
            value={phoneNumber}
            // error={errorPhoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            onSubmit={confirm}
            required
            autoFocus
          />
        </div>
        <div className="modal-button-container">
          {loading ? <div className="loader" /> : <Button
            type={TYPE.PRIMARY}
            text="Save"
            fullWidth={isMobile()}
          />}
        </div>
      </form>
    </>
  )
}