export const INCOME_TYPES = [
  {
    id: 1,
    title: "Salary",
    value: "SALARY"
  },
  {
    id: 2,
    title: "Investment",
    value: "INVESTMENT"
  },
  {
    id: 3,
    title: "Real Estate",
    value: "REAL_ESTATE"
  },
  {
    id: 4,
    title: "Agriculture",
    value: "AGRICULTURE"
  }
];

export const POLITICAL_TYPES = [
  {
    id: 1,
    title: "Yes",
    value: true
  },
  {
    id: 2,
    title: "No",
    value: false
  },
];

export const GENDER_TYPES = [
  {
    id: 1,
    title: "Male",
    value: "MALE"
  },
  {
    id: 2,
    title: "Female",
    value: "FEMALE"
  },
];