import React from "react";
import {toEur} from "../../../../../../../utils/tools";
import {Button, TYPE} from "../../../../../../../components/Button/Button";
import {isMobile} from "../../../../../../../utils/checkDevice";
import {ReactComponent as IconRequestTransfer} from "../../../../../../../images/icons/request/request-transfer.svg"
import {ROUTE} from "../../../../../../../constants/routes";
import {LS} from "../../../../../../../constants/ls";
import {useHistory} from "react-router-dom";

export const TransferRequestDataStep2 = ({isOpen, data}) => {
  const history = useHistory();

  const close = () => {
    if (isMobile()) {
      history.push(ROUTE.DASHBOARD_WALLET);
      localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET);
    } else {
      isOpen(false);
    }
  }

  return (
    <>
      <div className="modal-request-transfer-icon">
        <IconRequestTransfer/>
      </div>
      <div className="center">
        You transferred {toEur(data.amount)} to <b>{data.toUsername}</b> E-Wallet. <br/>
        User will receive notification in dashboard.
      </div>
      <div className="modal-button-container">
        <Button
          type={TYPE.PRIMARY}
          text="OK"
          onClick={() => close()}
          fullWidth={isMobile()}
        />
      </div>
    </>
  )
}