import React, {useState} from "react";
import "./Sidebar.css"
import "./AccountStaus.css"
import {ReactComponent as CardIcon} from "../../images/icons/sidebar/card.svg";
import {ReactComponent as CryptoIcon} from "../../images/icons/sidebar/crypto.svg";
import {ReactComponent as TransactionsIcon} from "../../images/icons/sidebar/transactions.svg";
import {ReactComponent as IconDashboard} from "../../images/icons/sidebar/dashboard.svg";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../constants/routes";
import {LS} from "../../constants/ls";
import {KycFullModal} from "../Modal/Full/KYC/KycFullModal";
import {OrderCardsModal} from "../Modal/Full/OrderCards/OrderCardsModal";
import {notification} from "../Notification";

export const Sidebar = () => {
  const [active, setActive] = useState("wallet");
  const [hover, setHover] = useState();
  const history = useHistory();
  const [modalAccountNotActivatedIsOpen, setModalAccountNotActivatedIsOpen] = useState(false);
  const [modalOrderCardIsOpen, setModalOrderCardIsOpen] = useState(false);

  const CLASS_INACTIVE = "subtitle sidebar-menu-item";
  const CLASS_ACTIVE = CLASS_INACTIVE + " sidebar-menu-item-active";

  const handleSetHover = (e) => {
    setHover(e.target.id);
  }

  const eraseHover = (e) => {
    setHover(null);
  }

  return (
    <div className="sidebar">
      <div className="sidebar-logo"/>
      <div className="sidebar-menu">
        <div
          id="wallet"
          onMouseEnter={handleSetHover}
          onMouseLeave={eraseHover}
          onClick={() => {
            history.push(ROUTE.DASHBOARD_WALLET);
            localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET)
          }}
          className={hover === "wallet" || active === "wallet" ? CLASS_ACTIVE : CLASS_INACTIVE}>
          <IconDashboard/>
          Overview
        </div>
        <div id="card"
             onMouseEnter={handleSetHover}
             onMouseLeave={eraseHover}
             onClick={() => setModalOrderCardIsOpen(true)}
             className={hover === "card" || active === "card" ? CLASS_ACTIVE : CLASS_INACTIVE}>
          <CardIcon/>
          Card
        </div>
        <div id="crypto"
             onMouseEnter={handleSetHover}
             onMouseLeave={eraseHover}
             onClick={() => notification.warning("Not implemented")}
             className={hover === "crypto" || active === "crypto" ? CLASS_ACTIVE : CLASS_INACTIVE}>
          <CryptoIcon/>
          Crypto
        </div>
        <div id="transactions"
             onMouseEnter={handleSetHover}
             onMouseLeave={eraseHover}
             onClick={() => notification.warning("Not implemented")}
             className={hover === "transactions" || active === "transactions" ? CLASS_ACTIVE : CLASS_INACTIVE}>
          <TransactionsIcon/>
          Transactions
        </div>
      </div>

      <div className="sidebar-copyright">© 2022-{new Date().getFullYear()} Payonow</div>

      {modalAccountNotActivatedIsOpen && <KycFullModal isOpen={setModalAccountNotActivatedIsOpen}/>}
      {modalOrderCardIsOpen &&
        <OrderCardsModal isOpen={setModalOrderCardIsOpen} redirectToKyc={setModalAccountNotActivatedIsOpen}/>}
    </div>
  )
}