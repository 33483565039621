import React, { useState } from "react";
import { MobileHeader } from "../../../../components/MobileHeader/MobileHeader";
import { ROUTE } from "../../../../constants/routes";
import { UpdatePhoneStep1 } from "./components/UpdatePhoneStep1";
import { UpdatePhoneStep2 } from "./components/UpdatePhoneStep2";

export const UpdatePhone = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);


  const renderContent = () => {
    if (step === 1) {
      return <UpdatePhoneStep1 setStep={setStep} setData={setData}/>
    } else if (step === 2) {
      return <UpdatePhoneStep2 setStep={setStep} data={data}/>
    }
  }

  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Update phone number"/>}
      {renderContent()}
    </div>
  )
}