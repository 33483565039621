import React, { useEffect, useState } from "react";
import { MobileHeader } from "../../../../components/MobileHeader/MobileHeader";
import { ROUTE } from "../../../../constants/routes";
import { isMobile } from "../../../../utils/checkDevice";
import { useSelector } from "react-redux";
import { INPUT_ICON, Input } from "../../../../components/Input/Input";
import { notification } from "../../../../components/Notification";
import "./Invite.css";

import { ReactComponent as IconShare } from "../../../../images/icons/social/share.svg"

export const Invite = () => {
    const user = useSelector(store => store.base.user);
    const [link, setLink] = useState("");

    useEffect(() => {
        if (user) {
            setLink(process.env.REACT_APP_DOMAIN + "/" + user?.username);
        }
    }, [user])

    const copy = (v) => {
        navigator.clipboard.writeText(v);
        notification.success("Copied to clipboard");
    }

    return (
        <div className="mobile-content">
            {isMobile() && <MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Invite" />}
            <div className="invite-logo"><IconShare /></div>

            <div className="invite-description">Invite your friends and earn 10 euros cash!
                <div className="invite-description-helper ">
                    Copy the invite link and use it to invite others.
                </div>
            </div>


            <div className="input"><Input
                name="invite"
                label="Your invite link"
                value={link}
                icon={INPUT_ICON.COPY}
                onClick={(e) => copy(link)}
                readOnly
            />
            </div>
            <div className="invite-description-helper">Your reward will be added once your friend has ordered and activated their debit card.</div>
        </div>
    )
}