import React, { useState } from "react";
import { Select } from "../../../../../components/Select/Select";
import { Input } from "../../../../../components/Input/Input";
import { client } from "../../../../../services/client";
import { notification } from "../../../../../components/Notification";
import { Button, TYPE } from "../../../../../components/Button/Button";
import { isMobile } from "../../../../../utils/checkDevice";
import { MobileHeader } from "../../../../../components/MobileHeader/MobileHeader";
import { ROUTE } from "../../../../../constants/routes";

const INTEGRATION_WALLET_TYPE = [
    {
        id: 1,
        title: "LMGT",
        value: "LMGT"
    },
]

export const WalletIntegration = ({ isOpen }) => {
    const [walletType, setWalletType] = useState(INTEGRATION_WALLET_TYPE[0]);
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false);

    const request = async (e) => {
        e && e.preventDefault()
        if (loading) {
            return;
        }
        setLoading(true);

        await client.requestLmgtWalletIntegration({
            email: email
        })
            .then(() => {
                if (!isMobile()) {
                    isOpen(false);
                }
                notification.success("Confirmation link has been sent to requested email")
            })
            .catch((error) => notification.warning(error.message))
            .finally(() => setLoading(false));
    }

    return (
        <>
            {isMobile() && <MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Wallet integration" />}
            <div className="mobile-content">
                <div>Request your wallet integration here and you will receive a confirmation link</div>
                <form onSubmit={request}>
                    <div className="input">
                        {walletType && <Select
                            items={INTEGRATION_WALLET_TYPE}
                            label="Choose wallet type"
                            selected={walletType}
                            callback={setWalletType} />}
                    </div>

                    <div className="input">
                        <Input
                            name="email"
                            label="Enter your email from Binary Torrent"
                            // error={errorEmail}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            onSubmit={request}
                            required
                        />
                    </div>
                    <div className="modal-button-container">
                        {loading ? <div className="loader" /> : <Button
                            type={TYPE.PRIMARY}
                            text="Request"
                            fullWidth={isMobile()}
                            onClick={request}
                        />}
                    </div>
                </form>
            </div>
        </>
    )
}