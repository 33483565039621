import React, { useState } from "react";
import { isMobile } from "../../../../../../utils/checkDevice";
import { Input } from "../../../../../Input/Input";
import { ReactComponent as UploadIcon } from "../../../../../../images/icons/kyc/upload.svg";
import { Button, TYPE } from "../../../../../Button/Button";
import { setUser } from "../../../../../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { client } from "../../../../../../services/client";
import { notification } from "../../../../../Notification";
import { Select } from "../../../../../Select/Select";

const ID_TYPE = [
  {
    id: 1,
    title: "ID",
    value: "ID"
  },
  {
    id: 2,
    title: "Passport",
    value: "PASSPORT"
  },
]

export const VerifyIdentity = () => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.base.user);
  const [docNumber, setDocNumber] = useState();
  const [idType, setIdType] = useState(ID_TYPE[0]);
  const [frontProof, setFrontProof] = useState(null);
  const [errorFrontProof, setErrorFrontProof] = useState(null);
  const [errorBackProof, setErrorBackProof] = useState(null);
  const [backProof, setBackProof] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (frontProof === null) {
      setErrorFrontProof("Front proof required");
      return;
    }
    if (backProof === null) {
      setErrorBackProof("Back proof required");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("idType", idType.value)
    formData.append("docNumber", docNumber)
    formData.append("frontProof", frontProof)
    formData.append("backProof", backProof)

    await client.verifyIdentity(formData)
      .then(() => {
        dispatch(setUser({ ...user, kycStep: 4 }))
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }

  const renderHeader = () => {
    if (isMobile()) {
      return <div>
        <div className="subtitle1">Verify your identity</div>
      </div>
    } else {
      return <div>
        <h3>Verify your identity</h3>
        <div className="subtitle1 kyc-description">Fill out your shipping address if you want the delivery of debit
          cards to be made to a separate location or you may load data from your residential address.
        </div>
      </div>
    }
  }
  return (
    <div>
      {renderHeader()}
      <form className="kyc-form" onSubmit={handleSubmit}>

        <div className="kyc-form-input">
          <div className="input">
            {idType && <Select
              items={ID_TYPE}
              label="Document type"
              selected={idType}
              callback={setIdType} />}
          </div>
        </div>

        <Input
          name="docNumber"
          label="Document number"
          value={docNumber}
          // error={errorEmail}
          onChange={(e) => {
            setDocNumber(e.target.value);
          }}
          onSubmit={handleSubmit}
          required
        />

        <div className={frontProof !== null ? "kyc-load-file kyc-load-file-active" : "kyc-load-file"}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="frontProof"
            type="file"
            onChange={(e) => {
              setFrontProof(e.target.files[0]);
              setErrorFrontProof(null);
            }}
          />
          {frontProof === null ? <label htmlFor="frontProof">
            <UploadIcon />
            <div>Click here to upload front proof</div>
          </label> : <div>
            <img src={URL.createObjectURL(frontProof)} />
            <div className="paragraph-small kyc-load-file-filename">
              <div>{frontProof.name}</div>
              <div className="link" onClick={() => setFrontProof(null)}>Remove</div>
            </div>
          </div>
          }
          {errorFrontProof && <div className="input-error-message">{errorFrontProof}</div>}
        </div>

        <div className={backProof ? "kyc-load-file kyc-load-file-active" : "kyc-load-file"}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="backProof"
            type="file"
            onChange={(e) => {
              setBackProof(e.target.files[0]);
              setErrorBackProof(null);
            }}
          />
          {!backProof ? <label htmlFor="backProof">
            <UploadIcon />
            <div>Click here to upload back proof</div>
          </label> : <div>
            <img src={URL.createObjectURL(backProof)} />
            <div className="paragraph-small kyc-load-file-filename">
              <div>{backProof.name}</div>
              <div className="link" onClick={() => setBackProof(null)}>Remove</div>
            </div>
          </div>
          }
          {errorBackProof && <div className="input-error-message">{errorBackProof}</div>}
        </div>

        <div className="kyc-full-modal-button-container">
          {!isMobile() && <Button
            type={TYPE.SECONDARY}
            text="Back"
            onClick={(e) => {
              e.preventDefault();
              dispatch(setUser({ ...user, kycStep: 2 }));
            }}
            fullWidth={false}
          />}
          <Button
            type={isMobile() ? TYPE.PRIMARY_GRADIENT : TYPE.PRIMARY}
            text="Next"
            onClick={(e) => {

            }}
            fullWidth={isMobile()}
          />
        </div>

      </form>
    </div>
  )
}