import React, {useState} from "react";
import {MobileHeader} from "../../../../components/MobileHeader/MobileHeader";
import {ROUTE} from "../../../../constants/routes";
import {UpdatePinStep1} from "./components/UpdatePinStep1";
import {UpdatePinStep2} from "./components/UpdatePinStep2";

export const UpdatePin = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);


  const renderContent = () => {
    if (step === 1) {
      return <UpdatePinStep1 setStep={setStep} setData={setData}/>
    } else if (step === 2) {
      return <UpdatePinStep2 setStep={setStep} pin={data}/>
    }
  }

  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Update system security code"/>}
      {renderContent()}
    </div>
  )
}