import React, {useState} from "react";
import "../Modal.css";
import "./ModalTransferRequest.css"
import {ModalTransferRequestStep1} from "./components/ModalTransferRequestStep1";
import {ModalTransferRequestStep2} from "./components/ModalTransferRequestStep2";

export const ModalTransferRequest = ({isOpen}) => {
  const [step, setStep] = useState(1);
  const [response, setResponse] = useState(null);

  const renderContent = () => {
    if (step === 1) {
      return <ModalTransferRequestStep1 isOpen={isOpen} setStep={setStep} setResponse={setResponse}/>
    } else if (step === 2 && response) {
      return <ModalTransferRequestStep2 isOpen={isOpen} setStep={setStep} response={response}/>
    }
  }

  return (
    <>
      <div className="modal-bg" onClick={() => isOpen(false)}/>
      <div className="modal modal-transfer-request">
        <div className="modal-header">
          <div className="modal-title">Receive</div>
          <div className="modal-close-button" onClick={() => isOpen(false)}/>
        </div>
        {renderContent()}
      </div>
    </>
  )
}