import React from "react";
import { isMobile } from "../../../../utils/checkDevice";
import { MobileHeader } from "../../../../components/MobileHeader/MobileHeader";
import { ROUTE } from "../../../../constants/routes";
import { AccountUsernameForm } from "./components/AccountForm";
import classNames from "classnames";
import "./Account.css";

export const Account = () => {
    return (
        <div className={classNames(
            { "account-container": !isMobile() }
        )} >
            <div className={classNames(
                { "mobile-content": isMobile() }
            )}>
                {isMobile() && <MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Account information" />}
                <div className={classNames(
                    {
                        "block block-big": !isMobile(),
                        "block-mobile": isMobile()
                    }
                )}>
                    <div className="block-title">Change username</div>
                    <AccountUsernameForm />
                </div>
            </div>
        </div >
    )
}