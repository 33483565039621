import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../../../components/Input/Input";
import { Button, SIZE, TYPE } from "../../../../../components/Button/Button";
import "../Account.css";
import { client } from "../../../../../services/client";
import { setUser } from "../../../../../store/actions/actions";
import { notification } from "../../../../../components/Notification";
import classNames from "classnames";
import { isMobile } from "../../../../../utils/checkDevice";

export const AccountUsernameForm = () => {
    const user = useSelector(store => store.base.user);
    const dispatch = useDispatch();
    const [username, setUsername] = useState();

    const [loadingUsername, setLoadingUsername] = useState(false);

    const [errorUsername, setErrorUsername] = useState(null);

    useEffect(() => {
        if (user) {
            setUsername(user.username);
        }
    }, []);

    const changeUsername = async (e) => {
        e.preventDefault();

        if (loadingUsername) {
            return;
        }

        if (username === user.username) {
            return;
        }

        setLoadingUsername(true);

        await client.changeUsername({
            username
        })
            .then(() => {
                dispatch(setUser({ ...user, username: username }));
                notification.success("Username changed");
            })
            .catch((error) => setErrorUsername(error.message))
            .finally(() => setLoadingUsername(false));
    }

    return (
        <div>
            <div className="form-description">
                You can change username here. It will affect your referral link.
            </div>
            <form onSubmit={(e) => changeUsername(e)}>
                <Input
                    name="username"
                    label="Username"
                    value={username}
                    error={errorUsername}
                    onChange={(e) => {
                        setUsername(e.target.value);
                        setErrorUsername(null);
                    }}
                    onSubmit={(e) => changeUsername(e)}
                    required
                />
                <div className={classNames(
                    "input",
                    {"flex jsr mt-1n": isMobile()}
                )}>
                    <Button
                        text="Change"
                        size={isMobile() ? SIZE.SMALL : SIZE.MEDIUM}
                        type={TYPE.PRIMARY}
                        fullWidth={false} />
                </div>
            </form>
        </div>
    )
}