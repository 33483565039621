import React, {useState} from "react";
import {isNumber} from "chart.js/helpers";
import {client} from "../../../../../services/client";
import {Input, INPUT_TYPES as INPUT_TYPE} from "../../../../../components/Input/Input";
import {Button, TYPE} from "../../../../../components/Button/Button";
import {isMobile} from "../../../../../utils/checkDevice";
import {notification} from "../../../../../components/Notification";

export const UpdatePinStep1 = ({isOpen, setStep, setData}) => {
  const [pin, setPin] = useState("");
  const [repeatPin, setRepeatPin] = useState("");
  const [errorPin, setErrorPin] = useState(null);
  const [errorRepeatPin, setErrorRepeatPin] = useState(null);
  const [loading, setLoading] = useState(false);

  const validatePin = () => {
    if (pin !== repeatPin) {
      setErrorRepeatPin("Pin and repeated pin do not match");
      return false;
    }
    if (!isNumber(pin)) {
      setErrorRepeatPin("Only digits allowed [0-9]");
      return false;
    }
    if (pin.length !== 4) {
      setErrorPin("Required length 4 digits");
      return false;
    }

    return true;
  }

  const resetErrors = () => {
    setErrorPin(null);
    setErrorRepeatPin(null);
  }

  const confirm = async (e) => {
    e.preventDefault();

    if (loading) {
      return false;
    }

    if (!validatePin()) {
      return false;
    }

    setLoading(true);

    await client.updatePinRequest()
      .then(() => {
        setData(pin);
        setStep(2);
      })
      .catch((error) => notification.warning(error.message))
      .finally(() => setLoading(false));
  }

  return (
    <>
      <div>
        Update your system security code to keep an extra layer of security to your account.
      </div>
      <form onSubmit={confirm}>
        <Input
          name="pin"
          label="Type four digit security pin"
          onChange={(e) => {
            resetErrors();
            setPin(e.target.value);
          }}
          value={pin}
          autoFocus={true}
          type={INPUT_TYPE.PASSWORD}
          onSubmit={confirm}
          error={errorPin}
          required
        />
        <Input
          name="repeat"
          label="Repeat four digit security pin"
          onChange={(e) => {
            resetErrors();
            setRepeatPin(e.target.value);
          }}
          value={repeatPin}
          type={INPUT_TYPE.PASSWORD}
          onSubmit={confirm}
          error={errorRepeatPin}
          required
        />
        <div className="modal-button-container">
          {loading ? <div className="loader"/> : <Button
            type={TYPE.PRIMARY}
            text="Confirm"
            fullWidth={isMobile()}
          />}
        </div>
      </form>
    </>
  )
}