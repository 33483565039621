import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE } from "../../../../../constants/routes";
import { notification } from "../../../../../components/Notification";
import { client } from "../../../../../services/client";

export const WalletIntegrationConfirm = () => {
    const history = useHistory()
    const id = useParams();

    useEffect(() => {
        client.confirmLmgtWalletIntegration({
            code: id.code
        }).then(() => {
            notification.success("LMGT wallet integrated successfully");
            history.push(ROUTE.DASHBOARD);
        }).catch((error) => {
            notification.warning(error.message);
            history.push(ROUTE.DASHBOARD);
        });
    }, [])

    return (
        <>processing...</>
    )
}