import React, {useCallback, useEffect, useState} from "react";
import "./GuestLayout.css";
import {isMobile} from "../../../utils/checkDevice";
import {useHistory, useLocation} from "react-router-dom";
import {setUser} from "../../../store/actions/actions";
import {client} from "../../../services/client";
import {useDispatch, useSelector} from "react-redux";

export const GuestLayout = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);
  const [isLanding, setIsLanding] = useState(false);
  let location = useLocation();

  const fetchCurrentUser = useCallback(async () => {
    const isAuth = !!localStorage.accessToken && !!localStorage.refreshToken;
    if (user === null && isAuth) {
      const user = await client.getCurrentUser();
      dispatch(setUser(user));
    }
  }, [user, history]);

  useEffect(() => {
    fetchCurrentUser();
  }, [user]);

  useEffect(() => {
    const p = location.pathname;
    if (p.includes("login") || p.includes("signup") || p.includes("password-reset")) {
      setIsLanding(false);
    } else {
      setIsLanding(true);
    }
  }, [location]);

  const renderContent = () => {
    if (isLanding === true) {
      return children
    } else {
      return <div className="guest-container">
        {!isMobile() && <div className="guest-left-panel">
          <div className="logo"/>
          <div className="guest-left-panel-text">
            Money made simple. It’s not everything - but everything needs money!
          </div>
          <div className="guest-cards"/>
        </div>
        }
        <div className="guest-right-panel">{children}</div>
      </div>
    }
  }

  return (
    <div className="guest-body">
      {renderContent()}
    </div>
  )
}