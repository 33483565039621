import React from "react";
import {isMobile} from "../../../../../utils/checkDevice";
import {DepositStep2} from "../../../../../pages/dashboard/wallet/components/Deposit/components/DepositStep2";

export const OrderPayment = ({setStep, isOpen, data}) => {
  return (
    <div>
      {!isMobile() && <h3 className="mb-1-625">Payment information</h3>}
      <DepositStep2 isOpen={isOpen} data={data} center={false}/>
    </div>
  )
}