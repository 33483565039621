import React from "react";
import "./Select.css";
import {SelectDropdown} from "./components/SelectDropdown/SelectDropdown";
import {Input, INPUT_ICON} from "../Input/Input";
import {useComponentVisible} from "../UseComponentVisible/UseComponentVisible";

export const Select = ({items, selected, search = false, callback, label, isPhoneCode = false}) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const handleClick = (event) => {
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div ref={ref} className="select">
      <Input
        label={label}
        icon={INPUT_ICON.SELECT}
        contentIcon={selected.icon}
        value={isPhoneCode ? selected.value : selected.title}
        onClick={handleClick}
        onIconClick={handleClick}
        readOnly
        select
      />
      {isComponentVisible && <SelectDropdown search={search} items={items} callback={callback} isOpen={handleClick}/>}
    </div>
  )
}