export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    CURRENT: 'users/current',
    SIGNUP: 'users',
    CHANGE_PASSWORD: 'users/password',
    CHANGE_USERNAME: 'users/username',
    CONFIRM_EMAIL: 'users/email/confirm',
    VERIFICATION: 'users/verification',
    SELFIE: 'users/selfie',
    RESEND_CODE: 'users/confirm/resend',
    UPDATE_PIN_REQUEST: 'users/pin/update/request',
    UPDATE_PIN_CONFIRM: 'users/pin/update/confirm',
    CHECK_PIN: 'users/pin/check',
    PASSWORD_RESET_REQUEST: 'users/password/reset',
    PASSWORD_RESET_CONFIRM: 'users/password/confirm',
    UPDATE_PHONE_REQUEST: 'users/phone/update/request',
    UPDATE_PHONE_CONFIRM: 'users/phone/update/confirm',
    IP_DATA: 'login-history/country/data',
    SEARCH: (data) => `users/search/${data}`,
    CHECK_REF: (data) => `users/ref/${data}`
  },

  USER_INFO: {
    GET_USER_INFO: 'users/info',
    UPDATE_USER_INFO: 'users/info',
  },

  USER_RESIDENTIAL_ADDRESS  : {
    GET_USER_RESIDENTIAL_ADDRESS: 'users/residential/address',
    UPDATE_USER_RESIDENTIAL_ADDRESS: 'users/residential/address',
  },

  USER_SHIPPING_ADDRESS  : {
    GET_USER_SHIPPING_ADDRESS: 'users/shipping/address',
    UPDATE_USER_SHIPPING_ADDRESS: 'users/shipping/address',
  },

  USER_VERIFY  : {
    IDENTITY: 'users/verify/identity',
    ADDRESS: 'users/verify/address',
  },

  ORDERS: {
    CREATE: 'orders',
    PAYMENT_METHODS: 'orders/payment/methods',
    LAST_PENDING: 'orders/last/pending',
    PRICE: (quantity, loadAmount) =>  `orders/price/${quantity}/${loadAmount}`,
    GET_BY_ID: (id) =>  `orders/${id}`,
  },

  WALLETS: {
    GET: 'wallets',
    GET_LMGT_WALLETS: 'wallets/lmgt',
    REQUEST_LMGT_WALLET_INTEGRATION: 'wallets/integrate/lmgt/request',
    CONFIRM_LMGT_WALLET_INTEGRATION: 'wallets/integrate/lmgt/confirm',
  },

  TRANSFERS: {
    SEND: 'transfers/send',
  },

  MESSAGES: {
    GET_UNREAD_COUNT: 'messages/unread/count',
    GET_ALL: 'messages',
    GET_BY_ID: (id) => `messages/${id}`,
    READ: (id) => `messages/${id}/read`,
  },

  TRANSFER_REQUESTS: {
    CREATE: 'transfers/requests/request',
    GET_BY_ID: (id) => `transfers/requests/${id}`,
    RESOLVE: (id) => `transfers/requests/resolve/${id}`,
    DECLINE: (id) => `transfers/requests/decline/${id}`,
    CANCEL: (id) => `transfers/requests/cancel/${id}`,
    FROM_ME: 'transfers/requests/from/me',
    TO_ME: 'transfers/requests/to/me',
  },

  TRANSACTIONS: {
    GET_ALL: 'transactions',
    GET_BY_ID: (id) => `transactions/${id}`,
  },

  STAT: {
    TRANSACTIONS: 'stat/transactions',
  },

  SSE: {
    SUBSCRIBE: 'sse'
  },
};
