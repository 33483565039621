import React, {useState} from "react";
import {MobileHeader} from "../../../components/MobileHeader/MobileHeader";
import {ROUTE} from "../../../constants/routes";
import {useSelector} from "react-redux";
import {getStepTitle, ORDER_CARD_STEP} from "../../../utils/orderCardUtils";
import {CardTypes} from "../../../components/Modal/Full/OrderCards/components/CardTypes";
import {CardSelect} from "../../../components/Modal/Full/OrderCards/components/CardSelect";
import {OrderDetails} from "../../../components/Modal/Full/OrderCards/components/OrderDetails";
import {DepositStep2} from "../wallet/components/Deposit/components/DepositStep2";
import {OrderPayment} from "../../../components/Modal/Full/OrderCards/components/OrderPayment";


export const OrderCards = () => {
  const user = useSelector(store => store.base.user);
  const [step, setStep] = useState(ORDER_CARD_STEP.TYPE);
  const [data, setData] = useState(null);

  const getContent = () => {
    switch (step) {
      case ORDER_CARD_STEP.TYPE: return <CardTypes setStep={setStep}/>
      case ORDER_CARD_STEP.SELECT: return <CardSelect setStep={setStep} setData={setData}/>
      case ORDER_CARD_STEP.DETAILS: return <OrderDetails setStep={setStep} data={data} setData={setData}/>
      case ORDER_CARD_STEP.PAYMENT: return <OrderPayment setStep={setStep}  data={data} />
    }
  }


  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text={getStepTitle(step)}/>}
      {getContent()}
    </div>
  )
}