import React, { useState } from "react"
import { isMobile } from "../../../utils/checkDevice";
import { MobileHeader } from "../../../components/MobileHeader/MobileHeader";
import { ROUTE } from "../../../constants/routes";
import { Input } from "../../../components/Input/Input";
import { client } from "../../../services/client";
import { notification } from "../../../components/Notification";
import { Button, TYPE } from "../../../components/Button/Button";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../utils/validators";

export const ResetPasswordRequest = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [buttonActive, setButtonActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const submit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }
    setLoading(true);

    if (!validateEmail(email)) {
      setEmailError("Wrong email format");
      return;
    }

    if (buttonActive) {
      await client.requestResetPassword({
        email: e.target.email.value
      })
        .then(() => {
          setStep(1);
        })
        .catch((error) => {
          setEmailError(error.message);
        })
        .finally(() => setLoading(false));
    }
  }

  const onChange = (e) => {
    const v = e.target.value
    setEmail(v);

    if (validateEmail(v)) {
      setButtonActive(true);
      setEmailError(null);
    } else {
      setButtonActive(false);
    }
  }

  return (
    <div className="auth-container">
      {isMobile() ? <MobileHeader url={ROUTE.ROOT} text="Forgot password" /> : <h3>Forgot password</h3>}

      {step === 0 ? <div className="auth-description">
        Enter your registered email and click to get a reset link. If you have an account with us, your reset link
        will arrive right away
      </div> : <div className="auth-description">
        Reset password link has been sent to your email
      </div>}

      {step === 0 && <><form onSubmit={(e) => submit(e)}>
        <Input
          name="email"
          label="Email"
          error={emailError}
          onChange={onChange}
          onSubmit={(e) => submit(e)}
          autoFocus
          required
        />
        <div className="auth-button-container">
          <Button
            type={buttonActive ? TYPE.PRIMARY : TYPE.INACTIVE}
            text="Send reset link"
          />
        </div>
        {!isMobile() && <div className="signup-link subtitle">
          Already have an account? <span onClick={() => history.push(ROUTE.LOGIN)} className="link">Log in</span>
        </div>}
      </form>
      </>}
    </div>
  )
}