import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { KycFullModal } from "../../../components/Modal/Full/KYC/KycFullModal";
import { ModalUpdatePin } from "../../../components/Modal/ModalUpdatePin/ModalUpdatePin";
import { NotificationIcon } from "../../../components/TopPanel/components/NotificationIcon/UnreadCounter";
import { KYC_STATUS } from "../../../constants/kycStatuses";
import { LS } from "../../../constants/ls";
import { ROUTE } from "../../../constants/routes";
import { ReactComponent as IconShare } from "../../../images/icons/share.svg";
import { ReactComponent as ProfileIcon } from "../../../images/icons/top-panel/account.svg";
import { ReactComponent as IconEyeClosed } from "../../../images/icons/top-panel/eye-closed.svg";
import { ReactComponent as IconEyeOpened } from "../../../images/icons/top-panel/eye-opened.svg";
import { ReactComponent as DepositIcon } from "../../../images/icons/wallet/deposit.svg";
import { ReactComponent as EurIcon } from "../../../images/icons/wallet/eur-item.svg";
import { ReactComponent as RequestIcon } from "../../../images/icons/wallet/request.svg";
import { ReactComponent as SendIcon } from "../../../images/icons/wallet/send.svg";
import { setDrawerAccount, setDrawerNotifications, setShowBalance } from "../../../store/actions/actions";
import { toEur } from "../../../utils/tools";
import "./Wallet.css";
import { Card } from "./components/Card/Card";
import { RecentTransactions } from "./components/Transactions/RecentTransactions";
import { WalletIntegration, WalletIntegrationSnippet } from "./components/Integration/WalletIntegrationSnippet";

export const WalletMobile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(store => store.base.user);
  const wallet = useSelector(store => store.base.wallet);
  const [modalKycOpen, setModalKycOpen] = useState(false);
  const [modalSecurityPinIsOpen, setModalSecurityPinIsOpen] = useState(false);

  const showBalance = useSelector(store => store.base.showBalance);

  useEffect(() => {
    const reqId = new URLSearchParams(history.location.search).get('request');
    const transId = new URLSearchParams(history.location.search).get('transaction');
    const orderId = new URLSearchParams(history.location.search).get('order');
    if (reqId != null) {
      dispatch(setDrawerNotifications(false));
      history.push(ROUTE.DASHBOARD_WALLET_REQUEST_TRANSFER_DATA.replace(":id", reqId));
    } else if (transId != null) {
      dispatch(setDrawerNotifications(false));
      history.push(ROUTE.DASHBOARD_WALLET_TRANSACTION.replace(":id", transId));
    } else if (orderId != null) {
      dispatch(setDrawerNotifications(false));
      history.push(ROUTE.DASHBOARD_WALLET_ORDER.replace(":id", orderId));
    }
  }, [location]);


  const openKyc = () => {
    history.push(ROUTE.DASHBOARD_KYC);
    localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_KYC);
  }

  const renderStatusLabel = () => {
    if (user) {
      if (user.kycStatus === KYC_STATUS.IN_PROGRESS) {
        return "Activate your account";
      } else {
        return "Account status"
      }
    }
  }

  const renderBalance = (balance) => {
    if (showBalance) {
      return toEur(balance);
    } else {
      return "€***.**"
    }
  }

  const renderStatusButton = () => {
    if (user) {
      if (user.kycStatus === KYC_STATUS.IN_PROGRESS) {
        return <div className="mobile-button mobile-button-blue-fill" onClick={openKyc}>
          Activate
        </div>
      } else if (user.kycStatus === KYC_STATUS.PENDING) {
        return <div className="mobile-button mobile-button-orange">
          Pending KYC
        </div>
      } else if (user.kycStatus === KYC_STATUS.REJECTED) {
        return <div className="mobile-button mobile-button-red">
          Rejected KYC
        </div>
      } else if (user.kycStatus === KYC_STATUS.APPROVED) {
        return <div className="mobile-button mobile-button-green">
          Approved KYC
        </div>
      }
    }
  }

  const renderOrderButton = () => {
    if (user) {
      if (user.kycStatus === KYC_STATUS.APPROVED) {
        return <div className="mobile-button mobile-button-blue" onClick={openKyc}>
          Order
        </div>
      } else {
        return <div className="mobile-button mobile-button-gray">
          Order
        </div>
      }
    }
  }

  return (
    <div>
      <div className="wallet-header-mobile">
        <ProfileIcon onClick={() => dispatch(setDrawerAccount(true))} />
        <div className="wallet-header-mobile-name"
          onClick={() => dispatch(setDrawerAccount(true))}>{user?.username}</div>
        <div className="align-right wallet-header-mobile-icons-right">
          {showBalance ? <IconEyeClosed onClick={() => dispatch(setShowBalance(false))} /> :
            <IconEyeOpened onClick={() => dispatch(setShowBalance(true))} />}
          <IconShare onClick={() => history.push(ROUTE.DASHBOARD_ACCOUNT_INVITE)} />
          <NotificationIcon />
        </div>
      </div>


      <div className="relative">
        <Card isMobile={true} />
        {/*{isSwipeIconVisible && <div className="wallet-mobile-swipe-icon-container">*/}
        {/*  <IconSwipe/>*/}
        {/*</div>}*/}
      </div>


      <div className="wallet-mobile-card-items-container">
        <div className="block-mobile">
          <div className="wallet-mobile-icons-container send-request-items">
            <div className="wallet-mobile-icon-item wallet-mobile-icon-item-green" onClick={() => {
              history.push(ROUTE.DASHBOARD_WALLET_RECEIVE);
              localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET_RECEIVE);
            }}>
              <RequestIcon />
              <div>Receive</div>
            </div>
            <div className="button-divider" />
            <div className="wallet-mobile-icon-item wallet-mobile-icon-item-blue" onClick={() => {
              history.push(ROUTE.DASHBOARD_WALLET_SEND);
              localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET_SEND);
            }}>
              <SendIcon />
              <div>Send</div>
            </div>
            <div className="button-divider" />
            <div className="wallet-mobile-icon-item wallet-mobile-icon-item-orange" onClick={() => {
              history.push(ROUTE.DASHBOARD_WALLET_DEPOSIT);
              localStorage.setItem(LS.ROUTE, ROUTE.DASHBOARD_WALLET_DEPOSIT);
            }}>
              <DepositIcon />
              <div>Deposit</div>
            </div>
          </div>
        </div>


        <div className="wallet-mobile-card-item subtitle1">
          <div className="wallet-mobile-card-item-icon-container">
            <div>{renderStatusLabel()}</div>
          </div>
          <div>
            {renderStatusButton()}
          </div>
        </div>

        <div className="wallet-mobile-card-item subtitle1">
          <div className="wallet-mobile-card-item-icon-container">
            <div>Order <b>debit card?</b></div>
          </div>
          <div>
            {renderOrderButton()}
          </div>
        </div>

        <div className="wallet-mobile-card-item subtitle1">
          <div className="wallet-mobile-card-item-icon-container">
            <EurIcon />
            <div>E-Wallet</div>
          </div>
          <div>
            {wallet && renderBalance(wallet.ewalletBalance)}
          </div>
        </div>

        <WalletIntegrationSnippet isMobile={true} />
        <RecentTransactions isMobile={true} />

      </div>

      {modalKycOpen && <KycFullModal isOpen={setModalKycOpen} />}
      {modalSecurityPinIsOpen && <ModalUpdatePin isOpen={setModalSecurityPinIsOpen} />}
    </div>
  )
}
