import React from "react";
import {MobileHeader} from "../../components/MobileHeader/MobileHeader";
import {ROUTE} from "../../constants/routes";
import {SecurityCodeData} from "./components/SecurityCodeData";

export const SecurityCode = ({isOpen, type}) => {

  return (
    <div className="mobile-content">
      {<MobileHeader url={ROUTE.DASHBOARD_WALLET} text="Check your email for OTP"/>}
      <SecurityCodeData isOpen={isOpen} isMobile={true}/>
    </div>
  )
}