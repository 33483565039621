import {handleActions} from 'redux-actions'

const initialState = {
  isAuth: false,
  user: null,
  wallet: null,
  showBalance: true,
  drawerAccount: false,
  drawerNotifications: false,
  unreadCount: 0,
  newTransactions: 0
}

export const baseReducer = handleActions(
  {
    SET_AUTH: (state, {payload}) => {
      return {
        ...state,
        isAuth: payload,
      }
    },
    LOG_OUT: () => initialState,
    SET_USER: (state, {payload}) => ({
      ...state,
      user: payload,
    }),
    SET_WALLET: (state, {payload}) => ({
      ...state,
      wallet: payload,
    }),
    SET_SHOW_BALANCE: (state, {payload}) => ({
      ...state,
      showBalance: payload,
    }),
    SET_DRAWER_ACCOUNT: (state, {payload}) => ({
      ...state,
      drawerAccount: payload,
    }),
    SET_DRAWER_NOTIFICATIONS: (state, {payload}) => ({
      ...state,
      drawerNotifications: payload,
    }),
    SET_UNREAD_COUNT: (state, {payload}) => ({
      ...state,
      unreadCount: payload,
    }),
    SET_NEW_TRANSACTIONS: (state, {payload}) => ({
      ...state,
      newTransactions: payload,
    })
  },
  initialState,
)
