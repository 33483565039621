import React from "react";
import { Button, TYPE } from "../../../components/Button/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE } from "../../../constants/routes";
import classNames from "classnames";
import { isMobile } from "../../../utils/checkDevice";

export const LandingProducts = () => {
    const history = useHistory();

    return (
        <div className={classNames(
            "landing-cards-block-2 landing-card-second-page gap-1-25",
            {
                "landing-card-second-page-inner2-mobile": isMobile()
            }
        )}>
            <div className="landing-cards-block gap-1-38">
                <div className="flex column gap-1-38">
                    <div className="center fs-2-5 fw-500">Free</div>
                    <ul className="list-style-disc landing-cards-ul-2">
                        <li>Crypto and e-wallet.</li>
                        <li>Free wallet to user transfers.</li>
                        <li>World-wide access.</li>
                        <li>No monthly or yearly fee.</li>
                    </ul>
                </div>
                <div>
                    <Button
                        type={TYPE.PRIMARY}
                        fullWidth={false}
                        text="Get a free account"
                        onClick={() => history.push(ROUTE.SIGNUP)}
                    />
                </div>
            </div>
            <div className="landing-cards-block gap-1-38">
                <div className="flex column gap-1-38">
                    <div className="center fs-2-5 fw-500">Basic</div>
                    <ul className="list-style-disc landing-cards-ul-2">
                        <li>Crypto and e-wallet.</li>
                        <li>Physical plastic card.</li>
                        <li>Free wallet to wallet transfers.</li>
                        <li>Free wallet to card transfers.</li>
                        <li>Free wallet to user transfers.</li>
                        <li>World-wide access.</li>
                        <li>No monthly fee or yearly fee the first year.</li>
                        <li>1% cash back.</li>
                    </ul>
                </div>
                <div>
                    <Button
                        type={TYPE.PRIMARY}
                        fullWidth={false}
                        text="Get a free account"
                        onClick={() => history.push(ROUTE.SIGNUP)}
                    />
                </div>
            </div>
            <div className="landing-cards-block gap-1-38">
                <div className="flex column gap-1-38">
                    <div className="center fs-2-5 fw-500">Metal</div>
                    <ul className="list-style-disc landing-cards-ul-2">
                        <li>Crypto and e-wallet.</li>
                        <li>Physical metal card.</li>
                        <li>Free wallet to wallet transfers.</li>
                        <li>Free wallet to card transfers.</li>
                        <li>Free wallet to user transfers.</li>
                        <li>World-wide access.</li>
                        <li>No monthly fee or yearly fee the first year.</li>
                        <li>2% cash back.</li>
                    </ul>
                </div>
                <div>
                    <Button
                        type={TYPE.PRIMARY}
                        fullWidth={false}
                        text="Get a free account"
                        onClick={() => history.push(ROUTE.SIGNUP)}
                    />
                </div>
            </div>
        </div>
    )
}